import React, { useState } from "react";
import Index from "../../../component/Index";
import { Outlet } from "react-router-dom";
import Header from "../../../component/DefaultLayout/Header/Header";
import Footer from "../../../component/DefaultLayout/Footer/Footer";
import TopHeader from "../../../component/DefaultLayout/Header/TopHeader";
import MenuHeader from "../../../component/DefaultLayout/Header/MenuHeader";

export default function Dashboard() {
  return (
    <div>
      <Index.Box className="dashboard-main exchange-dashboard">
        {/* <Index.Box className="dashboard-left-main">
        <Index.Sidebar/>
      </Index.Box> */}
        <Index.Box>
          <TopHeader />
        </Index.Box>
        <Index.Box className="dashboard-right-main head-per-bt">
          <MenuHeader />
          <Index.Box>
            <Outlet />
          </Index.Box>
          <Footer />
        </Index.Box>
      </Index.Box>
    </div>
  );
}
