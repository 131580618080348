import React, { useState } from "react";

import { Outlet } from "react-router-dom";
import Index from "../../../component/Index";

export default function LayOut() {
  const [open, setOpen] = useState(true);

  return (
    <div>
      <Index.Box className="dashboard-main bg-exchange-dash">
        <Index.Box
          className={`dashboard-left-main ${
            open ? "active" : "admin-sidebar-deactive"
          }`}
        >
          <Index.Sidebar open={open} setOpen={setOpen} />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Box className="dashboard-containt-main">
            <Index.MobileHeader />
            <Outlet />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
