import React from "react";
import NewHeader from "../../../component/DefaultLayout/Header/NewHeader";
import { Outlet } from "react-router-dom";
import Footer from "../../../component/DefaultLayout/Footer/Footer";
import Index from "../../../component/Index";
import TopHeader from "../../../component/DefaultLayout/Header/TopHeader";
import MenuHeader from "../../../component/DefaultLayout/Header/MenuHeader";

const NewDefaultLayout = () => {
  return (
    <>
      <Index.Box>
        <TopHeader />
      </Index.Box>
      <Index.Box className="main-background main_banner">
        <Index.Box>
          <MenuHeader />
        </Index.Box>
        <Outlet />
      </Index.Box>
      <Footer />
    </>
  );
};

export default NewDefaultLayout;
