import React, { useEffect, useState, useRef } from "react";
import Index from "../../../component/Index";
import { DataService } from "../../../config/DataService";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../config/Api";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Transfer from "./Transfer";
import { getWalletDataList } from "../../../redux/features/slices/User/UserService";
import { CircularProgress } from "@mui/material";

// modal
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const WithdrawCrypto = () => {
  const formikRef = useRef();
  const token = useSelector((state) => state.user.userToken);
  const walletList = useSelector((state) => state.user.walletList);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = useState("withdraw");
  const [networkData, setNetworkData] = useState([]);
  const [storeValue, setStoreValue] = useState({});
  const [resetValue, setResetValue] = useState({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  const WithdrawCryptoSchema = Yup.object().shape({
    walletAddress: Yup.string()
      .required("Please enter wallet address")
      .matches(/^[a-zA-Z0-9\s]+$/, "Only text and numbers are allowed"),
    amount: Yup.number()
      .typeError("Only numbers are allowed")
      .required("Please enter Amount")
      .test("balance", "Amount exceeds available balance", function (value) {
        const selectedTokenBalance = walletList.find(
          (data) => data.token_name === this.parent.selectedToken
        )?.token_balance;

        return selectedTokenBalance >= value;
      }),
  });

  const getNetwork = async () => {
    await DataService(token)
      .get(API.User.GET_SETTING)
      .then((res) => {
        setNetworkData(res.data.data.network);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const confirmationModalOpen = async () => {
    const withDrawData = new URLSearchParams();
    withDrawData.append("wallet_address", storeValue?.walletAddress);
    withDrawData.append("token_withdraw", storeValue?.amount);
    withDrawData.append("token_name", storeValue?.selectedToken);
    withDrawData.append("network", storeValue?.network);

    await DataService(token)
      .post(API.Exchange.WITHDRAW_CRYPTO, withDrawData)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
          // resetForm();
          //  { resetForm }
          // value=""
          // value={}
          dispatch(getWalletDataList(token));
          setOpen(false);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
        setOpen(false);
      });
    formikRef.current.resetForm();
  };

  const handleFormSubmit = async (value, { resetForm }) => {
    handleOpen();
    setStoreValue(value);
    setResetValue(resetForm);
  };

  useEffect(() => {
    getNetwork();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      {/* modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="confirmation-box">
          <Box sx={style} className="confirmation-btn">
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to withdraw?
            </Typography>
            <Index.Box className="con-btn">
              <Button
                onClick={confirmationModalOpen}
                style={{
                  color: "white",
                  backgroundColor: "#00235f",
                  fontFamily: "system-ui",
                  fontSize: 13,
                  padding: 5,
                }}
              >
                Yes
              </Button>
              <Button
                onClick={handleClose}
                style={{
                  color: "white",
                  backgroundColor: "red",
                  fontSize: 12,
                  fontFamily: "system-ui",
                  marginLeft: 5,
                }}
              >
                No
              </Button>
            </Index.Box>
          </Box>
        </Box>
      </Modal>

      <Formik
        innerRef={formikRef}
        initialValues={{
          walletAddress: "",
          network: networkData?.length > 0 ? networkData[0].network_type : "",
          selectedToken: walletList?.length > 0 ? walletList[0].token_name : "",
          amount: "",
        }}
        validationSchema={WithdrawCryptoSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Index.Box className="admin-dashboard-box">
              <Index.Box className="excha-card inp-padding">
                <Index.TabContext value={value}>
                  <Index.Box className="admin-sub-title-main">
                    <Index.Box>
                      <Index.TabList
                        aria-label="lab API tabs example"
                        onChange={tabHandleChange}
                        className="tab-btn"
                      >
                        <Index.Tab
                          className="admin-sub-title title "
                          label="Withdraw Crypto"
                          value="withdraw"
                        />
                        <Index.Tab
                          className="admin-sub-title title"
                          label="Transfer of FBC user"
                          value="transfer"
                        />
                      </Index.TabList>
                    </Index.Box>

                    {/* tab */}
                  </Index.Box>

                  <Index.TabPanel value="withdraw">
                    <Index.Box className="crypto-token-form withdraw-token-form">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Select Token
                              </Index.FormHelperText>
                              <Index.Box className="dropdown-box">
                                <Index.FormControl className="form-control drop-form-control">
                                  <Index.Select
                                    className="dropdown-select drop-select"
                                    name="selectedToken"
                                    value={values.selectedToken}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    {walletList &&
                                      walletList?.map((net) => (
                                        <Index.MenuItem
                                          key={net?.token_name}
                                          value={net?.token_name}
                                        >
                                          {net?.token_name}
                                        </Index.MenuItem>
                                      ))}
                                  </Index.Select>
                                </Index.FormControl>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box ">
                              <Index.FormHelperText className="form-lable">
                                Network
                              </Index.FormHelperText>
                              <Index.Box className="dropdown-box">
                                <Index.FormControl className="form-control drop-form-control">
                                  <Index.Select
                                    className="dropdown-select drop-select"
                                    name="network"
                                    value={values.network}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    {networkData &&
                                      networkData?.map((net) => (
                                        <Index.MenuItem
                                          key={net?.network_type}
                                          value={net?.network_type}
                                        >
                                          {net?.network_type}
                                        </Index.MenuItem>
                                      ))}
                                  </Index.Select>
                                </Index.FormControl>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                Wallet Address
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  name="walletAddress"
                                  className="form-control"
                                  placeholder=""
                                  autocomplete="off"
                                  onBlur={handleBlur}
                                  value={values.walletAddress}
                                  onChange={handleChange}
                                  helperText={
                                    touched.walletAddress &&
                                    errors.walletAddress
                                  }
                                  error={Boolean(
                                    errors.walletAddress &&
                                      touched.walletAddress
                                  )}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                Amount
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  name="amount"
                                  value={values.amount}
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder=""
                                  autocomplete="off"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={touched.amount && errors.amount}
                                  error={Boolean(
                                    errors.amount && touched.amount
                                  )}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.Box className="available-token-sec">
                                <Index.Typography
                                  className="available-titles"
                                  variant="p"
                                  component="p"
                                >
                                  Available Balance
                                </Index.Typography>
                                <Index.Typography
                                  className="available-data"
                                  variant="p"
                                  component="p"
                                >
                                  {values.selectedToken &&
                                    walletList
                                      ?.find(
                                        (data) =>
                                          data?.token_name ===
                                          values.selectedToken
                                      )
                                      ?.token_balance.toFixed(2)}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="verify-user">
                                <Index.Typography
                                  className="admin-sub-title"
                                  variant="p"
                                  component="p"
                                >
                                  Withdrawal Fees :{" "}
                                  <span>
                                    {" "}
                                    {values.network &&
                                      networkData.find(
                                        (data) =>
                                          data.network_type === values.network
                                      )?.fees}
                                  </span>
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="btn-main-primary withdraw-main-btn">
                              <Index.Button
                                className="btn-primary withdraw-btn"
                                type="submit"
                                disableRipple
                              >
                                Withdraw
                                {/* {!isLoading ? (
                                  "Withdraw"
                                ) : (
                                  <>
                                    <CircularProgress
                                      sx={{ color: "white" }}
                                      size={24}
                                    />
                                  </>
                                )} */}
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.TabPanel>

                  {/* transfer */}
                  <Index.Box className="tab-panel-box">
                    <Index.TabPanel value="transfer" className="tab-panel-main">
                      <Transfer />
                    </Index.TabPanel>
                  </Index.Box>
                </Index.TabContext>
              </Index.Box>
            </Index.Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WithdrawCrypto;
