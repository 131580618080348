import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import MuiPhoneInput from "material-ui-phone-number";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import Index from "../../../component/Index";

const SignUpUser = () => {
  const navigate = Index.useNavigate();

  const { search } = Index.useLocation();

  // Get Referral Code from location state
  let referral;
  if (search.includes("?Left-Referral")) {
    referral = search.split("?Left-Referral=")[1];
  } else if (search.includes("?Right-Referral")) {
    referral = search.split("?Right-Referral=")[1];
  }
  // End
  console.log(referral, ":search");

  const [flag, setFlag] = useState(false);
  const [ischecked, setIsChecked] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowPassword = () => setShowPassword((show) => !show);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword((show1) => !show1);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleContactNo = (data) => setCountryCode(data);

  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    userName: "",
    mobileNo: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email")
      .matches(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,4})+$/,
        "Please enter valid email"
      )
      .required("Please enter an email"),

    firstName: Yup.string()
      .required("Please enter firstname")
      .min(2, "Firstname should be 2-20 characters")
      .matches(/^[a-zA-Z\s]+$/, "Enter only alphabet character"),

    lastName: Yup.string()
      .required("Please enter lastname")
      .min(2, "Lastname should be 2-20 characters")
      .matches(/^[a-zA-Z\s]+$/, "Enter only alphabet character"),

    userName: Yup.string()
      .required("Please enter username")
      .min(2, "Username should be 2-20 characters")
      .matches(
        /^(?![0-9]+$)(?![!@#$%^&*()_+<>?]+$)[a-zA-Z0-9!@#$%^&*()_+<>?]+$/,
        "Please enter valid username"
      ),

    mobileNo: Yup.string()
      .required("Please enter mobile number")
      .matches(
        /^[\+]?[(]?[0-9]{1,4}[)]?\s?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,}$/,
        "Please enter valid mobile number"
      ),

    password: Yup.string()
      .required("Please enter a password")
      .matches(
        /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,16}$/,
        "Please Enter valid password (Ex. Jhon@123)"
      ),

    confirmPassword: Yup.string()
      .required("Please enter a confirm password")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password not matched with password"
      ),
  });

  const handleFormSubmit = async (values) => {
    setFlag(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", values?.email?.trim());
    urlencoded.append("first_name", values?.firstName?.trim());
    urlencoded.append("last_name", values?.lastName?.trim());
    urlencoded.append("user_name", values?.userName?.trim());
    urlencoded.append("mobile_number", values?.mobileNo);
    urlencoded.append("country_code", countryCode?.dialCode);
    urlencoded.append("type", "exchange");

    urlencoded.append("password", values?.password?.trim());
    if (referral) {
      urlencoded.append("referral_code", referral?.trim());
    }

    await DataService()
      .post(API.User.JOIN_WAITLIST, urlencoded)
      .then((res) => {
        Index.toast.success(res.data.message);
        setTimeout(() => {
          navigate("/new/login");
        }, [2000]);
      })
      .catch((error) => {
        if (error.response.data.status == 400) {
          setFlag(false);

          if (error.response.data.message == "Email already exist") {
            // setEmail("");
          }
          if (error.response.data.message == "Mobile number already exist") {
            // setContactNo("");
          }
          Index.toast.error(error.response.data.message);
        }
      });
    setFlag(false);
  };

  return (
    <>
      <Index.Box className="form-box res-pt-20 center-div auth-wrapper">
        <Index.Box className="res-set-modal-hit join-hit auth-form">
          <Index.Typography mt={3} mb={3} component="h4" variant="h4">
            {/* Fill the form to Register */}
            Register
          </Index.Typography>
          <Index.Box sx={{ flexGrow: 1 }}>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form className="user-register-form">
                  <Index.Grid
                    container
                    spacing={2}
                    justifyContent={"center"}
                    className="grid-100"
                  >
                    <Index.Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.ListItem className="res-li-modal">
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column w-100 "
                        >
                          <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input ">
                            <Index.FormHelperText className="form-lable">
                              Email <span style={{ color: "red" }}>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group" mb={2}>
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                name="email"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={handleChange}
                                helperText={touched.email && errors.email}
                                error={Boolean(errors.email && touched.email)}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.ListItem>
                    </Index.Grid>

                    <Index.Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className="set-grid-modal "
                    >
                      <Index.ListItem className="w-100">
                        <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input w-100">
                          <Index.FormHelperText className="form-lable">
                            First Name <span style={{ color: "red" }}>*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group" mb={2}>
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              inputProps={{ maxlength: 20 }}
                              className="form-control"
                              name="firstName"
                              onBlur={handleBlur}
                              value={values.firstName}
                              onChange={handleChange}
                              helperText={touched.firstName && errors.firstName}
                              error={Boolean(
                                errors.firstName && touched.firstName
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.ListItem>
                    </Index.Grid>

                    <Index.Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.ListItem className="res-li-modal">
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column w-100"
                        >
                          <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input">
                            <Index.FormHelperText className="form-lable">
                              Last Name <span style={{ color: "red" }}>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group" mb={2}>
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                inputProps={{ maxlength: 20 }}
                                className="form-control"
                                name="lastName"
                                onBlur={handleBlur}
                                value={values.lastName}
                                onChange={handleChange}
                                helperText={touched.lastName && errors.lastName}
                                error={Boolean(
                                  errors.lastName && touched.lastName
                                )}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.ListItem>
                    </Index.Grid>

                    <Index.Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.ListItem className="res-li-modal">
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column w-100"
                        >
                          <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input">
                            <Index.FormHelperText className="form-lable">
                              Mobile Number{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Index.FormHelperText>
                            <Index.Box
                              className="form-group country-code-main"
                              mb={2}
                            >
                              <MuiPhoneInput
                                defaultCountry="in"
                                onChange={(e, countryDetails) => {
                                  handleContactNo(countryDetails);
                                  setFieldValue(
                                    "mobileNo",
                                    e.replaceAll("-", "")
                                  );
                                }}
                                className="country-code-details"
                                name="mobileNo"
                                onBlur={handleBlur}
                                value={values.mobileNo}
                                error={Boolean(
                                  errors.mobileNo && touched.mobileNo
                                )}
                              />
                              {touched.mobileNo && errors.mobileNo && (
                                <Index.FormHelperText error>
                                  {errors.mobileNo}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.ListItem>
                    </Index.Grid>

                    {referral && (
                      <>
                        <Index.Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          className="set-grid-modal"
                        >
                          <Index.ListItem className="res-li-modal">
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column w-100"
                            >
                              <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input">
                                <Index.FormHelperText className="form-lable">
                                  Referral Code{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Index.FormHelperText>
                                <Index.Box className="form-group" mb={2}>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    value={
                                      referral
                                        ? Index.ReplaceCharsWithAsterisks(
                                            referral
                                          )
                                        : "-"
                                    }
                                    disabled={referral ? true : false}
                                    className="form-control"
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.ListItem>
                        </Index.Grid>
                      </>
                    )}

                    <Index.Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.ListItem>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column w-100"
                        >
                          <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input">
                            <Index.FormHelperText className="form-lable">
                              User Name <span style={{ color: "red" }}>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                inputProps={{ maxlength: 15 }}
                                className="form-control"
                                name="userName"
                                onBlur={handleBlur}
                                value={values.userName}
                                onChange={handleChange}
                                helperText={touched.userName && errors.userName}
                                error={Boolean(
                                  errors.userName && touched.userName
                                )}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.ListItem>
                    </Index.Grid>

                    <Index.Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.ListItem>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column w-100 "
                        >
                          <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input ">
                            <Index.FormHelperText className="form-lable">
                              Password <span style={{ color: "red" }}>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group pass_group_main">
                              <Index.Box className="form-group pass_group_main">
                                <Index.OutlinedInput
                                  className="form-control custom_form_control"
                                  inputProps={{ maxlength: 16 }}
                                  id="outlined-adornment-password"
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  onBlur={handleBlur}
                                  value={values.password}
                                  onChange={handleChange}
                                  helperText={
                                    touched.password && errors.password
                                  }
                                  error={Boolean(
                                    errors.password && touched.password
                                  )}
                                  endAdornment={
                                    <Index.InputAdornment
                                      position="end"
                                      className="pass_position"
                                    >
                                      <Index.IconButton
                                        className="icon_end_icon"
                                        aria-label="toggle password visibility"
                                        onClick={handleShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <Index.VisibilityOff />
                                        ) : (
                                          <Index.Visibility />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  }
                                  label="Password"
                                />
                                {touched.password && errors.password && (
                                  <Index.FormHelperText error>
                                    {errors.password}
                                  </Index.FormHelperText>
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.ListItem>
                    </Index.Grid>

                    <Index.Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.ListItem>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column w-100 "
                        >
                          <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input ">
                            <Index.FormHelperText className="form-lable">
                              Confirm Password{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group pass_group_main">
                              <Index.Box className="form-group pass_group_main">
                                <Index.OutlinedInput
                                  className="form-control custom_form_control"
                                  inputProps={{ maxlength: 16 }}
                                  id="outlined-adornment-password"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  name="confirmPassword"
                                  onBlur={handleBlur}
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  helperText={
                                    touched.confirmPassword &&
                                    errors.confirmPassword
                                  }
                                  error={Boolean(
                                    errors.confirmPassword &&
                                      touched.confirmPassword
                                  )}
                                  endAdornment={
                                    <Index.InputAdornment
                                      position="end"
                                      className="pass_position"
                                    >
                                      <Index.IconButton
                                        className="icon_end_icon"
                                        aria-label="toggle password visibility"
                                        onClick={handleShowConfirmPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showConfirmPassword ? (
                                          <Index.VisibilityOff />
                                        ) : (
                                          <Index.Visibility />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  }
                                  label="Password"
                                />
                                {touched.confirmPassword &&
                                  errors.confirmPassword && (
                                    <Index.FormHelperText error>
                                      {errors.confirmPassword}
                                    </Index.FormHelperText>
                                  )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.ListItem>
                    </Index.Grid>
                    {!referral && (
                      <Index.Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        className="set-grid-modal"
                      ></Index.Grid>
                    )}

                    <Index.Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className="set-grid-modal"
                    >
                      <Index.ListItem>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column w-100"
                        >
                          <Index.Box className="flex-all forgot-row">
                            <Index.Box className="checkbox-main">
                              <Index.FormControlLabel
                                control={<Index.Checkbox />}
                                label="I have read and agreed to Privacy Policy and Terms & Condition"
                                className="checkbox-lable"
                                onClick={(e) => setIsChecked(e.target.checked)}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.ListItem>
                      <Index.ListItem>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column w-100 set-grid-modal"
                        >
                          <Index.Box
                            className={
                              ischecked
                                ? "btn-main"
                                : "btn-main  btn-main-submit "
                            }
                          >
                            <Index.Button
                              className="btn login-btn w-100 btn-dark-comman-main"
                              type="submit"
                              disableRipple
                              disabled={ischecked ? false : true}
                              onClick={(e) => {
                                !flag && handleSubmit(e);
                              }}
                            >
                              Register
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.ListItem>
                    </Index.Grid>
                  </Index.Grid>
                </Form>
              )}
            </Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default SignUpUser;
