import React, { useState, useEffect } from "react";
import Index from "../../../component/Index";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { useSelector } from "react-redux";
import "../exchange/Exchange.css";
import PaginationContainer from "../../../component/common/Pagination";
import { useNavigate } from "react-router-dom";

const Market = () => {
  const navigate = useNavigate();
  const [gainerCoins, setGainerCoins] = useState([]);
  const [looserCoins, setLooserCoins] = useState([]);
  const [trendingCoins, setTrendingCoins] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [allCategoryData, setAllCategoryData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const token = useSelector((state) => state.user.userToken);

  // trending api
  const trendingCoinsData = async () => {
    await DataService(token)
      .get(API.Exchange.TRENDING_COINS)
      .then((res) => {
        setTrendingCoins(res?.data?.data);
      })
      .catch((error) => {});
  };

  // gainer looser api
  const gainerLooserCoinsData = async () => {
    await DataService(token)
      .get(API.Exchange.GET_GAINER_LOSER_LIST)
      .then((res) => {
        setGainerCoins(res?.data?.data?.gainerList);
        setLooserCoins(res?.data?.data?.looserList);
      })
      .catch((error) => {});
  };

  // all category data
  const getCategoryData = async () => {
    await DataService(token)
      .post(API.Exchange.GET_CATEGORY_DATA)
      .then((res) => {
        setCategoryData(res?.data?.data);
        setCategoryList(res?.data?.data);
      })
      .catch((error) => {});
  };

  // get all category
  const getAllCategoryData = async () => {
    await DataService(token)
      .get(API.Exchange.GET_CATEGORY)
      .then((res) => {
        console.log(res.data.data, 60);
        setAllCategoryData(res?.data?.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    trendingCoinsData();
    gainerLooserCoinsData();
    getCategoryData();
    getAllCategoryData();
  }, []);
  const requestSearch = (searched) => {
    if (!searched) return setCategoryData(categoryList);
    setCategoryData(
      categoryList.filter(
        (item) =>
          item?.name?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.symbol?.toLowerCase().includes(searched.toLowerCase())
      )
    );
  };
  const formatCash = (n) => {
    if (n < 1e3) return n.toFixed(2);
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  const handleCategoryDetails = async (id) => {
    setActiveCategory(id);
    const data = new URLSearchParams();
    data.append("category_id", id ? id : "");
    await DataService(token)
      .post(API.Exchange.GET_CATEGORY_DATA, data)
      .then((res) => {
        setCategoryData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Index.Box className="bnb-container">
        <Index.Box className="market-title">
          <Index.Typography className="bnb-token-name bnb-token-name">
            Markets Overview
          </Index.Typography>
        </Index.Box>

        <Index.Box className="chart-tab">
          <Index.Box className="main-chart main-chart-set">
            <Index.Box>
              {/* category data start */}
              <Index.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
                className="market-tabs-main market-tabs-main-set"
              >
                <Index.Typography
                  className={
                    !activeCategory
                      ? "coin-name market-category-text active"
                      : "coin-name market-category-text"
                  }
                  variant="p"
                  component="p"
                  onClick={() => handleCategoryDetails("")}
                >
                  All
                </Index.Typography>

                {allCategoryData?.map((item) => {
                  return (
                    <>
                      <Index.Typography
                        className={
                          activeCategory == item?._id
                            ? "coin-name market-category-text active"
                            : "coin-name market-category-text"
                        }
                        variant="p"
                        component="p"
                        onClick={() => handleCategoryDetails(item?._id)}
                      >
                        {item?.name}
                      </Index.Typography>
                    </>
                  );
                })}
              </Index.Box>
              {/* category data end */}

              {/* <Index.Box className="market-typography-box">
          <Index.Typography className="market-typography">
            Top Tokens by Market Capitalization
          </Index.Typography>
          <Index.Typography className="market-typography-paragraph">
            Get a comprehensive snapshot of all cryptocurrencies available on
            Binance. This page displays the latest prices, 24-hour trading
            volume, price changes, and market capitalizations for all
            cryptocurrencies on Binance. Users can quickly access key
            information about these digital assets and access the trade page
            from here.
          </Index.Typography>
          <Index.Typography className="market-typography-paragraph">
            The data presented is for informational purposes only. Some data is
            provided by CoinMarketCap and is shown on an “as is” basis, without
            representation or warranty of any kind. Please view our General Risk
            Warning for more information.
          </Index.Typography>
        </Index.Box> */}

              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main search-flex">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        placeholder="search"
                        className="form-control"
                        onChange={(e) => requestSearch(e.target.value)}
                      />

                      <img
                        src={Index.Svg.search}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              {/* Table start */}
              <Index.TableContainer component={Index.Paper}>
                <Index.Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <Index.TableHead>
                    <Index.TableRow className="table-header">
                      <Index.TableCell align="left">NAME</Index.TableCell>
                      <Index.TableCell align="left">PRICE</Index.TableCell>
                      <Index.TableCell align="left">1H%</Index.TableCell>
                      <Index.TableCell align="left">24H%</Index.TableCell>
                      <Index.TableCell align="left">7D%</Index.TableCell>
                      <Index.TableCell align="left">MARKET CAP</Index.TableCell>
                      <Index.TableCell align="left">
                        VOLUME(24H)
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody>
                    {currentRows?.length > 0 ? (
                      currentRows &&
                      currentRows.map((row) => (
                        <Index.TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          className="table-rows"
                        >
                          <Index.TableCell component="th" scope="row">
                            <Index.Box
                              className="market-table-body"
                              onClick={() => {
                                navigate(`/market/${row?.symbol}`, {
                                  state: {
                                    categoryData: row,
                                  },
                                });
                              }}
                            >
                              <Index.Box>
                                <img
                                  className="table-img"
                                  src={`${process.env.REACT_APP_IMAGE_URL}/${row?.image}`}
                                />
                              </Index.Box>
                              <Index.Box
                                component="p"
                                className="sort-review-table"
                              >
                                {row?.symbol}
                              </Index.Box>
                              <Index.Box
                                component="p"
                                sx={{ color: "#777E91", fontSize: "15px" }}
                              >
                                {row.name}
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            ${row.token_price?.toFixed(2)}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {row?.USD?.percent_change_1h?.toFixed(2)} %
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            className={
                              row?.USD?.percent_change_24h > 0
                                ? "change24-h-positive"
                                : "change24-h-negative"
                            }
                          >
                            {row?.USD?.percent_change_24h?.toFixed(2)} %
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            className={
                              row?.USD?.percent_change_7d > 0
                                ? "change24-h-positive"
                                : "change24-h-negative"
                            }
                          >
                            {row?.USD?.percent_change_7d?.toFixed(2)} %
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            ${formatCash(Number(row?.market_cap))}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            ${formatCash(Number(row?.market_cap))}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell colSpan={12} align="center">
                          No data found
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
              <Index.Box className="pagination-main market-pagination">
                <PaginationContainer
                  setCurrentRows={setCurrentRows}
                  allData={categoryData?.length > 0 ? categoryData : []}
                  pageData={currentRows}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* gainer looser */}
          <Index.Box>
            <Index.Box
              className="market-analysis-panel"
              sx={{ padding: "0px" }}
            >
              {/* trending list */}
              <Index.Box className="market-section-title market-section-title-set">
                <Index.Typography className="market-section-heading section-heading">
                  Top Trending Coins
                </Index.Typography>
                <Index.Box
                  component={Index.Paper}
                  className="market-hot-coins-paper"
                >
                  <Index.Box className="market-hot-coins-list">
                    {trendingCoins &&
                      trendingCoins.map((items) => {
                        return (
                          <Index.Box className="market-hot-coins-flex">
                            <Index.Box
                              className="market-img-box "
                              onClick={() => {
                                navigate(`/market/${items?.symbol}`, {
                                  state: {
                                    categoryData: items,
                                  },
                                });
                              }}
                            >
                              <img
                                className="table-img"
                                src={`${process.env.REACT_APP_IMAGE_URL}/${items?.image}`}
                              />
                              <Index.Box className="market-name">
                                {items?.symbol}
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="market-dollar">
                              ${items?.USD?.price?.toFixed(2)}
                            </Index.Box>
                            <Index.Box
                              className={`${
                                items?.USD?.percent_change_24h > 0
                                  ? "change24-h-positive"
                                  : "change24-h-negative"
                              } market-percentage`}
                            >
                              {items?.USD?.percent_change_24h > 0
                                ? "+" +
                                  " " +
                                  items?.USD?.percent_change_24h?.toFixed(2) +
                                  " " +
                                  "%"
                                : items?.USD?.percent_change_24h?.toFixed(2) +
                                  " " +
                                  "%"}
                            </Index.Box>
                          </Index.Box>
                        );
                      })}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="market-section-title">
                <Index.Typography className="market-section-heading section-heading">
                  Top Gainer Coins
                </Index.Typography>
                <Index.Box
                  component={Index.Paper}
                  className="market-hot-coins-paper"
                >
                  <Index.Box className="market-hot-coins-list">
                    {gainerCoins &&
                      gainerCoins.map((items) => {
                        return (
                          <Index.Box className="market-hot-coins-flex">
                            <Index.Box
                              className="market-img-box "
                              onClick={() => {
                                navigate(`/market/${items?.symbol}`, {
                                  state: {
                                    categoryData: items,
                                  },
                                });
                              }}
                            >
                              <img
                                className="table-img"
                                src={`${process.env.REACT_APP_IMAGE_URL}/${items?.image}`}
                              />
                              <Index.Box className="market-name">
                                {items?.symbol}
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="market-dollar">
                              ${items?.USD?.price?.toFixed(2)}
                            </Index.Box>
                            <Index.Box
                              className={`${
                                items?.USD?.percent_change_24h > 0
                                  ? "change24-h-positive"
                                  : "change24-h-negative"
                              } market-percentage`}
                            >
                              {items?.USD?.percent_change_24h > 0
                                ? "+" +
                                  " " +
                                  items?.USD?.percent_change_24h?.toFixed(2) +
                                  " " +
                                  "%"
                                : items?.USD?.percent_change_24h?.toFixed(2) +
                                  " " +
                                  "%"}
                            </Index.Box>
                          </Index.Box>
                        );
                      })}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="market-section-title">
                <Index.Typography className="market-section-heading section-heading">
                  Top Looser Coins
                </Index.Typography>
                <Index.Box
                  component={Index.Paper}
                  className="market-hot-coins-paper"
                >
                  <Index.Box className="market-hot-coins-list">
                    {looserCoins &&
                      looserCoins.map((items) => {
                        return (
                          <Index.Box className="market-hot-coins-flex">
                            <Index.Box
                              className="market-img-box "
                              onClick={() => {
                                navigate(`/market/${items?.symbol}`, {
                                  state: {
                                    categoryData: items,
                                  },
                                });
                              }}
                            >
                              <img
                                className="table-img"
                                src={`${process.env.REACT_APP_IMAGE_URL}/${items?.image}`}
                              />
                              <Index.Box className="market-name">
                                {items?.symbol}
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="market-dollar">
                              ${items?.USD?.price?.toFixed(2)}
                            </Index.Box>
                            <Index.Box
                              className={`${
                                items?.USD?.percent_change_24h > 0
                                  ? "change24-h-positive"
                                  : "change24-h-negative"
                              } market-percentage`}
                            >
                              {items?.USD?.percent_change_24h > 0
                                ? "+" +
                                  " " +
                                  items?.USD?.percent_change_24h?.toFixed(2) +
                                  " " +
                                  "%"
                                : items?.USD?.percent_change_24h?.toFixed(2) +
                                  " " +
                                  "%"}
                            </Index.Box>
                          </Index.Box>
                        );
                      })}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Market;
