import { padding } from "@mui/system";

const HeaderStyle = {
  mainContainer: {
    width: "100%",
    maxWidth: {
      xs: "100%",
      sm: "600px",
      md: "100%",
      lg: "1526px",
      xlg: "1526px",
    },
    maxHeight: "80px",
    backgroundColor: "Neutrals8",
    color: "Neutrals1",
    // border:'1px solid black',
    display: "flex",
    paddingRight: "15px",
    paddingLeft: "15px",
  },
  appbar: {
    // backgroundColor: "white",
    // border:'2px solid red',
    width: "100%",
    padding: { xs: "0", lg: "0" },
  },
  logo: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  vectorLogo: {
    height: "32px",
    width: "35px",
    borderRadius: "0px",
    marginRight: "6px",
  },
  ncxLogo: {
    height: "70px",
    width: "auto",
    // width: "25%",
    float: "center",
    borderRadius: "0px",
  },
  dividerHeader: {
    height: "40px",
    marginLeft: { md: "15px", lg: "20px" },
    backgroundColor: "#141416",
  },
  headerContent: {
    display: { xs: "none", lg: "flex" },
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft: { md: "20px", lg: "35px" },
    justifyContent: "flex-end",
  },
  IpadHeaderMenu: {
    fontFamily: "DMSans-Bold",
    border: "0",
    padding: "0",
  },
  Menu: {
    textDecoration: "none",
    color: "#FCFCFD",
    fontStyle: "normal",
    fontSize: { sm: "10px", md: "10px", lg: "14px" },
    fontWeight: "bold",
    whiteSpace: "nowrap",
    fontFamily: "DMSans-Bold",
    display: "block",
    marginRight: { sm: "10px", md: "20px", lg: "22px", xl: "48px" },
  },
  headerContent2: {
    display: { xs: "none", md: "flex" },
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft: "auto",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "40px",
    width: "464.52px",
  },
  Menu2: {
    textDecoration: "none",
    color: "text.black",
    fontSize: "15px",
    fontWeight: "700",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "30px",
    width: "30px",
    marginLeft: "24px",
    cursor: "pointer",
  },
  walletButton: {
    display: { xs: "none", md: "flex" },
    // borderColor: "#141416",
    // color: "#23262F",
    // borderColor:'Neutrals1',color:'Neutrals1',
    // backgroundColor: "#3772FF",
    color: "black",
    borderRadius: "0px",
    fontSize: "15px",
    fontWeight: "700",
    // marginLeft: "24px",
    // marginRight: "24px",
    // bgcolor: 'background.default',
  },
  menuicon: {
    display: { xs: "flex", md: "none" },
    marginLeft: "auto",
    width: "100%",
    justifyContent: "end",
  },
  icon: {
    margin: "16px",
    marginTop: "30px",
  },
};
export default HeaderStyle;
