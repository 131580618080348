import { createSlice } from "@reduxjs/toolkit";
import {
  getWalletDataList,
  loginUser,
  loginUserData,
  registerUserData,
  userSocialLogin,
  verifyEmail,
  walletData,
} from "./UserService";

const UserSlice = createSlice({
  name: "counter",
  initialState: {
    userRegister: [],
    isLoading: false,
    userData: {},
    userToken: "",
    isAuthenticated: false,
    registerUser: {},
    countryList: [],
    walletList:[]
  },
  reducers: {
    getUserProfile: (state, action) => {
      state.userData = action?.payload;
    },
    getUserData: (state, action) => {
      state.userData = action.payload;
    },
    getUserToken: (state, action) => {
      state.userToken = action.payload;
      state.isAuthenticated = true;
    },
    userLogout: (state, action) => {
      state.userToken = "";
      state.isAuthenticated = false;
      state.userData = {};
    },
  },
  extraReducers: (builder) => {
    // user register code start
    builder.addCase(registerUserData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(registerUserData.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(registerUserData.rejected, (state, action) => {
      state.isLoading = false;
    });
    // user register code end

    // user login
    builder.addCase(loginUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.userToken = action?.payload?.data?.token;
      state.userData = action?.payload?.data;
      state.isAuthenticated = true;
      state.isLoading = false;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.isLoading = false;
    });
    // user login end

    // user verifyEmail
    builder.addCase(verifyEmail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.userToken = action?.payload?.data?.accessToken;
      state.userData = action?.payload?.data;
      state.isLoading = false;
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.isLoading = false;
    });
    // user verifyEmail end
    builder.addCase(userSocialLogin.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(userSocialLogin.fulfilled, (state, action) => {
      state.userToken = action?.payload?.data?.accessToken;
      state.userData = action?.payload?.data;
      state.isLoading = false;
    });
    builder.addCase(userSocialLogin.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getWalletDataList.fulfilled, (state, action) => {
      state.walletList = action?.payload?.data;
    });
  },
});

export const { getUserData, getUserToken, userLogout, getUserProfile,getWallet } =
  UserSlice.actions;

export default UserSlice.reducer;
