import React, { useState, useEffect } from "react";
import { Pagination } from "@mui/material";
import Index from "../Index";

const PaginationContainer = ({ setCurrentRows, allData, pageData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    //Pagination
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = allData.slice(firstIndex, lastIndex);
    const numbersOfPage = Math.ceil(allData.length / recordsPerPage);
    setPageCount(numbersOfPage);
    setCurrentRows(records);
    if (records?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, allData]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      {pageData?.length !== 0 ? (
        <Index.Box className="pagination-main">
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            className="pagination market-pagination-left"
          />
        </Index.Box>
      ) : (
        ""
      )}
    </>
  );
};

export default PaginationContainer;
