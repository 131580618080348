import React from "react";
import Index from "../../Index";

const TopHeader = () => {
  return (
    <div>
      {" "}
      <Index.Box className="exchange-topbar bt-clear">
        <Index.Box className="topBarPort port bt-clear">
          <Index.Box className="topTools btTopToolsLeft">
            <Index.Link
              href="#"
              title=""
              target="_self"
              className="btIconWidget  btAccentIconWidget btWidgetWithText"
            >
              <Index.Box className="btIconWidgetIcon">
                <Index.Typography
                  data-ico-remixicons-map="&#xe946;"
                  className="bt_bb_icon_holder"
                ></Index.Typography>
              </Index.Box>
              <Index.Box className="btIconWidgetContent">
                <Index.Typography className="btIconWidgetTitle">
                  Welcome To FBC Exchange
                </Index.Typography>
              </Index.Box>
            </Index.Link>
            <Index.Link
              href="tel:0800999988"
              title=""
              target="_self"
              className="btIconWidget  btAccentIconWidget btWidgetWithText"
            >
              <Index.Box className="btIconWidgetIcon">
                <Index.Typography
                  data-ico-remixicons-device="&#xe94e;"
                  className="bt_bb_icon_holder"
                ></Index.Typography>
              </Index.Box>
              <Index.Box className="btIconWidgetContent">
                <Index.Typography className="btIconWidgetTitle"></Index.Typography>
              </Index.Box>
            </Index.Link>
          </Index.Box>

          <Index.Box className="topTools btTopToolsRight">
            <Index.Box className="btIconWidget btBreakRowMobile btWidgetWithText">
              <Index.Box className="btIconWidgetContent">
                <Index.Typography className="btIconWidgetTitle">
                  Visit us on social networks
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Link
              href="#"
              title=""
              target="_self"
              className="btIconWidget  btAccentIconWidget"
            >
              <Index.Box className="btIconWidgetIcon">
                <Index.Typography
                  data-ico-remixicons-logos="&#xe930;"
                  className="bt_bb_icon_holder"
                ></Index.Typography>
              </Index.Box>
            </Index.Link>
            <Index.Link
              href="#"
              title=""
              target="_self"
              className="btIconWidget  btAccentIconWidget"
            >
              <Index.Box className="btIconWidgetIcon">
                <Index.Typography
                  data-ico-remixicons-logos="&#xe99c;"
                  className="bt_bb_icon_holder"
                ></Index.Typography>
              </Index.Box>
            </Index.Link>
            <Index.Link
              href="#"
              title=""
              target="_self"
              className="btIconWidget  btAccentIconWidget"
            >
              <Index.Box className="btIconWidgetIcon">
                <Index.Typography
                  data-ico-remixicons-logos="&#xe950;"
                  className="bt_bb_icon_holder"
                ></Index.Typography>
              </Index.Box>
            </Index.Link>
            <Index.Link
              href="#"
              title=""
              target="_self"
              className="btIconWidget  btAccentIconWidget"
            >
              <Index.Box className="btIconWidgetIcon">
                <Index.Typography
                  data-ico-remixicons-logos="&#xe93e;"
                  className="bt_bb_icon_holder"
                ></Index.Typography>
              </Index.Box>
            </Index.Link>
            <Index.Link
              href="#"
              title=""
              target="_self"
              className="btIconWidget  btAccentIconWidget"
            >
              <Index.Box className="btIconWidgetIcon">
                <Index.Typography
                  data-ico-remixicons-logos="&#xe946;"
                  className="bt_bb_icon_holder"
                ></Index.Typography>
              </Index.Box>
            </Index.Link>{" "}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default TopHeader;
