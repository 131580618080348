import React from "react";
import "../../../assets/css/zoya.css";
import Index from "../../../component/Index";
import Png from "../../../assets/Png";

const CaseStudies = () => {
  return (
    <>
      <Index.Box className="case_studies">
        <Index.Box className="case-studies-heading">
          <Index.Typography variant="h2">Case Studies</Index.Typography>
        </Index.Box>
        <Index.Box className="case-studies-main">
          <Index.Box className="case-studies-one">
            <Index.Grid container direction={"column"}>
              <Index.Grid item sm={6} className="grid-box-performance  ">
                <Index.Box className="case-studies-performance">
                  <Index.Typography variant="h5">Simplicity</Index.Typography>
                </Index.Box>
                <Index.Box className="case-studies-performance-content">
                  <Index.Typography variant="p">
                    FBC makes it easy to buy crypto using our mobile apps.
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>
              <Index.Grid
                item
                sm={6}
                className="grid-box-performance Corporate"
              >
                <Index.Box className="case-studies-performance Corporate">
                  <Index.Typography variant="h5">Education</Index.Typography>
                </Index.Box>
                <Index.Box className="case-studies-performance-content Corporate">
                  <Index.Typography variant="p">
                    Not sure where to start? Head to our Learn Center and learn
                    about all things crypto.
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
          <Index.Box className="case-studies-two ">
            {/* <Index.Box className="case-studies-heading">
              <Index.Typography variant="h1">Case studies</Index.Typography>
            </Index.Box>
            <Index.Box className="case-studies-button solution portfolio">
              <Index.Link
                href="#"
                target="_self"
                class="bt_bb_link"
                title="View solutions"
              >
                <span class="bt_bb_button_text">Service</span>
                <span
                  data-ico-remixicons-others="&#xe92f;"
                  class="bt_bb_icon_holder"
                ></span>
              </Index.Link>
            </Index.Box> */}
          </Index.Box>
          <Index.Box className="case-studies-three">
            <Index.Grid container direction={"column"}>
              <Index.Grid item sm={6} className=" grid-box-right">
                <Index.Box className="case-studies-right-heading">
                  <Index.Typography variant="h5">Transparency</Index.Typography>
                </Index.Box>
                <Index.Box className="case-studies-right-content">
                  <Index.Typography variant="p">
                    Find your answers instantly in our Support Center. Or reach
                    us 24/7/365 on Live Chat.
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item sm={6} className=" grid-box-right">
                <Index.Box className="case-studies-right-heading">
                  <Index.Typography variant="h5">Scalability</Index.Typography>
                </Index.Box>
                <Index.Box className="case-studies-right-content">
                  <Index.Typography variant="p">
                    Adapt rapidly to market opportunities and threats in a
                    cost-effective and productive manner, for example, processes
                    enabled via flexible technology.
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Box>

        <Index.Box class="case_studies_coverage">
          <img
            src={Png.bgCoverLayer}
            alt="bt_bb_section_bottom_section_coverage_image"
          />
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default CaseStudies;
