import React from "react";
import "../../../assets/css/zoya.css";
import Index from "../../Index";
import Png from "../../../assets/Png";
import TopHeader from "./TopHeader";
import MenuHeader from "./MenuHeader";

const NewHeader = () => {
  return (
    <>
      <Index.Box>
        <TopHeader />
      </Index.Box>
      <Index.Box className="main-background main_banner">
        <Index.Box>
          <MenuHeader />
        </Index.Box>

        <Index.Box className="mainHeader banner_content">
          <Index.Box className="header">
            <header class="bt_bb_headline bt_bb_dash_none bt_bb_dash_type_line bt_bb_dash_color_accent bt_bb_size_extrahuge bt_bb_align_inherit">
              <h2 class="bt_bb_headline_tag bt_bb_line_height_1">
                <span class="bt_bb_headline_content">
                  <span>Introduction</span>
                </span>
              </h2>
            </header>
            <header class=" bt_bb_dash_none bt_bb_dash_type_line bt_bb_dash_color_accent bt_bb_size_extralarge bt_bb_subheadline bt_bb_align_inherit">
              <Index.Box class="bt_bb_headline_subheadline">
                Farmar Bright Coin by FBC Network provides revolutionary
                ecosystem specially designed for agriculture industries. Idea is
                to create system that brings more transparency, security,
                benefits by bringing environment friendly thoughts and concepts.
                Aiming to creating a top level community which can provide their
                members a limitless opportunity and infrastructure to grow.
              </Index.Box>
            </header>

            <Index.Box class="bt_bb_button solution bt_bb_align_center">
              <Index.Link
                href="#"
                target="_self"
                class="bt_bb_link"
                title="View solutions"
              >
                <span class="bt_bb_button_text">WhitePaper</span>
                <span
                  data-ico-remixicons-others="&#xe92f;"
                  class="bt_bb_icon_holder"
                ></span>
              </Index.Link>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box class="coverage_image">
          <img
            src={Png.bottomImage}
            alt="bt_bb_section_bottom_section_coverage_image"
          />
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default NewHeader;
