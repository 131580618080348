import React, { useEffect, useState } from "react";
import Index from "../../../component/Index";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { useSelector } from "react-redux";

const TradeList = () => {
  const [tradeList, setTradeList] = useState([]);
  const token = useSelector((state) => state.user.userToken);

  const getTradingData = async () => {
    await DataService(token)
      .get(API.Exchange.TRADING_LIST)
      .then((res) => {
        setTradeList(
          res?.data?.data?.map((ele) => {
            return {
              ...ele,
              proLoss:
                ((ele?.price * ele?.quantity - ele?.avg_price * ele?.quantity) /
                  ele?.price) *
                100,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getTradingData();
  }, []);

  return (
    <>
      <Index.Box className="exchange-main">
        <Index.Box className="main-tab-container">
          <Index.Box className="main-table-container">
            <Index.Box className="main-title-sec">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Trade List
              </Index.Typography>
            </Index.Box>
            <Index.TableContainer component={Index.Paper}>
              <Index.Table className="main-table" aria-label="simple table">
                <Index.TableHead>
                  <Index.TableRow className="table-header">
                    <Index.TableCell align="left">Date</Index.TableCell>
                    <Index.TableCell align="center">Type</Index.TableCell>
                    <Index.TableCell align="center">Quantity</Index.TableCell>
                    <Index.TableCell align="center">Price</Index.TableCell>
                    <Index.TableCell align="center">
                      Profit && Loss
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {tradeList?.map((item, i) => (
                    <Index.TableRow key={i} className="table-rows">
                      <Index.TableCell align="left">
                        {item?.createdAt
                          ? Index.moment(item?.createdAt).format(
                              "DD-MM-YYYY hh:mm A"
                            )
                          : "-"}
                      </Index.TableCell>
                      <Index.TableCell align="center">
                        {item?.type ? item?.type : "-"}
                      </Index.TableCell>
                      <Index.TableCell align="center">
                        {item?.quantity ? item?.quantity : "-"}
                      </Index.TableCell>
                      <Index.TableCell align="center">
                        {item?.price ? item?.price : "-"}
                      </Index.TableCell>
                      <Index.TableCell
                        align="center"
                        className={
                          item?.proLoss > 0
                            ? "change24-h-positive"
                            : item?.proLoss < 0
                            ? "change24-h-negative"
                            : ""
                        }
                      >
                        {item?.type == "sell" ? item?.proLoss?.toFixed(2) : ""}
                        {item?.proLoss ? "%" : ""}
                      </Index.TableCell>
                    </Index.TableRow>
                  ))}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default TradeList;
