import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createChart } from "lightweight-charts";
import {
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { useSelector } from "react-redux";
import MarketChart from "../market/MasterChart";
import { toast } from "react-toastify";
import Index from "../../../component/Index";
import BuyModel from "./BuyModel";
import SellModel from "./SellModel";
const MarketView = () => {
  const location = useLocation();
  const coinDetails = location.state;
  const navigate = useNavigate();
  // const marketSymbol = state?.symbol;
  const { symbol } = useParams();
  console.log(symbol, 6);
  console.log(coinDetails, 7);
  const chartContainer = useRef(null);
  //   useEffect(() => {
  //     const chart = createChart(chartContainer.current, {
  //       width: 400,
  //       height: 300,
  //     });

  //     const lineSeries = chart.addLineSeries();
  //     lineSeries.setData([
  //       { time: "2023-01-01", value: 100 },
  //       { time: "2023-01-02", value: 120 },
  //       // Add more data points...
  //     ]);
  //   }, []);
  const [marketDetails, setMarketDetails] = useState({});
  console.log(marketDetails, "marketDetails");
  const token = useSelector((state) => state.user.userToken);
  const userData = useSelector((store) => store.user.userData);
  const isUserLogin = useSelector((store) => store.user.isAuthenticated);

  const [openSwap, setOpenSwap] = useState(false);
  const handleOpenSwap = () => setOpenSwap(true);
  const handleCloseSwap = () => {
    setOpenSwap(false);
  };
  const [settingDetials, setSettingDetails] = useState({});
  const [allCoins, setAllCoins] = useState([]);
  const [gainerList, setGainerList] = useState([]);
  const [loserList, setLooserList] = useState([]);
  const [walletList, setWalletList] = useState([]);
  console.log(walletList, "walletList");
  const getAllCoinsDetails = async () => {
    await DataService(token)
      .get(API.Exchange.TRENDING_COINS)
      .then((res) => {
        setAllCoins(res?.data?.data);
      })
      .catch((error) => {
        console.log(error, ":error");
      });
  };
  const getTopGainLoseList = async () => {
    await DataService(token)
      .get(API.Exchange.GET_GAINER_LOSER_LIST)
      .then((res) => {
        setGainerList(res?.data?.data?.gainerList);
        setLooserList(res?.data?.data?.looserList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllCoinsDetails();
    getTopGainLoseList();
    getWalletList();
  }, []);
  const getMarketDetails = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("symbol", symbol);
    await DataService(token)
      .post(API.Exchange.TOKEN_DETAILS, urlencoded)
      .then((res) => {
        setMarketDetails(res?.data?.data);
      })
      .catch((error) => {
        console.log(error, ":Error");
      });
  };

  useEffect(() => {
    getMarketDetails();
  }, [symbol]);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const getWalletList = async () => {
    await DataService(token)
      .get(API.Exchange.GET_WALLET)
      .then((res) => {
        setWalletList(res?.data?.data);
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  const getSettingDetails = async () => {
    await DataService(token)
      .get(API.User.GET_SETTING)
      .then((res) => {
        setSettingDetails(res?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.data?.status == 400) {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  useEffect(() => {
    getSettingDetails();
  }, []);
  const handleClick = () => {
    if (token) {
      toast.warning("Upcoming");
      // navigate("/new-design/exchange-dashboard");
    } else {
      navigate("/login");
    }
  };
  const [openBuy, setOpenBuy] = useState(false);
  const [openSell, setOpenSell] = useState(false);

  const handleOpenBuyModal = () => {
    if (marketDetails?.is_buyable === true) {
      setOpenBuy(true);
    } else {
      toast.warning("Upcoming");
    }
  };

  const handleOpenSellModal = () => {
    if (marketDetails?.is_sellable === true) {
      setOpenSell(true);
    } else {
      toast.warning("Upcoming");
    }
  };
  const handleCloseBuyModal = () => {
    setOpenBuy(false);
  };
  const handleCloseSellModal = () => {
    setOpenSell(false);
  };

  return (
    <>
      {/* <h1>Chart</h1> */}
      {/* <div ref={chartContainer} />; */}
      <Box className="main-bnb-center">
        <Box className="bnb-container">
          <Box className="bnb-heading-select">
            <Box className="bnb-heading">
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}/${marketDetails?.image}`}
                style={{ height: 48, width: 48 }}
              />

              <Typography
                variant="h1"
                component="h1"
                className="bnb-token-name"
              >
                {marketDetails?.name}

                <span>({marketDetails?.symbol})</span>
              </Typography>
            </Box>

            {/* <p className="market-token-name ">
              {location?.state?.categoryData?.category_id?.title}
            </p> */}
          </Box>
          <Box className="chart-tab">
            <Box className="main-chart">
              <Box className="price-inc-day">
                <Box className="bnb-price-chart">
                  $ {marketDetails?.token_price?.toFixed(2)}
                </Box>

                <Box
                  className={
                    Number(marketDetails?.h_change) > 0
                      ? "bnb-inc-chart"
                      : "bnb-inc-chart price-red"
                  }
                >
                  {Number(marketDetails?.h_change) > 0
                    ? `+${Number(marketDetails?.h_change)?.toFixed(2)} %`
                    : Number(marketDetails?.h_change)?.toFixed(2)}
                </Box>
                {/* <Box className="bnb-day-chart">(1D)</Box> */}
              </Box>
              <Box className="chart-design">
                {marketDetails?.trading_view_symbol &&
                  marketDetails?.trading_view_symbol != "" && (
                    <MarketChart symbol={marketDetails?.trading_view_symbol} />
                  )}
              </Box>
              <Box className="token-para">
                <Typography component="h6" className="token-big-txt">
                  About Us
                </Typography>
                <Typography component="p" className="token-sml-txt">
                  {marketDetails?.description}
                </Typography>
              </Box>
              <Box className="token-para">
                <Box className="bnb-heading">
                  <Typography
                    variant="h6"
                    component="h6"
                    className="sub-heading token-big-txt"
                  >
                    {marketDetails?.name} Resources
                  </Typography>
                </Box>
                <Box>
                  <Box className="flex-btn">
                    {/* <LanguageIcon /> */}
                    <Typography
                      variant="p"
                      component="p"
                      className="sub-heading"
                      sx={{ marginLeft: "5px" }}
                    >
                      <a href={marketDetails?.website} target="_blank">
                        Official {marketDetails?.name} Website
                      </a>
                    </Typography>
                  </Box>
                  <Box className="flex-btn">
                    {/* <MenuBookIcon /> */}
                    <Typography
                      variant="p"
                      component="p"
                      className="sub-heading"
                      sx={{ marginLeft: "5px" }}
                    >
                      <a
                        href={settingDetials?.white_paper_link}
                        target="_blank"
                      >
                        Whitepaper
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="main-tabbar">
              <Box className="sub-tabs">
                <Box sx={{ width: "100%" }}>
                  <Box className="bnb-heading">
                    {/* <img
                      src={`http://35.177.56.74:3029/api/images/${marketDetails?.image}`}
                      style={{ height: 48, width: 48 }}
                    /> */}
                    <Typography
                      variant="h5"
                      component="h5"
                      className="bnb-token-name"
                    >
                      Make a Trade
                    </Typography>
                  </Box>
                  <Box className="main-head-right sub-tab-buttons">
                    <Button
                      className="trading-btns  header-wallet-btn buttons"
                      variant="contained"
                      onClick={handleOpenBuyModal}
                    >
                      Buy
                    </Button>
                    <BuyModel
                      open={openBuy}
                      handleClose={handleCloseBuyModal}
                      marketDetails={marketDetails && marketDetails}
                      tokenBalance={
                        walletList.length > 0 && walletList[0].token_balance
                      }
                    />
                    <Button
                      className="trading-btns header-wallet-btn buttons"
                      variant="contained"
                      onClick={handleOpenSellModal}
                    >
                      Sell
                    </Button>
                    <SellModel
                      open={openSell}
                      handleClose={handleCloseSellModal}
                      marketDetails={marketDetails && marketDetails}
                      tokenBalance={
                        walletList.length > 0 && walletList[0].token_balance
                      }
                    />
                  </Box>
                  <Box className="main-tabbar">
                    {/* <Box className="main-tabbar">
                      <Box className="sub-tabs">
                        <Box className="market-section-title">
                          <Box className="bnb-heading">
                            <Typography
                              variant="h6"
                              component="h6"
                              className="sub-heading"
                            >
                              Trending Coins
                            </Typography>
                          </Box>
                          <Box className="market-hot-coins" mt={2}>
                            <Box>
                              {allCoins?.map((item) => {
                                return (
                                  <>
                                    <Box className="market-box exchange-market review-market bnb-token-details">
                                      <Box className="Bnb-price-flex">
                                        <Box>
                                          <Box
                                            className="verify-user"
                                            onClick={() =>
                                              navigate(
                                                `/market/${item?.symbol}`
                                              )
                                            }
                                          >
                                            <img
                                              className="coin-svg"
                                              src={`${process.env.REACT_APP_IMAGE_URL}/${item?.image}`}
                                              width="40px"
                                              height="40px"
                                            />
                                            <Box
                                              sx={{
                                                display: "inline-block",
                                                marginLeft: "15px",
                                              }}
                                            >
                                              <Box
                                                component="p"
                                                className="market-token-name"
                                              >
                                                {item?.name}
                                              </Box>
                                              <Box
                                                component="p"
                                                className="market-token-name"
                                                sx={{ color: "#777E91" }}
                                              >
                                                {item?.symbol}
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className="market-box-data market-token-price">
                                          <Box className="upper-box">
                                            <p className="coin-name bnb-token-price">
                                              $ {item?.USD?.price?.toFixed(2)}
                                            </p>
                                          </Box>
                                          <Box className="upper-box">
                                            <p className="coin-name bnb-token-price">
                                              $ {item?.USD?.percent_change_1h?.toFixed(2)}
                                            </p>
                                          </Box>
                                          {/* <Box className="upper-box">
                                            {!Index.checkPositive(
                                              item?.USD?.percent_change_1h
                                            ) && (
                                              <Box className="market-box-data">
                                                <Box className="upper-box">
                                                  <p className="coin-name price-percent">
                                                    +{" "}
                                                    {!Index.checkPositive(
                                                      item?.USD
                                                        ?.percent_change_1h
                                                    ) &&
                                                      item?.USD?.percent_change_1h?.toFixed(
                                                        2
                                                      )}{" "}
                                                    %
                                                  </p>
                                                </Box>
                                              </Box>
                                            )}
                                            {Index.checkPositive(
                                              item?.USD?.percent_change_1h
                                            ) && (
                                              <Box className="market-box-data">
                                                <Box className="upper-box price-red">
                                                  <p className="coin-name price-red">
                                                    {item?.USD?.percent_change_1h?.toFixed(
                                                      2
                                                    )}{" "}
                                                    %
                                                  </p>
                                                </Box>
                                              </Box>
                                            )}
                                          </Box> */}
                    {/* </Box>
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box> */}
                    <Index.Box className="sub-tabs">
                      <Index.Box className="main-tabbar-trending">
                        <Index.Box className="trending-section-heading">
                          <Index.Typography className="section-heading">
                            Trending Coins
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="trending-section-data">
                          <Index.Box>
                            {allCoins.map((trending) => (
                              <>
                                <Index.Box className="trending-data">
                                  <Index.Box className="sub-data-tab">
                                    <Index.Box>
                                      <Index.Box
                                        className="name-data"
                                        onClick={() => {
                                          navigate(
                                            `/market/${trending?.symbol}`,
                                            {
                                              state: {
                                                categoryData: trending,
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        <img
                                          src={`${process.env.REACT_APP_IMAGE_URL}/${trending.image}`}
                                        />

                                        <Index.Box className="name-text-data">
                                          <Index.Typography className="name-text">
                                            {`${trending.name} `}
                                          </Index.Typography>
                                          <Index.Typography className="name-text-2">
                                            {`${trending.symbol} `}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="trending-percents">
                                      <Index.Box className="trending-price">
                                        <Index.Typography className="price">
                                          {trending?.USD?.price?.toFixed(2)}
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trending-price">
                                        <Index.Box className="percent-box">
                                          <Index.Typography
                                            className={
                                              trending?.USD?.percent_change_1h
                                                ? "percent-positive"
                                                : "percent-negative"
                                            }
                                          >
                                            {/* {trending.USD.percent_change_1h.toFixed(
                                            2
                                          )}
                                          % */}
                                            {trending?.USD?.percent_change_1h >
                                            0
                                              ? "+" +
                                                " " +
                                                trending?.USD?.percent_change_1h?.toFixed(
                                                  2
                                                ) +
                                                " " +
                                                "%"
                                              : trending?.USD?.percent_change_1h?.toFixed(
                                                  2
                                                ) +
                                                " " +
                                                "%"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box></Index.Box>
                              </>
                            ))}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="sub-tabs">
                      <Index.Box className="main-tabbar-trending">
                        <Index.Box className="trending-section-heading">
                          <Index.Typography className="section-heading">
                            Top 5 Gainers
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="trending-section-data">
                          <Index.Box>
                            {gainerList?.map((trending) => (
                              <>
                                <Index.Box className="trending-data">
                                  <Index.Box className="sub-data-tab">
                                    <Index.Box>
                                      <Index.Box
                                        className="name-data"
                                        onClick={() => {
                                          navigate(
                                            `/market/${trending?.symbol}`,
                                            {
                                              state: {
                                                categoryData: trending,
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        <img
                                          src={`${process.env.REACT_APP_IMAGE_URL}/${trending?.image}`}
                                        />

                                        <Index.Box className="name-text-data">
                                          <Index.Typography className="name-text">
                                            {`${trending?.name} `}
                                          </Index.Typography>
                                          <Index.Typography className="name-text-2">
                                            {`${trending?.symbol} `}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="trending-percents">
                                      <Index.Box className="trending-price">
                                        <Index.Typography className="price">
                                          {trending?.USD?.price?.toFixed(2)}
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="trending-price">
                                        <Index.Box className="percent-box">
                                          <Index.Typography
                                            className={
                                              trending?.USD?.percent_change_1h
                                                ? "percent-positive"
                                                : "percent-negative"
                                            }
                                          >
                                            {/* {trending.USD.percent_change_1h.toFixed(
                                            2
                                          )}
                                          % */}
                                            {trending?.USD?.percent_change_1h >
                                            0
                                              ? "+" +
                                                " " +
                                                trending?.USD?.percent_change_1h?.toFixed(
                                                  2
                                                ) +
                                                " " +
                                                "%"
                                              : trending?.USD?.percent_change_1h?.toFixed(
                                                  2
                                                ) +
                                                " " +
                                                "%"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box></Index.Box>
                              </>
                            ))}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="sub-tabs">
                      <Index.Box className="main-tabbar-trending">
                        <Index.Box className="trending-section-heading">
                          <Index.Typography className="section-heading">
                            Top 5 Losers
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="trending-section-data">
                          <Index.Box>
                            {loserList &&
                              loserList?.map((trending) => (
                                <>
                                  <Index.Box className="trending-data">
                                    <Index.Box className="sub-data-tab">
                                      <Index.Box>
                                        <Index.Box
                                          className="name-data"
                                          onClick={() => {
                                            navigate(
                                              `/market/${trending?.symbol}`,
                                              {
                                                state: {
                                                  categoryData: trending,
                                                },
                                              }
                                            );
                                          }}
                                        >
                                          <img
                                            src={`${process.env.REACT_APP_IMAGE_URL}/${trending.image}`}
                                          />

                                          <Index.Box className="name-text-data">
                                            <Index.Typography className="name-text">
                                              {`${trending.name} `}
                                            </Index.Typography>
                                            <Index.Typography className="name-text-2">
                                              {`${trending.symbol} `}
                                            </Index.Typography>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>

                                      <Index.Box className="trending-percents">
                                        <Index.Box className="trending-price">
                                          <Index.Typography className="price">
                                            {trending.USD.price?.toFixed(2)}
                                          </Index.Typography>
                                        </Index.Box>
                                        <Index.Box className="trending-price">
                                          <Index.Box className="percent-box">
                                            <Index.Typography
                                              className={
                                                trending.USD.percent_change_1h
                                                  ? "percent-positive"
                                                  : "percent-negative"
                                              }
                                            >
                                              {/* {trending.USD.percent_change_1h.toFixed(
                                            2
                                          )}
                                          % */}
                                              {trending.USD.percent_change_1h >
                                              0
                                                ? "+" +
                                                  " " +
                                                  trending.USD.percent_change_1h?.toFixed(
                                                    2
                                                  ) +
                                                  " " +
                                                  "%"
                                                : trending.USD.percent_change_1h?.toFixed(
                                                    2
                                                  ) +
                                                  " " +
                                                  "%"}
                                            </Index.Typography>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box></Index.Box>
                                </>
                              ))}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MarketView;
