import React from "react";
import Index from "../../../component/Index";
import { useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { toast } from "react-toastify";
const SellModel = ({ open, handleClose, marketDetails, tokenBalance }) => {
  const token = useSelector((state) => state.user.userToken);

  const [sellAmount, setSellAmount] = useState(0);
  console.log(marketDetails.token_price, "open1");
  const WithdrawCryptoSchema = Yup.object().shape({
    quantity: Yup.number().required("Please enter quantity"),
  });
  const handleFormSubmit = (values) => {
    getBuyData(values.amount);
  };
  const handleCloseModal = () => {
    setSellAmount(0);
    handleClose();
  };
  const getBuyData = async (amount) => {
    const data = new URLSearchParams();
    data.append("amount", amount);
    data.append("token_name", marketDetails.name);
    await DataService(token)
      .post(API.Exchange.SELL_TOKEN, data)
      .then((res) => {
        //  setIsLoading(false);
        console.log(res, 6464);
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
          console.log(res);
          // resetForm();
          //    dispatch(getWalletDataList(token));
          //    setOpen(false)
        }
      })
      .catch((error) => {
        //  setIsLoading(false);
        toast.error(error?.response?.data?.message);
      });
    //    formikRef.current.resetForm();
  };
  const calculateQuantityAndTotal = (totalQuantity, setFieldValue) => {
    console.log(totalQuantity, 55);
    const tokenPrice = marketDetails.token_price;
    const transactionChargePercentage = 1;
    // const effectiveAmount = amount - transactionCharge;
    const calculatedAmount = totalQuantity * tokenPrice;
    const transactionCharge =
      (calculatedAmount * transactionChargePercentage) / 100;
    const total = calculatedAmount - transactionCharge;
    setFieldValue("amount", calculatedAmount.toFixed(2));
    // const calculatedTotal = calculatedQuantity * tokenPrice;

    setSellAmount(total);
    // setQuantity(calculatedQuantity);
    // setTotal(calculatedTotal);
  };
  return (
    <Index.Dialog fullWidth maxWidth="xs" open={open} className="dialog-box">
      <Formik
        initialValues={{
          amount: "",
          quantity: "",
          transactionFees: "1%",
        }}
        validationSchema={WithdrawCryptoSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} className="modal-form">
            {console.log(errors, 555)}

            <Index.DialogTitle className="dialog-title">
              Sell Token
              <Index.CloseIcon
                className="model-close-icon"
                onClick={handleCloseModal}
              />
            </Index.DialogTitle>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box">
                <Index.FormHelperText className="form-lable">
                  Enter Quantity
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    id="fullWidth"
                    name="quantity"
                    fullWidth
                    type="number"
                    className="form-control"
                    placeholder=""
                    autocomplete="off"
                    onBlur={handleBlur}
                    onWheel={(e) => e.target.blur()}
                    helperText={touched.quantity && errors.quantity}
                    error={Boolean(errors.quantity && touched.quantity)}
                    value={values.quantity}
                    onChange={(e) => {
                      const totalQuantity = e.target.value;
                      setFieldValue("quantity", totalQuantity);
                      calculateQuantityAndTotal(totalQuantity, setFieldValue);
                    }}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box">
                <Index.FormHelperText className="form-lable">
                  Amount
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    id="fullWidth"
                    disabled
                    fullWidth
                    name="amount"
                    className="form-control"
                    placeholder=""
                    autocomplete="off"
                    onBlur={handleBlur}
                    value={values.amount}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box">
                <Index.Box className="available-token-sec">
                  <Index.Typography
                    className="available-titles"
                    variant="p"
                    component="p"
                  >
                    Total Amount <br />{" "}
                    {sellAmount ? sellAmount?.toFixed(2) : 0}
                  </Index.Typography>
                  <Index.Typography
                    className="available-data"
                    variant="p"
                    component="p"
                  >
                    Transaction Fees : <br /> 1%
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box">
                <Index.Box className="available-token-sec">
                  <Index.Typography
                    className="available-titles"
                    variant="p"
                    component="p"
                  >
                    Available Balance
                  </Index.Typography>
                  <Index.Typography
                    className="available-data"
                    variant="p"
                    component="p"
                  >
                    {tokenBalance ? tokenBalance.toFixed(2) : 0}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="sub-tab-buttons">
              <Index.Button
                className="trading-btns buttons trade-buttons"
                variant="contained"
                type="submit"
                fullWidth
              >
                Sell
              </Index.Button>
            </Index.Box>
          </Form>
        )}
      </Formik>
    </Index.Dialog>
  );
};

export default SellModel;
