import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { DataService } from "../config/DataService";
import { API } from "../config/Api";
import {
  getUserProfile,
  userLogout,
} from "../redux/features/slices/User/UserSlice";
import Chatboat from "../component/common/chatboat";

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Check token expiry
  const isValidToken = (accessToken) => {
    if (!accessToken) return false;

    return true;

    // const decodedToken = jwtDecode(accessToken);
    // const currentTime = Date.now() / 1000;
    // if (decodedToken.exp > currentTime) {
    //   // Set access token in axios instance
    // }
    // axios.defaults.headers.common.auth = token;
    // return decodedToken.exp > currentTime;
  };

  useEffect(() => {
    DataService(userToken)
      .get(API.User.GET_USER)
      .then((res) => {
        dispatch(getUserProfile(res?.data?.data));
      })
      .catch((error) => {
        if (error?.response?.data?.status == 401) {
          toast.error(error?.response?.data?.message);
          dispatch(userLogout());
          navigate("/login");
        }
      });
  }, []);

  const isLogin = useSelector((state) => state.user.isAuthenticated);
  const userToken = useSelector((store) => store.user.userToken);

  return (
    <>
      {isLogin && isValidToken(userToken) ? (
        <Outlet />
      ) : (
        <Navigate to="/login" />
      )}
      <Chatboat />
    </>
  );
};

export default PrivateRoutes;
