import React from "react";
import Index from "../../../../component/Index";
import { useState } from "react";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import QrCodeGenerate from "../../../../component/common/QrCodeGenerate";
import { BrowserDetect } from "../../../../component/common/DetectBrowser";
import { PieChart } from "../DashBoardPieChart";

const Deposit = () => {
  const userToken = useSelector((state) => state?.user?.userToken);
  const walletList = useSelector((state) => state.user.walletList);
  const browser = BrowserDetect();
  const [network, setNetwork] = useState([]);
  const [settingDetails, setSettingDetails] = useState({});

  const [chainId, setChainId] = useState(56);
  const [selectedNetwork, setSelectedNetwork] = useState("BNB Smart Chain");
  const [hash, setHash] = useState("");
  const [ipAddress, setIpAddress] = useState("");

  const [transactionError, setTransactionError] = useState("");
  const [networkError, setNetworkError] = useState("");


  const getIpAddress = async () => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
  };

  const getSettingDetails = () => {
    DataService(userToken)
      .get(API.User.GET_SETTING)
      .then((res) => {
        setSettingDetails(res?.data?.data);
        setNetwork(res?.data?.data?.network);
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
  };



  const totalData = walletList?.reduce(
    (start, current) => start + current.price,
    0
  );

  useEffect(() => {
    getIpAddress();
    getSettingDetails();
  }, []);
  const selectNetwork = (value) => {
    let name = "";
    switch (value) {
      case 137:
        name = "Polygon";
        break;
      case 1:
        name = "Ethereum";
        break;
      case 56:
        name = "BNB Smart Chain";
        break;

      default:
        return false;
    }
    return name;
  };

  const handleNetwork = (event) => {
    const { value } = event.target;
    let network = selectNetwork(value);
    setSelectedNetwork(network);
    setChainId(value);
  };

  const handleHash = (e) => {
    let hashRegex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;

    const data = hashRegex.test(e.target.value);

    if (data) {
      setTransactionError("Please Enter Valid Hash key");
      setHash(e.target.value);
    } else {
      setHash(e.target.value);
      setTransactionError("");
    }
  };

  const menuItems = (networkArr) => {
    return networkArr?.map((item) => {
      // let data = {
      //   network_type: item?.network_type,
      //   chain_id: item?.chain_id,
      // };
      return (
        <Index.MenuItem
          value={item?.chain_id}
          name={item?.network_type}
          className="menuitem"
        >
          {item?.network_type}
        </Index.MenuItem>
      );
    });
  };

  const validate = () => {
    if (chainId == "") {
      setNetworkError("Please select network");
    } else {
      setNetworkError("");
    }
    if (hash == "") {
      setTransactionError("Please Enter Hash key");
    } else {
      let hashRegex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
      const data = hashRegex.test(hash);
      if (data) {
        setTransactionError("Please Enter Valid Hash key");
      } else {
        setTransactionError("");
      }
    }
  };

  const handleDeposit = async (e) => {
    e.preventDefault();

    if (selectedNetwork && chainId && hash && settingDetails?.wallet_address) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("transactionHash", hash);
      urlencoded.append("network_address", settingDetails?.wallet_address);

      urlencoded.append("network_chain_id", chainId);
      urlencoded.append("network_type", selectedNetwork);
      urlencoded.append("ip_address", ipAddress);
      urlencoded.append("web_browser", browser);
      await DataService(userToken)
        .post(API.Exchange.DEPOSIT, urlencoded)
        .then((res) => {
          Index.toast.success(res?.data?.message);
          setChainId("");
          setHash("");
          setNetwork("");
        })
        .catch((error) => {
          console.log(error, ":error");
        });
    } else {
      validate();
    }
  };

  return (
    <div>
      <Index.Box className="admin-dashboard-box">
        <Index.Box className="excha-card chart-transiction-sec">
          <Index.Box className="right-top-main">
            <Index.Box className="profile-head-main">
              {/* <Index.Box className="profile-main">
                <img src={Index.Png.user1} className="" alt="user1" />
              </Index.Box> */}
              <Index.Typography className="dashboard-wallet-total" sx={{color:"green"}}>Total: {totalData ? totalData?.toFixed(2) : 0}</Index.Typography>
              <Index.Box className="notification-main">
                {/* <img
                  src={Index.Svg.notification}
                  className="noti-icon"
                  alt="notification"
                /> */}
              </Index.Box>
            </Index.Box>
            <Index.Box className="chart-sec">
              <PieChart />
            </Index.Box>
            {/* <Index.Box className="job-sec">
              <Index.Box className="job-item">
                <Index.Box className="cus-circle"></Index.Box>
                <Index.Typography
                  className="available-data"
                  variant="p"
                  component="p"
                >
                  jobs (2300)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-item">
                <Index.Box className="cus-circle cus-circle-orange"></Index.Box>
                <Index.Typography
                  className="available-data"
                  variant="p"
                  component="p"
                >
                  Practice for sale (230)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-item">
                <Index.Box className="cus-circle cus-circle-sky"></Index.Box>
                <Index.Typography
                  className="available-data"
                  variant="p"
                  component="p"
                >
                  Client for sale (2300)
                </Index.Typography>
              </Index.Box>
            </Index.Box> */}
          </Index.Box>
          <Index.Box className="transfer-sec">
            <Index.Box className="deposit-main-content">
              <Index.Box className="deposit-flex-content">
                <Index.Box className="deposit-content-qr-copy">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      component="h5"
                      variant="h5"
                      className="admin-sub-title"
                    >
                      Transaction
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="qr-code-box-img">
                    <QrCodeGenerate
                      qrCodeData={
                        Object.keys(settingDetails)?.length &&
                        settingDetails?.wallet_address
                      }
                    />
                    {/* <img
                      src={Index.Png.qrCode}
                      className="qr-code-img"
                      alt="qr-code"
                    /> */}
                  </Index.Box>
                  <Index.Box className="copy-hash-id-flex">
                    <Index.Box className="form-control-details-auth">
                      <Index.Box className="icon-position-rel">
                        <Index.TextField
                          className="form-control custom-auth-user-control "
                          value={settingDetails?.wallet_address}
                          disabled
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="connect-wallet-btn-main primary-btn-main copy-btn-new">
                      <Index.Button className="history-btn">
                        <img
                          src={Index.Svg.copyNew}
                          className="copyicon-img"
                          alt="copy-icon"
                        />
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="deposit-content-qr-content">
                  <Index.Box className="box-deposit-details-content input-box">
                    <Index.Box className="form-group-main deposit-form-content mb-25px-form">
                      <Index.FormHelperText className="form-lable">
                        Network
                      </Index.FormHelperText>
                      <Index.Box className="edit_profile_field">
                        <Index.Box className="form-group from_login_details">
                          <Index.Box className="dropdown-box">
                            <Index.FormControl className="form-control">
                              <Index.Select
                                className="dropdown-select "
                                value={chainId}
                                onChange={(e) => handleNetwork(e)}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                {menuItems(network)}
                              </Index.Select>
                            </Index.FormControl>
                            <Index.ErrorShow error={networkError} />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="form-group-main deposit-form-content mb-25px-form">
                      <Index.FormHelperText className="form-lable">
                        Hash Key
                      </Index.FormHelperText>
                      <Index.Box className="form-control-details-auth">
                        <Index.Box className="icon-position-rel">
                          <Index.TextField
                            className="form-control custom-auth-user-control"
                            value={hash}
                            onChange={(e) => handleHash(e)}
                          />
                        </Index.Box>
                        <Index.ErrorShow error={transactionError} />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="form-btn-verify-details">
                      <Index.Box className="betting-card-btn-comman">
                        {/* <Index.Button btnLabel="Verify" className="history-btn" /> */}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="btn-main-primary">
                      <Index.Button
                        className="btn-primary"
                        onClick={(e) => {
                          handleDeposit(e);
                        }}
                      >
                        Verify
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default Deposit;
