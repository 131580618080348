import {
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Menu,
  Modal,
  Grid,
  Collapse,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import copyTextToClipboard from "copy-text-to-clipboard";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, NavLink } from "react-router-dom";
import PaidLable from "./common/PaidLable";
import FailedLable from "./common/FailedLable";
import PendingLable from "./common/PendingLable";
import PersonIcon from "@mui/icons-material/Person";
import PrimaryButton from "./common/PrimaryButton";
import GreyButton from "./common/GreyButton";
import TextButton from "./common/TextButton";
import PrimaryBorderButton from "./common/PrimaryBorderButton";
import OutlineButton from "./common/OutlineButton";
import Dialog from "@mui/material/Dialog";
// import {
//   validateName,
//   validatePassword,
//   validateEmail,
//   validateConfirmPassword,
// } from "./common/AuthValidation";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers";
import SettingsIcon from "@mui/icons-material/Settings";
import PercentIcon from "@mui/icons-material/Percent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SendIcon from "@mui/icons-material/Send";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import {
  ReplaceCharsWithAsterisks,
  sortInAscending,
  sortInDescending,
  sortFieldInAscending,
  sortFieldInDescending,
  IdentifyAddressProof,
  validateInpField,
  checkPositive,
} from "./common/CommonFun";
// import { Export_excel } from "./common/ExportAll";
import { IOSSwitch } from "./common/IosSwitch";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { NetworkLink, selectNetwork } from "./common/NetworkLink";

// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ErrorShow from "./common/ErrorShow";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
// import MuiPhoneInput from "material-ui-phone-number";
import VerifiedIcon from "@mui/icons-material/Verified";
import CloseIcon from "@mui/icons-material/Close";

// Custom Tabs
import { CustomTabPanel, a11yProps } from "./common/CustomeTabPanel";
import BpCheckbox from "./common/BpCheckBox";
import Sidebar from "./DefaultLayout/Sidebar";

//Images
import Png from "../assets/Png";
import Svg from "../assets/Svg";
import Jpg from "../assets/Jpg";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DialogContent from "@mui/material/DialogContent";
import MobileHeader from "./DefaultLayout/Header/MobileHeader";
import LinearProgress from "@mui/material/LinearProgress";
export default {
  NetworkLink,
  TabContext,
  TabList,
  TabPanel,
  DialogContent,
  PercentIcon,
  SettingsIcon,
  MoreHorizIcon,
  sortInAscending,
  // MuiPhoneInput,
  toast,
  ToastContainer,
  VerifiedIcon,
  CloseIcon,
  useNavigate,
  useDispatch,
  useSelector,
  useLocation,
  sortInDescending,
  sortFieldInAscending,
  sortFieldInDescending,
  // AdapterDayjs,
  // LocalizationProvider,
  // DesktopDatePicker,
  // DatePicker,
  // copyTextToClipboard,
  ReplaceCharsWithAsterisks,
  Stack,
  DialogTitle,
  SendIcon,
  ExpandLess,
  ExpandMore,
  Collapse,
  Modal,
  DialogActions,
  MoreVertIcon,
  AddIcon,
  // DataGrid,
  Grid,
  Dialog,
  ExpandMoreIcon,
  AccordionDetails,
  Tooltip,
  AccordionSummary,
  Accordion,
  PersonIcon,
  // validateName,
  ContentCopyIcon,
  OutlineButton,
  // validatePassword,
  // validateEmail,
  // validateConfirmPassword,
  Box,
  FormHelperText,
  TextField,
  Typography,
  MailOutlineIcon,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  Link,
  NavLink,
  List,
  ListItem,
  // Video,
  // Sidebar,
  // Header,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  PaidLable,
  FailedLable,
  PendingLable,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Menu,
  PrimaryButton,
  GreyButton,
  TextButton,
  PrimaryBorderButton,
  Jpg,
  TablePagination,
  // Export_excel,
  moment,
  IOSSwitch,
  // CKEditor,
  // ClassicEditor,
  ErrorShow,
  selectNetwork,
  IdentifyAddressProof,
  validateInpField,

  CustomTabPanel,
  a11yProps, //Custom Tabs
  BpCheckbox, // checkbox
  Svg,
  Png,
  checkPositive,
  Sidebar,
  MobileHeader,
  LinearProgress,
};
