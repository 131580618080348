import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart = () => {
  const [chartApiData, setChartApiData] = useState([]);
  
  const token = useSelector((state) => state.user.userToken);
  const chartData = async () => {
    await DataService(token)
      .get(API.Exchange.GET_WALLET)
      .then((res) => {
        setChartApiData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    chartData();
  }, []);

  const percentages = chartApiData.map((item) => {
    const totalData = chartApiData.reduce(
      (start, current) => start + current.token_balance,
      0
    );
    const percentage = (item.token_balance / totalData) * 100;
   
    return percentage.toFixed(2);
  });

 

  const chartDataApi = {
    labels: chartApiData.map((item) => item?.token_name),

    datasets: [
      {
        data: percentages,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  console.log(chartDataApi, "chartDataApi");
  return <Pie data={chartDataApi} />;
};
