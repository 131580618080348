import React from "react";
import NewHeader from "../../../component/DefaultLayout/Header/NewHeader";
import Navigating_business from "../navigating_business/Navigating_business";
import CaseStudies from "../case_studies/CaseStudies";
import Services from "../services/Services";
import Footer from "../../../component/DefaultLayout/Footer/Footer";

const NewHomePage = () => {
  return (
    <>
      <NewHeader />
      <Navigating_business />
      <CaseStudies />
      <Services />
      <Footer />
    </>
  );
};

export default NewHomePage;
