// LIGHT MODE IMAGES IMPORT
import Vectorlogo from "../assets/images/Vectors.png";
import NCXlogo from "../assets/images/light/NCX.png";
import Lightning from "../assets/images/light/Lightning.png";
import Lightmod from "../assets/images/light/Lightmod.png";
import User from "../assets/images/light/User.png";
import Composition from "../assets/images/Composition.png";
import Google from "../assets/images/light/Google.png";
import Apple from "../assets/images/light/Apple.png";
import bluecheck from "../assets/images/light/check.png";
import AppStore from "../assets/images/AppStore.png";
import AndroidStore from "../assets/images/AndroidStore.png";
import SignupQr from "../assets/images/SignupQr.png";
import graph from "../assets/images/graph.png";
import redGraph from "../assets/images/redGraph.png";
import calendar from "../assets/images/light/calendar.png";
import order1 from "../assets/images/light/order-1.png";
import order3 from "../assets/images/light/order-3.png";
import Lock from "../assets/images/Lock.png";
import closeblack from "../assets/images/light/close-black.png";
import close from "../assets/images/png/close.png";
import ncxIcon from "../assets/images/light/ncx-logo.png";
import avdCash from "../assets/images/light/ADVCash.png";
import blackBankTransfer from "../assets/images/light/BlackBankTransfer.png";
import blackPerfectMoney from "../assets/images/light/BlackPerfectMoney.png";
import blackSEPA from "../assets/images/light/BlackSEPA.png";
import blackVisa from "../assets/images/light/BlackVisa.png";
import blackXOOCash from "../assets/images/light/BlackXOOCash.png";
import arrowleft from "../assets/images/light/left-arrow.png";
import line from "../assets/images/light/Line.png";
import sesflag from "../assets/images/light/flag.png";
import apikeyComposition from "../assets/images/light/apikeyComposition.png";
import greydown from "../assets/images/light/grey-down.png";
import payoutround from "../assets/images/light/payout-round.png";
import noti1 from "../assets/images/light/noti1.png";
import noti2 from "../assets/images/light/noti2.png";
import noti3 from "../assets/images/light/noti3.png";
import notical from "../assets/images/light/noti-cal.png";
import userprofile from "../assets/images/light/user-profile.png";
import userdark from "../assets/images/light/user-dark-mode.png";
import userlogout from "../assets/images/light/user-logout.png";
import usersetting from "../assets/images/light/user-setting.png";
import userkey from "../assets/images/light/user-key.png";
import profiledown from "../assets/images/light/profile-down-arrow.png";
import profilelock from "../assets/images/light/profile-lock.png";
import userReferral from "../assets/images/light/user-referral.png";
import profilesession from "../assets/images/light/profile-session.png";
import profileapi from "../assets/images/light/profileapi.png";
import arrowwhitedown from "../assets/images/light/arrow-white-down.png";
import loading from "../assets/images/light/loading.png";
import eye from "../assets/images/light/eye.png";
import rightgrey from "../assets/images/light/right-grey.png";
import vdots from "../assets/images/light/vertical-3-dot.png";
import profile from "../assets/images/light/profile-light.png";
import twoFA from "../assets/images/two-fa.png";
import depayround from "../assets/images/light/de-payround.png";
import descanimg from "../assets/images/light/de-scan-img.png";
import blackdot from "../assets/images/light/black-dot.png";
import greydot from "../assets/images/light/grey-dot.png";
import signdown from "../assets/images/light/sign-down.png";
import headerdown from "../assets/images/light/header-down.png";
import logoheader from "../assets/images/logo_new.png";
import bottomImage from "../assets/images/png/bgn-cover-bottom-5.png";
import bgCoverLayer from "../assets/images/png/bgn-cover-bottom-1.png";

// DARK MODE IMAGES IMPORT
import ncxIconDark from "../assets/images/dark/ncx-logo.png";
import blackBankTransferDark from "../assets/images/dark/BlackBankTransfer.png";
import blackPerfectMoneyDark from "../assets/images/dark/BlackPerfectMoney.png";
import blackSEPADark from "../assets/images/dark/BlackSEPA.png";
import blackVisaDark from "../assets/images/dark/BlackVisa.png";
import blackXOOCashDark from "../assets/images/dark/BlackXOOCash.png";
import avdCashDark from "../assets/images/dark/ADVCash.png";
import NCXlogoDark from "../assets/images/dark/NCX.png";
import userReferralDark from "../assets/images/dark/user-referral.png";
import profileapiDark from "../assets/images/dark/profileapi.png";
import profilesessionDark from "../assets/images/dark/user-referral.png";
import userkeyDark from "../assets/images/dark/user-referral.png";
import profilelockDark from "../assets/images/dark/user-referral.png";
import arrowleftDark from "../assets/images/dark/left-arrow.png";
import profileDark from "../assets/images/dark/profile.png";

// New Import
import logo_new from "../assets/images/png/logo_new.png";
import logo_new1 from "../assets/images/png/logo_new1.png";

import project_setting from "../assets/images/png/project-setting.png";
import misson from "../assets/images/png/misson.png";
import vision from "../assets/images/png/vision.png";
// import buy_sell from "../assets/images/png/buy-crypto.png";
import buySell from "../assets/images/png/buySell.png";
import buySell1 from "../assets/images/png/buySell1.png";

import simple from "../assets/images/png/simple.png";
import education from "../assets/images/png/education.png";
import service from "../assets/images/png/service.png";
import journey from "../assets/images/png/journey.png";
import journey1 from "../assets/images/png/journey1.png";
import dashHero from "../assets/images/png/dash-hero-crypto.png";
import fbcCoin from "../assets/images/png/FBC_Coin.png";
import fbclogo from "../assets/images/png/V1_Final_FBC_Network_Logo.png";
import bottomEnd from "../assets/images/png/img-curved-separator.png";
import logoAjani from "../assets/images/png/logo-ajani.png";
import advantageImg from "../assets/images/png/service-2.png";
import medicareImg from "../assets/images/png/logo-medicare-3.png";
import PrimaveraImg from "../assets/images/png/services-1.png";
import celesteImg from "../assets/images/png/service-3.png";
import applauzImg from "../assets/images/png/service-4.png";
import konkImg from "../assets/images/png/service-5.png";
import serviceBottomImg from "../assets/images/png/bgn-cover-top-3.png";
import buyCrypto from "../assets/images/png/buy-crypto-new.png";

// FBC Exchange
import fbcNetworkLogo from "../assets/images/png/fbc_network_logo.png";
import qrCode from "../assets/images/png/qr-code.png";
import user1 from "../assets/images/png/user1.png";
import chart from "../assets/images/png/chart.png";
import chartTrad from "../assets/images/png/chartTrad.png";
const Png = {
  //LIGHT IMAGES
  simple,
  journey,
  journey1,
  bottomImage,
  buyCrypto,
  serviceBottomImg,
  bgCoverLayer,
  advantageImg,
  medicareImg,
  PrimaveraImg,
  konkImg,
  celesteImg,
  applauzImg,
  logoAjani,
  buySell,
  buySell1,
  dashHero,
  bottomEnd,
  service,
  education,
  fbclogo,
  project_setting,
  fbcCoin,
  misson,
  vision,
  close,
  logoheader,
  twoFA,
  profile,
  ncxIcon,
  avdCash,
  blackBankTransfer,
  blackPerfectMoney,
  blackSEPA,
  blackVisa,
  blackXOOCash,
  Vectorlogo,
  NCXlogo,
  Lightning,
  Lightmod,
  User,
  Composition,
  Google,
  Apple,
  line,
  Lock,
  graph,
  order1,
  order3,
  closeblack,
  apikeyComposition,
  bluecheck,
  greydown,
  arrowleft,
  redGraph,
  payoutround,
  noti1,
  noti2,
  noti3,
  notical,
  userprofile,
  userdark,
  userkey,
  userlogout,
  usersetting,
  profiledown,
  profilelock,
  userReferral,
  profilesession,
  profileapi,
  arrowwhitedown,
  loading,
  eye,
  rightgrey,
  vdots,
  blackdot,
  greydot,
  calendar,
  signdown,

  profile,
  depayround,
  descanimg,
  headerdown,

  //DARK IMAGES
  profileDark,
  ncxIconDark,
  blackBankTransferDark,
  blackPerfectMoneyDark,
  blackSEPADark,
  blackVisaDark,
  blackXOOCashDark,
  avdCashDark,
  AppStore,
  AndroidStore,
  SignupQr,
  NCXlogoDark,
  sesflag,
  userReferralDark,
  profileapiDark,
  profilesessionDark,
  userkeyDark,
  profilelockDark,
  arrowleftDark,

  //   new import
  logo_new,
  logo_new1,

  // FBC Exchange
  fbcNetworkLogo,
  qrCode,
  user1,
  chart,
  chartTrad,
};
export default Png;
