import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Index from "../../../component/Index";

import { loginUser } from "../../../redux/features/slices/User/UserService";

export default function Login() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();

  let initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email")
      .matches(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,4})+$/,
        "Please enter valid email"
      )
      .required("Please enter an email"),
    password: Yup.string().required("Please enter a password"),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = async (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", values.email?.trim());
    urlencoded.append("password", values.password?.trim());

    dispatch(loginUser(urlencoded))
      .then((response) => {
        console.log(response, ":response");
        if (response?.payload?.status == 200) {
          Index.toast.success(response?.payload?.message);
          navigate("/v1/dashboard");
        }
      })
      .catch((error) => {
        console.loge(error, ":response");
      });
    Formik.resetForm();
  };

  return (
    <>
      <Index.Box className="center-div auth-wrapper">
        <form onSubmit={handleFormSubmit} className="auth-form">
          <Index.Box className="login-form-details">
            <Index.Box className="login-pd-main res-login-column ">
              <Index.Box className="login-max-content">
                <Index.Box className="login-details-title">
                  <Index.Typography
                    className="login-title"
                    component="h5"
                    variant="h5"
                  >
                    Login
                  </Index.Typography>
                  <Index.Typography
                    className="login-desc"
                    component="p"
                    variant="p"
                  >
                    Enter your email and password
                  </Index.Typography>
                </Index.Box>

                <Formik
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                        className="login-grid-inner"
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column login-column mb-20px"
                        >
                          <Index.Box className="input-box custom-form-details login-input">
                            <Index.Box className="form-group custom-group">
                              <Index.FormHelperText className="form-label">
                                Enter Email{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                name="email"
                                className="form-control custom-control"
                                onBlur={handleBlur}
                                value={values.email}
                                onChange={handleChange}
                                helperText={touched.email && errors.email}
                                error={Boolean(errors.email && touched.email)}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column login-column mb-20px"
                        >
                          <Index.Box className="input-box custom-form-details login-input">
                            <Index.Box className="form-group custom-group">
                              <Index.FormHelperText className="form-label">
                                Enter Password{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Index.FormHelperText>
                              <Index.Box className="input-details-main">
                                <Index.Box className="form-group pass_group_main">
                                  <Index.OutlinedInput
                                    className="form-control custom_form_control"
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    onBlur={handleBlur}
                                    value={values.password}
                                    onChange={handleChange}
                                    helperText={
                                      touched.password && errors.password
                                    }
                                    error={Boolean(
                                      errors.password && touched.password
                                    )}
                                    inputProps={{ maxlength: 16 }}
                                    endAdornment={
                                      <Index.InputAdornment
                                        position="end"
                                        className="pass_position"
                                      >
                                        <Index.IconButton
                                          className="icon_end_icon"
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showPassword ? (
                                            <Index.VisibilityOff />
                                          ) : (
                                            <Index.Visibility />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    }
                                    label="Password"
                                  />
                                  {touched.password && errors.password && (
                                    <Index.FormHelperText error>
                                      {errors.password}
                                    </Index.FormHelperText>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column login-column"
                        >
                          <Index.Box className="flex-check-login comman-flex-justify">
                            <Index.Box className="check-remeber-main">
                              <Index.Box className="checkbox-main">
                                <Index.BpCheckbox className="custom-check" />{" "}
                                <span>Remember Me</span>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="create-account-text">
                              <Index.Link
                                className="signup-redirect"
                                to="/forgot-password"
                              >
                                Forgot Password ?
                              </Index.Link>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column login-column"
                        >
                          <Index.Box className="">
                            <Index.Link
                              // to={"/user"}
                              className="signin-btn-main primary-btn-main-link-user"
                            >
                              <Index.Button
                                type="submit"
                                variant="contained"
                                className="comman-primary-btn "
                                onClick={handleSubmit}
                              >
                                {" "}
                                Sign in
                              </Index.Button>
                            </Index.Link>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Form>
                  )}
                </Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </form>
        {/* </Index.Box> */}
      </Index.Box>
      {/* </Index.Box> */}
      {/* </Index.Box> */}
      {/* </Index.Box> */}
    </>
  );
}
