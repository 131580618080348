import React from "react";
import Index from "../../../component/Index";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

function Listing() {
  const [listData, setListData] = useState([]);
  const token = useSelector((state) => state.user.userToken);
  const listingData = async () => {
    await DataService(token)
      .get(API.Exchange.GET_WALLET)
      .then((res) => {
        setListData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    listingData();
  }, []);
  return (
    <>
      <Index.Box className="listing-wrapper">
        <Index.Box className="exchange-dash-main">
          {/* <Index.Box className="mob-header">
            <Index.Box className="mob-header-left">
              <img
                src={Index.Svg.bergurmenu}
                className="bergurmenu-icon"
                alt="dashboard logo"
              ></img>
              <Index.Box className="mob-header-logo">
                <img
                  src={Index.Png.fbcNetworkLogo}
                  className="admin-sidebar-logo"
                  alt="logo"
                />
              </Index.Box>
            </Index.Box>
            <Index.Box className="mob-header-right">
              <Index.Box className="profile-main">
                <img src={Index.Png.user1} className="" alt="user1" />
              </Index.Box>
            </Index.Box>
          </Index.Box> */}

          <Index.Box className="overlay-section"></Index.Box>
          <Index.Box className="main-title-sec">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              Transaction List
            </Index.Typography>
          </Index.Box>
          <Index.Box className="agency-card-wrapper">
            {listData &&
              listData?.map((items, index) => {
                return (
                  <>
                    <Index.Box className="agency-card excha-card">
                      <Index.Box className="agency-wrapper">
                        <Index.Box className="agency-left">
                          <Index.Box className="agency-left-icon">
                            <img
                              src={
                                items?.image &&
                                `${process.env.REACT_APP_IMAGE_URL}/${items?.image}`
                              }
                              className="agency-ic"
                              alt="swap"
                            />
                          </Index.Box>
                          <Index.Box className="agency-left-content">
                            <Index.Typography
                              className="agency-title"
                              variant="h6"
                              component="h6"
                            >
                              {/* Accountant Windess */}
                              {items?.token_name}
                            </Index.Typography>
                            <Index.Box className="loca-sec">
                              <Index.Box className="loca-ic-sec">
                                {/* <img
                                  src={Index.Svg.location}
                                  className="location-ic"
                                  alt="swap"
                                /> */}
                              </Index.Box>
                              <Index.Box className="loca-add">
                                <Index.Typography
                                  className="available-data"
                                  variant="h6"
                                  component="h6"
                                >
                                  {items?.token_type}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>{" "}
                        <Index.Box className="agency-right">
                          <Index.Typography
                            className="asking-price"
                            variant="p"
                            component="p"
                          >
                            Price
                          </Index.Typography>
                          <Index.Typography
                            className="asking-price-value"
                            variant="h5"
                            component="h5"
                          >
                            ${items?.token_balance?.toFixed(2)}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </>
                );
              })}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Listing;
