import React, { useEffect, useState } from "react";
import Index from "../../../component/Index";
import redGraph from "../../../../src/assets/images/redGraph.png";
import "./ResponsiveCSS.css";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

const Exchange = () => {
  const navigate = useNavigate();
  const [getData, setGetData] = useState([]);
  const [gainerList, setGainerList] = useState([]);
  const [loserList, setLoserList] = useState([]);
  const [value, setValue] = React.useState("1");
console.log(gainerList,loserList,"loser")
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const token = useSelector((state) => state.user.userToken);

  const getTrendingCoinsData = async () => {
    await DataService(token)
      .get(API.Exchange.TRENDING_COINS)
      .then((res) => {
        setGetData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTopGainers = async () => {
    await DataService(token)
      .get(API.Exchange.GET_GAINER_LOSER_LIST)
      .then((res) => {
        setGainerList(res.data.data.gainerList);
        setLoserList(res.data.data.looserList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getTrendingCoinsData();
    getTopGainers();
  }, []);

  const formatCash = (n) => {
    if (n < 1e3) return n.toFixed(2);
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  return (
    <>
      <Index.Box className="exchange-main">
        {/* <Index.Box className="trending-coins-text">
          <Index.Typography className="trending-text">
            Trending Coins
          </Index.Typography>
        </Index.Box>
        <Index.Box className="trending-coins-card">
          <Index.Box className="trending-card-content">
            <Swiper spaceBetween={2} slidesPerView={3}>
              {getData.map((data, i) => (
                <>
                  <SwiperSlide>
                    <Index.Box className="tranding-coin-box">
                      <Index.Box
                        className="card-content-image"
                        onClick={() => {
                          navigate(`/exchange/${data?.symbol}`, {
                            state: {
                              categoryData: data,
                            },
                          });
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}/${data?.image}`}
                          alt="coin_image"
                          className="image-1"
                        />
                      </Index.Box>
                      <Index.Box className="trending-box-data">
                        <Index.Box className="upper-box">
                          <Index.Typography className="coin-name">
                            {data?.name}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Typography className="current-price">
                          $ {data?.USD?.price.toFixed(3)}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="trending-box-graph">
                        <img src={redGraph} alt="graph_image" />
                      </Index.Box>
                    </Index.Box>
                  </SwiperSlide>
                </>
              ))}
            </Swiper>
          </Index.Box>
        </Index.Box> */}

        <Index.Box className="main-tab-container">
          {/* <Index.TabContext value={value}> */}
          {/* <Index.Box className="tab-list">
              <Index.TabList
                aria-label="lab API tabs example"
                onChange={handleChange}
              >
                <Index.Tab label="Top Gainers" value="1" />
                <Index.Tab label="Top Loser" value="2" />
              </Index.TabList>
            </Index.Box> */}
          {/* <Index.TabPanel value="1"> */}
          {/* <Index.TabPanel > */}
          <Index.Box className="main-table-container">
            <Index.Box className="market-section-title">
              <Index.Typography className="market-section-heading section-heading exchange-section-heading">
                Top Gainers
              </Index.Typography>
            </Index.Box>
            <Index.TableContainer component={Index.Paper}>
              <Index.Table className="main-table" aria-label="simple table">
                <Index.TableHead>
                  <Index.TableRow className="table-header">
                    <Index.TableCell>#</Index.TableCell>
                    <Index.TableCell align="left">NAME</Index.TableCell>
                    <Index.TableCell align="center">PRICE</Index.TableCell>
                    <Index.TableCell align="center">GAIN</Index.TableCell>
                    <Index.TableCell align="center">24H %</Index.TableCell>
                    <Index.TableCell align="center">7d %</Index.TableCell>
                    <Index.TableCell align="center">MARKET CAP</Index.TableCell>
                    {/* <Index.TableCell align="center">CHART</Index.TableCell> */}
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {gainerList.map((gain, i) => (
                    <Index.TableRow key={gain.name} className="table-rows">
                      <Index.TableCell align="center">{i + 1}</Index.TableCell>
                      <Index.TableCell align="left">
                        <Index.Box
                          className="name-data"
                          onClick={() => {
                            const {name,token_price}=gain
               
                            navigate(`/market/${gain?.symbol}`, {
                              state: {
                                name,token_price
                              },
                            });
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}/${gain?.image}`}
                          />
                          <Index.Box className="name-text-data">
                            <Index.Typography className="name-text">
                              {`${gain?.name}`}
                            </Index.Typography>
                            <Index.Typography className="name-text-2">
                              {`${gain?.symbol}`}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.TableCell>
                      <Index.TableCell align="center">
                        ${gain?.USD?.price.toFixed(3)}
                      </Index.TableCell>
                      <Index.TableCell
                        align="center"
                        className={
                          gain?.USD?.percent_change_1h > 0
                            ? "change24-h-positive"
                            : "change24-h-negative"
                        }
                      >
                        {gain?.USD?.percent_change_1h.toFixed(2)}%
                      </Index.TableCell>
                      <Index.TableCell
                        align="center"
                        className={
                          gain?.USD?.percent_change_24h > 0
                            ? "change24-h-positive"
                            : "change24-h-negative"
                        }
                      >
                        {gain?.USD?.percent_change_24h.toFixed(2)}%
                      </Index.TableCell>
                      <Index.TableCell
                        align="center"
                        className={
                          gain?.USD?.percent_change_7d > 0
                            ? "change24-h-positive"
                            : "change24-h-negative"
                        }
                      >
                        {gain?.USD?.percent_change_7d.toFixed(2)}%
                      </Index.TableCell>
                      <Index.TableCell align="center" className="market-cap">
                        {formatCash(gain?.market_cap)}
                      </Index.TableCell>
                      {/* <Index.TableCell align="center">
                            <img src={redGraph} alt="graph_image" />
                          </Index.TableCell> */}
                    </Index.TableRow>
                  ))}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          {/* </Index.TabPanel> */}
          {/* <Index.TabPanel value="2"> */}
          <Index.Box className="main-table-container">
            <Index.Box className="market-section-title">
              <Index.Typography className="market-section-heading section-heading exchange-section-heading">
                Top Looser
              </Index.Typography>
            </Index.Box>
            <Index.TableContainer component={Index.Paper}>
              <Index.Table className="main-table" aria-label="simple table">
                <Index.TableHead>
                  <Index.TableRow className="table-header">
                    <Index.TableCell>#</Index.TableCell>
                    <Index.TableCell align="left">NAME</Index.TableCell>
                    <Index.TableCell align="center">PRICE</Index.TableCell>
                    <Index.TableCell align="center">LOSE</Index.TableCell>
                    <Index.TableCell align="center">24H %</Index.TableCell>
                    <Index.TableCell align="center">7d %</Index.TableCell>
                    <Index.TableCell align="center">MARKET CAP</Index.TableCell>
                    {/* <Index.TableCell align="center">CHART</Index.TableCell> */}
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {loserList.map((lose, i) => (
                    <Index.TableRow key={lose.name} className="table-rows">
                      <Index.TableCell align="center">{i + 1}</Index.TableCell>
                      <Index.TableCell align="left">
                        <Index.Box
                          className="name-data"
                          onClick={() => {
                            const {name,token_price}=lose
                            console.log(name,token_price,"hello")
                            navigate(`/market/${lose?.symbol}`, {
                              state: {
                                name,token_price
                              },
                            });
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}/${lose?.image}`}
                          />
                          <Index.Box className="name-text-data">
                            <Index.Typography className="name-text">
                              {`${lose?.name}`}
                            </Index.Typography>
                            <Index.Typography className="name-text-2">
                              {`${lose?.symbol}`}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.TableCell>
                      <Index.TableCell align="center">
                        ${lose.USD.price.toFixed(3)}
                      </Index.TableCell>
                      <Index.TableCell
                        align="center"
                        className={
                          lose?.USD?.percent_change_1h > 0
                            ? "change24-h-positive"
                            : "change24-h-negative"
                        }
                      >
                        {" "}
                        {lose?.USD?.percent_change_1h.toFixed(2)}%
                      </Index.TableCell>
                      <Index.TableCell
                        align="center"
                        className={
                          lose?.USD?.percent_change_24h > 0
                            ? "change24-h-positive"
                            : "change24-h-negative"
                        }
                      >
                        {lose?.USD?.percent_change_24h.toFixed(2)}%
                      </Index.TableCell>
                      <Index.TableCell
                        align="center"
                        className={
                          lose?.USD?.percent_change_7d > 0
                            ? "change24-h-positive"
                            : "change24-h-negative"
                        }
                      >
                        {lose?.USD?.percent_change_7d.toFixed(2)}%
                      </Index.TableCell>
                      <Index.TableCell
                        align="center"
                        className="market-cap-loser"
                      >
                        {formatCash(lose?.market_cap)}
                      </Index.TableCell>
                      {/* <Index.TableCell align="center">
                            <img src={redGraph} alt="graph_image" />
                          </Index.TableCell> */}
                    </Index.TableRow>
                  ))}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          {/* </Index.TabPanel> */}
          {/* </Index.TabContext> */}
        </Index.Box>

        {/* listing */}
      </Index.Box>
    </>
  );
};

export default Exchange;
