import React, { useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import png from "../../../assets/Png";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import {
  Container,
  Link,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  Avatar,
} from "@mui/material";

import HeaderStyle from "./HeaderStyle";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Svg from "../../../assets/Svg";
import Index from "../../Index";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../redux/features/slices/User/UserSlice";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Height } from "@mui/icons-material";
import { useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      // backgroundColor: "Neutrals8",
      backgroundColor: "#141416",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};
const names = ["Exchange", "Market", "Earn"];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
// }));

const Header = (props) => {
  const userToken = useSelector((store) => store.user.userToken);
  const userData = useSelector((store) => store.user.userData);

  const [scroll, setscroll] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (popupState) => {
    console.log("sdffsdsdfsdf");
    // Your logic here
    popupState.close();
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setscroll(window.scrollY > 50);
    });
  }, []);

  console.log(userData, ":userData");
  // let theamCondition = TheamAndDisplayCondition();

  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const Theme = useTheme();
  // const matches = useMediaQuery(Theme.breakpoints.up("sm"));
  // let themeMode = Theme?.palette?.mode === "light" ? true : false;
  let themeMode = false;
  // const [close, setClose] = React.useState(true);
  // DRAWER STATE
  const [state, setState] = React.useState({
    left: false,
  });

  const pages = [
    {
      menuName: "Exchange",
      menuLink: "/exchange",
    },

    {
      menuName: "Market",
      menuLink: "/market",
    },
    {
      menuName: "About Program",
      menuLink: "#",
    },
    {
      menuName: "Download APP",
      menuLink: "#",
    },
    // " Buy Crypto",
    // "Exchange",
    // "Market",
    // "Earn",
    // "NCX Card",
    // "NCX Coin",
  ];

  // toggleDrawer
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ left: open });
  };

  //LIST FOR MOBILE DREWER
  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        backgroundColor: "#141416",
        // color: "Neutrals1",
        color: "#FCFCFD",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="txt-line mobile-sidebar">
        {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => ( */}
        {pages.map((item, index) => (
          <ListItem
            component={RouterLink}
            sx={{ backgroundColor: "#141416", color: "#FCFCFD" }}
            key={index}
            className="sidebar-li"
          >
            {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
            <Link
              to={item?.menuLink}
              component={RouterLink}
              className="text-decoration-none sidebar-a"
            >
              <ListItemText
                primary={item?.menuName}
                menuLink={item?.menuLink}
              />
            </Link>
          </ListItem>
        ))}
        {/* <ListItem button>
          <ListItemText primary="EN/USD" />
        </ListItem> */}
        {/* <ListItem button>
          <img src={themeMode ? Svg.light : Svg.lightDark}></img>
        </ListItem> */}
        {/* <ListItem button>
          <img
            src={themeMode ? Svg.themeMode : Svg.themeModeDark}
            onClick={props.toggleColorMode}
          ></img>
        </ListItem> */}
        {userToken ? (
          <Link
            to={"/wallet"}
            component={RouterLink}
            className="text-decoration-none sidebar-buttons"
          >
            {" "}
            <ListItem button>
              <Stack>
                <Button
                  sx={{ backgroundColor: "#3772FF", color: "#FCFCFD" }}
                  className="button-gradient"

                  // variant="outlined"
                >
                  Wallet
                </Button>
              </Stack>
            </ListItem>{" "}
          </Link>
        ) : (
          <>
            <Link
              to={"/new/login"}
              component={RouterLink}
              className="text-decoration-none sidebar-buttons"
            >
              {" "}
              <ListItem button>
                <Stack>
                  <Button
                    sx={{ backgroundColor: "#3772FF", color: "#FCFCFD" }}
                    className="button-gradient"
                    // variant="outlined"
                  >
                    Login
                  </Button>
                </Stack>
              </ListItem>{" "}
            </Link>

            <Link
              to={"/signup"}
              component={RouterLink}
              className="text-decoration-none sidebar-buttons"
            >
              {" "}
              <ListItem button>
                <Stack>
                  <Button
                    sx={{ backgroundColor: "#3772FF", color: "#FCFCFD" }}
                    className="button-gradient"
                    // variant="outlined"
                  >
                    Register
                  </Button>
                </Stack>
              </ListItem>{" "}
            </Link>
          </>
        )}
      </List>
    </Box>
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [selectedTab, setSelectedTab] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleTabChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTab(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleLogout = () => {
    navigate("/");
    Index.toast.success("User logout Successfully");
    dispatch(userLogout());
  };

  return (
    <>
      <Box className={scroll ? "wrap-head-scroll" : "wrap-head"}>
        <Container
          className="cus-container-dash"
          sx={[HeaderStyle.mainContainer]}
        >
          <Toolbar sx={HeaderStyle.appbar} className="main-head-flx">
            <Box className="head-logo">
              <Link to={"/"}>
                <Box
                  className="logo header-logo-box"
                  sx={HeaderStyle.logo}
                  onClick={() => navigate("/")}
                >
                  {/* <Button color="inherit"> */}
                  <img style={HeaderStyle.ncxLogo} src={Index.Png.fbclogo} />
                  {/* </Button> */}
                </Box>
              </Link>
            </Box>

            {/* TABLATE SCREEN DROPDWON */}

            <FormControl style={HeaderStyle.Dropdown}>
              <Select
                className="language-select"
                sx={{
                  marginLeft: "32px",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "inline-block",
                    lg: "none",
                  },
                }}
                // multiple
                displayEmpty
                value={selectedTab}
                onChange={handleTabChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return "Exchange";
                  }
                  return selected.join(", ");
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* for desktop */}
            <Box className="main-head-right">
              <Box className="head-links-main">
                <Box sx={HeaderStyle.headerContent} className="per-links">
                  <Link
                    to={"/exchange"}
                    component={RouterLink}
                    sx={HeaderStyle.Menu}
                  >
                    Exchange
                  </Link>

                  <Link
                    to={"/market"}
                    component={RouterLink}
                    sx={HeaderStyle.Menu}
                  >
                    Market
                  </Link>

                  <Link to={"#"} component={RouterLink} sx={HeaderStyle.Menu}>
                    About Program
                  </Link>

                  {/* <Link to='#' component={RouterLink} sx={HeaderStyle.Menu}>
              About
            </Link>
            <Link to='#' component={RouterLink} sx={HeaderStyle.Menu}>
            Blog/News
            </Link>
            <Link to='#' component={RouterLink} sx={HeaderStyle.Menu}>
            Security
            </Link> */}
                </Box>
              </Box>
              {userToken ? (
                <Box className="head-btns">
                  <Box className="user-icons-header">
                    <FormControl style={HeaderStyle.Dropdown}></FormControl>
                    <Stack>
                      <Link
                        to={"/wallet"}
                        component={RouterLink}
                        className="text-decoration-none"
                      >
                        {" "}
                        <Button
                          className="header-wallet-btn"
                          sx={HeaderStyle.walletButton}
                        >
                          Wallet
                        </Button>
                      </Link>
                    </Stack>
                    <a style={HeaderStyle.Menu2} id="user-profile-icon">
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-popover"
                      >
                        {(popupState) => (
                          <>
                            <div>
                              {/* <Button variant="contained" {...bindTrigger(popupState)}>
                      Open Popover
                    </Button> */}
                              {/* <Badge variant="dot" color="primary" {...bindTrigger(popupState)}> */}
                              <img
                                src={themeMode ? Svg.user : Svg.userDark}
                                {...bindTrigger(popupState)}
                              ></img>{" "}
                              {/* </Badge> */}
                              <Popover
                                className="header-noti-popup header-profile-popup"
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                // sx={{ padding: "8px" }}
                              >
                                {/* <Typography>Notifications</Typography> */}
                                <Box className="header-profile-main">
                                  <Link
                                    to={"/profile"}
                                    className="text-decoration-none"
                                    component={RouterLink}
                                    onClick={() => handleClick(popupState)}
                                  >
                                    {" "}
                                    <Box className="header-profile-content">
                                      <Box className="header-profile-box">
                                        <Box className="header-profile-title-box">
                                          <Box className="user-img-box">
                                            <img
                                              src={png.userprofile}
                                              className="user-profile-img"
                                            ></img>
                                          </Box>
                                          <Box className="header-proile-list">
                                            {" "}
                                            <Box className="header-profile-inner-title">
                                              Profile
                                            </Box>
                                            <Box className="header-profile-time">
                                              Important account details
                                            </Box>
                                          </Box>{" "}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Link>
                                  <Link
                                    to={"/change-password"}
                                    className="text-decoration-none"
                                    component={RouterLink}
                                  >
                                    <Box className="header-profile-content">
                                      <Box className="header-profile-box">
                                        <Box className="header-profile-title-box">
                                          <Box className="user-img-box">
                                            <img
                                              src={png.userReferral}
                                              className="user-profile-img"
                                            ></img>
                                          </Box>
                                          <Box className="header-proile-list">
                                            <Box className="header-profile-inner-title">
                                              Change Password
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>{" "}
                                  </Link>
                                  <Link
                                    to={"/kyc"}
                                    className="text-decoration-none"
                                    component={RouterLink}
                                    onClick={() => handleClick(popupState)}
                                  >
                                    <Box className="header-profile-content">
                                      <Box className="header-profile-box">
                                        <Box className="header-profile-title-box">
                                          <Box className="user-img-box">
                                            <img
                                              src={png.usersetting}
                                              className="user-profile-img"
                                            ></img>
                                          </Box>
                                          <Box className="header-proile-list">
                                            <Box className="header-profile-inner-title">
                                              Kyc
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>{" "}
                                  </Link>

                                  <Link
                                    to={"/history"}
                                    className="text-decoration-none"
                                    component={RouterLink}
                                    onClick={() => handleClick(popupState)}
                                  >
                                    <Box className="header-profile-content">
                                      <Box className="header-profile-box">
                                        <Box className="header-profile-title-box">
                                          <Box className="user-img-box">
                                            <img
                                              src={png.usersetting}
                                              className="user-profile-img"
                                            ></img>
                                          </Box>
                                          <Box className="header-proile-list">
                                            <Box className="header-profile-inner-title">
                                              History
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>{" "}
                                  </Link>
                                  {/* <Link
                          to={"/referral"}
                          className="text-decoration-none"
                          component={RouterLink}
                        >
                          <Box className="header-profile-content">
                            <Box className="header-profile-box">
                              <Box className="header-profile-title-box">
                                <Box className="user-img-box">
                                  <img
                                    src={png.userReferral}
                                    className="user-profile-img"
                                  ></img>
                                </Box>
                                <Box className="header-proile-list">
                                  <Box className="header-profile-inner-title">
                                    Referrals
                                  </Box>
                                  <Box className="header-profile-time">
                                    Invite your friends and earn rewards
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>{" "}
                        </Link>{" "}
                        <Link
                          to={"/two-fa"}
                          className="text-decoration-none"
                          component={RouterLink}
                        >
                          <Box className="header-profile-content">
                            <Box className="header-profile-box">
                              <Box className="header-profile-title-box">
                                <Box className="user-img-box">
                                  <img
                                    src={png.userkey}
                                    className="user-profile-img"
                                  ></img>
                                </Box>
                                <Box className="header-proile-list">
                                  <Box className="header-profile-inner-title">
                                    2FA security
                                  </Box>
                                  <Box className="header-profile-time">
                                    Setup 2FA for more security
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>{" "}
                        </Link>
                        <Box className="header-profile-content">
                          <Box className="header-profile-box">
                            <Box className="header-profile-title-box">
                              <Box className="user-img-box">
                                <img
                                  src={png.usersetting}
                                  className="user-profile-img"
                                ></img>
                              </Box>
                              <Box className="header-proile-list">
                                <Box className="header-profile-inner-title">
                                  Settings
                                </Box>
                                <Box className="header-profile-time">
                                  View additional settings
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box> */}

                                  <Box className="header-profile-content">
                                    <Box className="header-profile-box">
                                      <Box className="header-profile-title-box">
                                        <Box className="user-img-box">
                                          <img
                                            src={png.userlogout}
                                            className="user-profile-img"
                                          ></img>
                                        </Box>
                                        <Box
                                          className="header-proile-list"
                                          onClick={() => handleLogout()}
                                        >
                                          <Box className="header-profile-inner-title">
                                            Log out
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Popover>
                            </div>
                          </>
                        )}
                      </PopupState>
                    </a>
                    <span style={{ color: "#fff" }}>
                      {userData?.user_name} ({userData?.uid})
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      {userData?.is_verified && (
                        <VerifiedIcon color="primary" fontSize="14px" />
                      )}
                    </span>
                  </Box>
                </Box>
              ) : (
                <Box sx={HeaderStyle.headerContent2}>
                  {/* <Stack>
                    <Link
                      to={"/login"}
                      component={RouterLink}
                      className="text-decoration-none"
                    >
                      {" "}
                      <Button
                        className="header-wallet-btn"
                        sx={HeaderStyle.walletButton}
                      >
                        Login
                      </Button>
                    </Link>
                  </Stack>
                  <Stack>
                    <Link
                      to={"/singup"}
                      component={RouterLink}
                      className="text-decoration-none"
                    >
                      {" "}
                      <Button
                        className="header-wallet-btn"
                        sx={HeaderStyle.walletButton}
                      >
                        Register
                      </Button>
                    </Link>
                  </Stack> */}
                  <IconButton
                    onClick={handleMenuClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>F</Avatar>
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleClose}>
                      {" "}
                      <Link
                        to={"/new/login"}
                        component={RouterLink}
                        className="text-decoration-none"
                      >
                        {" "}
                        <Button sx={HeaderStyle.walletButton}>Login</Button>
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <Link
                        to={"/new/singup"}
                        component={RouterLink}
                        className="text-decoration-none"
                      >
                        {" "}
                        <Button sx={HeaderStyle.walletButton}>Register</Button>
                      </Link>
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Box>
          </Toolbar>

          <Box sx={HeaderStyle.menuicon} className="res-head-right">
            {/* <a href="#" style={HeaderStyle.icon}>
              <Badge variant="dot" color="primary">
                <img src={themeMode ? Svg.ball : Svg.ballDark}></img>
              </Badge>
            </a> */}
            {userToken && (
              <>
                <a style={HeaderStyle.Menu2} id="user-profile-icon">
                  <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                      <>
                        <div>
                          {/* <Button variant="contained" {...bindTrigger(popupState)}>
                      Open Popover
                    </Button> */}
                          {/* <Badge variant="dot" color="primary" {...bindTrigger(popupState)}> */}
                          <img
                            src={themeMode ? Svg.user : Svg.userDark}
                            {...bindTrigger(popupState)}
                          ></img>{" "}
                          {/* </Badge> */}
                          <Popover
                            className="header-noti-popup header-profile-popup"
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            // sx={{ padding: "8px" }}
                          >
                            {/* <Typography>Notifications</Typography> */}
                            <Box className="header-profile-main">
                              <Link
                                to={"/profile"}
                                className="text-decoration-none"
                                component={RouterLink}
                                onClick={() => handleClick(popupState)}
                              >
                                {" "}
                                <Box className="header-profile-content">
                                  <Box className="header-profile-box">
                                    <Box className="header-profile-title-box">
                                      <Box className="user-img-box">
                                        <img
                                          src={png.userprofile}
                                          className="user-profile-img"
                                        ></img>
                                      </Box>
                                      <Box className="header-proile-list">
                                        {" "}
                                        <Box className="header-profile-inner-title">
                                          Profile
                                        </Box>
                                        <Box className="header-profile-time">
                                          Important account details
                                        </Box>
                                      </Box>{" "}
                                    </Box>
                                  </Box>
                                </Box>
                              </Link>
                              <Link
                                to={"/change-password"}
                                className="text-decoration-none"
                                component={RouterLink}
                              >
                                <Box className="header-profile-content">
                                  <Box className="header-profile-box">
                                    <Box className="header-profile-title-box">
                                      <Box className="user-img-box">
                                        <img
                                          src={png.userReferral}
                                          className="user-profile-img"
                                        ></img>
                                      </Box>
                                      <Box className="header-proile-list">
                                        <Box className="header-profile-inner-title">
                                          Change Password
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>{" "}
                              </Link>
                              <Link
                                to={"/kyc"}
                                className="text-decoration-none"
                                component={RouterLink}
                                onClick={() => handleClick(popupState)}
                              >
                                <Box className="header-profile-content">
                                  <Box className="header-profile-box">
                                    <Box className="header-profile-title-box">
                                      <Box className="user-img-box">
                                        <img
                                          src={png.usersetting}
                                          className="user-profile-img"
                                        ></img>
                                      </Box>
                                      <Box className="header-proile-list">
                                        <Box className="header-profile-inner-title">
                                          Kyc
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>{" "}
                              </Link>

                              <Link
                                to={"/history"}
                                className="text-decoration-none"
                                component={RouterLink}
                                onClick={() => handleClick(popupState)}
                              >
                                <Box className="header-profile-content">
                                  <Box className="header-profile-box">
                                    <Box className="header-profile-title-box">
                                      <Box className="user-img-box">
                                        <img
                                          src={png.usersetting}
                                          className="user-profile-img"
                                        ></img>
                                      </Box>
                                      <Box className="header-proile-list">
                                        <Box className="header-profile-inner-title">
                                          History
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>{" "}
                              </Link>
                              {/* <Link
                          to={"/referral"}
                          className="text-decoration-none"
                          component={RouterLink}
                        >
                          <Box className="header-profile-content">
                            <Box className="header-profile-box">
                              <Box className="header-profile-title-box">
                                <Box className="user-img-box">
                                  <img
                                    src={png.userReferral}
                                    className="user-profile-img"
                                  ></img>
                                </Box>
                                <Box className="header-proile-list">
                                  <Box className="header-profile-inner-title">
                                    Referrals
                                  </Box>
                                  <Box className="header-profile-time">
                                    Invite your friends and earn rewards
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>{" "}
                        </Link>{" "}
                        <Link
                          to={"/two-fa"}
                          className="text-decoration-none"
                          component={RouterLink}
                        >
                          <Box className="header-profile-content">
                            <Box className="header-profile-box">
                              <Box className="header-profile-title-box">
                                <Box className="user-img-box">
                                  <img
                                    src={png.userkey}
                                    className="user-profile-img"
                                  ></img>
                                </Box>
                                <Box className="header-proile-list">
                                  <Box className="header-profile-inner-title">
                                    2FA security
                                  </Box>
                                  <Box className="header-profile-time">
                                    Setup 2FA for more security
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>{" "}
                        </Link>
                        <Box className="header-profile-content">
                          <Box className="header-profile-box">
                            <Box className="header-profile-title-box">
                              <Box className="user-img-box">
                                <img
                                  src={png.usersetting}
                                  className="user-profile-img"
                                ></img>
                              </Box>
                              <Box className="header-proile-list">
                                <Box className="header-profile-inner-title">
                                  Settings
                                </Box>
                                <Box className="header-profile-time">
                                  View additional settings
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box> */}

                              <Box className="header-profile-content">
                                <Box className="header-profile-box">
                                  <Box className="header-profile-title-box">
                                    <Box className="user-img-box">
                                      <img
                                        src={png.userlogout}
                                        className="user-profile-img"
                                      ></img>
                                    </Box>
                                    <Box
                                      className="header-proile-list"
                                      onClick={() => handleLogout()}
                                    >
                                      <Box className="header-profile-inner-title">
                                        Log out
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Popover>
                        </div>
                      </>
                    )}
                  </PopupState>
                </a>
                <span style={{ color: "#fff" }}>{userData?.user_name}</span>
                <span style={{ marginLeft: "5px" }}>
                  {userData?.is_verified && (
                    <VerifiedIcon color="primary" fontSize="14px" />
                  )}
                </span>
              </>
            )}

            <IconButton
              // size="large"
              // aria-label="show more"
              // aria-controls={mobileMenuId}
              // aria-haspopup="true"
              onClick={(e) => {
                toggleDrawer("left", true)(e);
              }}
            >
              {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}

              <img src={themeMode ? Svg.menuIcon : Svg.menuIconDark} />
            </IconButton>
            <Drawer
              sx={{
                "& .css-1oj3801-MuiPaper-root-MuiDrawer-paper": {
                  backgroundColor: "Neutrals8",
                  backgroundImage: "none",
                },
              }}
              anchor={"left"}
              open={state?.left}
              onClose={(e) => {
                toggleDrawer("left", false)(e);
              }}
            >
              {list("left")}
            </Drawer>
          </Box>
          {/* {renderMobileMenu} */}
        </Container>
      </Box>
    </>
  );
};

export default Header;
