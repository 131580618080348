import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";

export const registerUserData = createAsyncThunk(
  "users/registerUser",
  async (userData) => {
    try {
      const response = await DataService().post(
        API.User.REGISTER_USER,
        userData
      );
      if (
        response.data.message == "User already exists with this email address"
      ) {
        toast.error(response.data.message);
      } else {
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  // async (userData) => {
  //   const response = await DataService.post(API.User.REGISTER_USER, userData);
  //   return response;
  // }
);

export const loginUser = createAsyncThunk(
  "users/loginUser",

  async (userData) => {
    try {
      const response = await DataService().post(API.User.USER_LOGIN, userData);
      // toast.success(response.data.message);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const ForgotPasswordAPI = createAsyncThunk(
  "users/forgotPassword",

  async (userData) => {
    try {
      const response = await DataService.post(
        API.User.FORGOT_PASSWORD,
        userData
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const ResetPasswordAPI = createAsyncThunk(
  "users/resetPassword",

  async (userData) => {
    try {
      const response = await DataService.post(
        API.User.RESET_PASSWORD,
        userData
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const ResetPasswordResendOTPAPI = createAsyncThunk(
  "users/resetPasswordResendOTP",

  async (userData) => {
    try {
      const response = await DataService.post(
        API.User.RESEND_OTP_RESET_PASSWORD,
        userData
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getRoadMapList = createAsyncThunk(
  "users/getRoadMapList",

  async () => {
    const response = await DataService.post(API.Admin.ROADMAP_LIST);
    return response.data;
  }
);

export const getCountryList = createAsyncThunk(
  "users/getCountryList",

  async () => {
    const response = await DataService.get(API.User.COUNTRY_LIST);
    return response.data;
  }
);

export const getMyReferredUser = createAsyncThunk(
  "users/getMyReferredUser",

  async (data) => {
    const response = await DataService.post(API.User.MY_REFERRED_USERS);
    return response.data;
  }
);

export const verifyEmail = createAsyncThunk(
  "users/verifyEmail",

  async (data) => {
    try {
      const response = await DataService.post(
        API.User.EMAIL_VERIFICATION,
        data
      );
      // if(response?.payload?.data?.accessToken){
      //   toast.success("Email verified successfully");
      // }
      // else{
      //   toast.error(response.data.message);
      // }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "users/getUserProfile",
  async () => {
    try {
      const response = await DataService.get(API.User.GET_USER_PROFILE);
      return response.data;
    } catch (error) {}
  }
);

export const getUserWalletHistory = createAsyncThunk(
  "users/getUserWalletHistory",
  async () => {
    try {
      const response = await DataService.get(API.User.WALLET_HISTORY);
      return response.data;
    } catch (error) {}
  }
);

export const updateUserProfile = createAsyncThunk(
  "users/updateUserProfile",

  async (data) => {
    const response = await DataService.post(
      API.User.UPDATE_USER_PROFILE,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }
);
export const userEmailVerify = createAsyncThunk(
  "users/userEmailVerify",

  async (data) => {
    try {
      const response = await DataService.post(API.User.EMAIL_VERIFY, data);
      return response.data;
    } catch (error) {
      // toast.error(error?.response?.data?.message)
    }
  }
);
export const userResendOtp = createAsyncThunk(
  "users/userResendOtp",

  async (data) => {
    try {
      const response = await DataService.post(API.User.RESEND_OTP, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getUserProfileData = createAsyncThunk(
  "users/getUserProfileData",

  async () => {
    try {
      const response = await DataService.get(API.User.GET_USER_PROFILE);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const userSocialLogin = createAsyncThunk(
  "users/userSocialLogin",

  async (data) => {
    try {
      const response = await DataService.post(API.User.SOCIAL_LOGIN, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const userContactUs = createAsyncThunk(
  "users/userContactUs",

  async (data) => {
    try {
      const response = await DataService.post(API.User.CONTACT_US, data.values);
      // toast.success(response?.data?.message)
      return response.data;
    } catch (error) {
      // toast.error(error?.response?.data?.message)
    }
  }
);

export const userForgetPassword = createAsyncThunk(
  "users/userForgetPassword",

  async (data) => {
    try {
      const response = await DataService.post(API.User.FORGOT_PASSWORD, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const checkUserNameExist = createAsyncThunk(
  "users/checkUserNameExist",

  async (data) => {
    try {
      const response = await DataService.post(
        API.User.CHECK_USERNAME_EXIST,
        data
      );
      return response.data;
    } catch (error) {}
  }
);

export const getWalletDataList = createAsyncThunk("users/getWalletDataList", async (data) => {
  const response = await DataService(data).get(API.Exchange.GET_WALLET);
  console.log(response, "response279")
  return response.data;
});
