import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Index from "../../../component/Index";
import { API } from "../../../config/Api";
import { DataService } from "../../../config/DataService";
import Chatboat from "../../../component/common/chatboat";

export default function ForgotPassword() {
  const navigate = Index.useNavigate();

  let initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email")
      .matches(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,4})+$/,
        "Please enter valid email"
      )
      .required("Please enter an email"),
  });

  const handleFormSubmit = async (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", values.email?.trim());
    await DataService()
      .post(API.User.USER_FORGOT_PASSWORD, urlencoded)
      .then((res) => {
        if (res?.data?.status == 200) {
          Index.toast.success(res?.data?.message);
          setTimeout(() => {
            navigate("/otp", {
              state: {
                email: res?.data?.data?.email,
              },
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status == 401) {
          Index.toast.error(error?.response?.data?.message);
        } else {
          Index.toast.error("User not exist");
        }
      });
  };

  return (
    <>
      <Index.Box className="center-div auth-wrapper">
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form className="auth-form" onSubmit={handleSubmit}>
              <Index.Box className="login-form-details">
                <Index.Box className="login-pd-main res-login-column ">
                  <Index.Box className="login-max-content">
                    <Index.Box className="login-details-title">
                      <Index.Typography
                        className="login-title forgot-title"
                        component="h5"
                        variant="h5"
                      >
                        Forgot Password!
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                      className="login-grid-inner"
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column login-column mb-20px"
                      >
                        <Index.Box className="input-box custom-form-details login-input">
                          <Index.Box className="form-group custom-group">
                            <Index.FormHelperText className="form-label">
                              Enter Email{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Index.FormHelperText>
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              name="email"
                              className="form-control custom-control"
                              onBlur={handleBlur}
                              value={values.email}
                              onChange={handleChange}
                              helperText={touched.email && errors.email}
                              error={Boolean(errors.email && touched.email)}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column login-column"
                      >
                        <Index.Box className="">
                          <Index.Link className="signin-btn-main primary-btn-main-link-user">
                            <Index.Button
                              type="submit"
                              variant="contained"
                              className="comman-primary-btn "
                              onClick={handleSubmit}
                            >
                              Submit
                            </Index.Button>
                          </Index.Link>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Form>
          )}
        </Formik>
        <Chatboat />
      </Index.Box>
    </>
  );
}
