import React from 'react'
import Index from "../../../component/Index";
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

// for custom progressbar design

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? 'red' : 'red',
    },
}));

const tabcolumns = [
    { field: 'pair', headerName: 'Pair', width: 100 },
    { field: 'price', headerName: 'Price', width: 100 },

    {
        field: 'change',
        headerName: 'Change',
        description: '',
        sortable: false,
        width: 80
        // valueGetter: (params) =>
        //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    }
];

const tabrows = [
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    { id: 1, pair: 'BTTC//USDT', price: '0.00000039', change: '+0.00%' },
    // { id: 2, pair: 'Snow', price: 'Jon', change: 'abc' },
    // { id: 3, pair: 'Snow', price: 'Jon', change: 'abc' },
    // { id: 4, pair: 'Snow', price: 'Jon', change: 'abc' },
    // { id: 5, pair: 'Snow', price: 'Jon', change: 'abc' },
];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Index.Box>
                    <Index.Typography>{children}</Index.Typography>
                </Index.Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',

];

const ITEM_HEIGHT = 48;

function createData(price, amount, total) {
    return { price, amount, total };
}

const rows = [
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
    createData(25814.7, 0.030048, 12.3917),
];

function SpotTrade() {
    const [age, setAge] = React.useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [tabvalue, settabValue] = React.useState(0);

    const tabHandleChange = (event, newValue) => {
        settabValue(newValue);
    };

    // spot tab
    const [spotvalue, setSpotValue] = React.useState(0);

    const spothandleChange = (event, newspotValue) => {
        setSpotValue(newspotValue);
    };

    // spot inner tab
    const [limitvalue, setLimitValue] = React.useState(0);

    const limithandleChange = (event, newlimitValue) => {
        setLimitValue(newlimitValue);
    };

    // grid inner tab
    const [gridvalue, setGridValue] = React.useState(0);

    const gridhandleChange = (event, newgridValue) => {
        setGridValue(newgridValue);
    };
    return (
        <>
            <Index.Box className="exchange-dash-main ">
                <Index.Box className="overlay-section"></Index.Box>
                <Index.Box className="spot-trade-main">
                    <Index.Box className="trade-left">
                        <Index.Box className="trade-left-header">
                            <Index.Box className="trade-bitcoin-sec">
                                <Index.Box className="bitcoin-usdt-price">
                                    <Index.Box className="bitcoin-usdt">
                                        <Index.Typography
                                            className="utc-text"
                                            component="h6"
                                            variant="h6"
                                        >
                                            BTC/USDT
                                        </Index.Typography>
                                        <Index.Box className="book-ic">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="iconLeave css-yzbbmf"><path d="M15.11 5.63L6 6.89 15.11 4v1.63zM17.61 6.89v11.2h-1.25V8.33L6 6.89h11.61z" fill="#707a8a"></path><path d="M6 6.89v11.16l.1.01.07.01.17.03h-.27l9.04 2.86V9.77L6 6.89zm3.4 3.2l1.87.6v1.84l-1.87-.59v-1.85zm1.87 7.66l-1.87-.59.03-3.85 1.88.59-.04 3.85z" fill="#707a8a"></path></svg>
                                        </Index.Box>
                                        <Index.Link className='coin-price-link'>Bitcoin Price</Index.Link>
                                    </Index.Box>
                                    <Index.Box className="bitcoin-vol">
                                        <Index.Typography
                                            className="vol-text"
                                            component="p"
                                            variant="p"
                                        >
                                            PWO
                                        </Index.Typography>
                                        <Index.Typography
                                            className="vol-text text-dash"
                                            component="p"
                                            variant="p"
                                        >
                                            ᱾
                                        </Index.Typography>
                                        <Index.Typography
                                            className="vol-text"
                                            component="p"
                                            variant="p"
                                        >
                                            Vol
                                        </Index.Typography>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-kkvevc"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.288 12l-3.89 3.89 1.768 1.767L15.823 12l-1.768-1.768-3.889-3.889-1.768 1.768 3.89 3.89z" fill="#c99400"></path></svg>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className='up-down-price-sec'>
                                    <Index.Typography
                                        className="up-down-text green-text"
                                        component="p"
                                        variant="p"
                                    >
                                        26,179.64
                                    </Index.Typography>
                                    <Index.Typography
                                        className="up-dow-black"
                                        component="p"
                                        variant="p"
                                    >
                                        $26,158.61
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className='up-down-price-sec'>
                                    <Index.Typography
                                        className="change-text"
                                        component="p"
                                        variant="p"
                                    >
                                        24h change
                                    </Index.Typography>
                                    <Index.Typography
                                        className="change-text"
                                        component="p"
                                        variant="p"
                                    >
                                        348.47 <span>+1.35%</span>
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className='up-down-price-sec'>
                                    <Index.Typography
                                        className="change-text"
                                        component="p"
                                        variant="p"
                                    >
                                        24h High
                                    </Index.Typography>
                                    <Index.Typography
                                        className="change-text"
                                        component="p"
                                        variant="p"
                                    >
                                        26,445.50
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className='up-down-price-sec'>
                                    <Index.Typography
                                        className="change-text"
                                        component="p"
                                        variant="p"
                                    >
                                        24h Low
                                    </Index.Typography>
                                    <Index.Typography
                                        className="change-text"
                                        component="p"
                                        variant="p"
                                    >
                                        25,615.38
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className='up-down-price-sec'>
                                    <Index.Typography
                                        className="change-text"
                                        component="p"
                                        variant="p"
                                    >
                                        24h Volume(BTC)
                                    </Index.Typography>
                                    <Index.Typography
                                        className="change-text"
                                        component="p"
                                        variant="p"
                                    >
                                        34,445.50
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className='up-down-price-sec'>
                                    <Index.Typography
                                        className="change-text"
                                        component="p"
                                        variant="p"
                                    >
                                        24h Volume(USDT)
                                    </Index.Typography>
                                    <Index.Typography
                                        className="change-text"
                                        component="p"
                                        variant="p"
                                    >
                                        890,285.706.80
                                    </Index.Typography>
                                </Index.Box>

                            </Index.Box>
                            <Index.Box className="guidance-sec">
                                <Index.Box className="spot-tutorial">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-ylhofr"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm8.934 3.5H10v-7h1.934L16 12l-4.066 3.5z" fill="#707a8a"></path></svg>
                                    Spot Tutorial
                                </Index.Box>
                                <Index.Box className="spot-tutorial guid-sec">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-ylhofr"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.896 3h14v18h-14V3zm11 13v2h-8v-2h8zm-8-3h8V6h-8v7zm6-5h-4v3h4V8z" fill="#707a8a"></path></svg>
                                    Spot Guidance
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="trade-chart-sec">
                            <Index.Box className="trade-inner-left">
                                <Index.Box className="price-table-sec">
                                    <Index.Box className="price-table-header">
                                        <Index.Box className="grid-table">
                                            <Index.Button className='grid-btn'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1iztezc"><path d="M4 4h7v7H4V4z" fill="#F6465D"></path><path d="M4 13h7v7H4v-7z" fill="#0ECB81"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z" fill="#707A8A"></path></svg>
                                            </Index.Button>
                                            <Index.Button className='grid-btn'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1iztezc"><path d="M4 4h7v16H4V4z" fill="#0ECB81"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z" fill="#707A8A"></path></svg>
                                            </Index.Button>
                                            <Index.Button className='grid-btn'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1iztezc"><path d="M4 4h7v16H4V4z" fill="#F6465D"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z" fill="#707A8A"></path></svg>
                                            </Index.Button>
                                        </Index.Box>
                                        <Index.Box className="price-table-right">
                                            <Index.Box className='dropdown-box pay-dropdown-box'>
                                                <Index.FormControl className='form-control pay-form-control'>
                                                    <Index.Select className='dropdown-select pay-select'
                                                        value={age}
                                                        onChange={handleChange}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <Index.MenuItem value="" className='menuitem'>
                                                            0.1
                                                        </Index.MenuItem>
                                                        <Index.MenuItem value={10} className='payment-menuitem'>0.01</Index.MenuItem>
                                                        <Index.MenuItem value={20} className='payment-menuitem'>0.1</Index.MenuItem>
                                                        <Index.MenuItem value={30} className='payment-menuitem'>1</Index.MenuItem>
                                                        <Index.MenuItem value={30} className='payment-menuitem'>10</Index.MenuItem>
                                                        <Index.MenuItem value={30} className='payment-menuitem'>50</Index.MenuItem>
                                                        <Index.MenuItem value={30} className='payment-menuitem'>100</Index.MenuItem>
                                                    </Index.Select>
                                                    <span><img src={Index.Svg.downArrow} className="grey-down pay-down" alt='grey down arrow'></img></span>
                                                </Index.FormControl>
                                            </Index.Box>
                                            <Index.Box className='table-menu-btn'>
                                                <Index.IconButton
                                                    aria-label="more"
                                                    id="long-button"
                                                    aria-controls={open ? 'long-menu' : undefined}
                                                    aria-expanded={open ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={handleClick}
                                                >
                                                    <Index.MoreVertIcon />
                                                </Index.IconButton>
                                                <Index.Menu
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'long-button',
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    PaperProps={{
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.5,
                                                            width: '20ch',
                                                        },
                                                    }}
                                                >
                                                    {options.map((option) => (
                                                        <Index.MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                                                            {option}
                                                        </Index.MenuItem>
                                                    ))}
                                                </Index.Menu>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className='price-table-main'>
                                        <Index.TableContainer className='table-container' component={Paper}>
                                            <Index.Table className='price-table-wrap' aria-label="simple table">
                                                <Index.TableHead>
                                                    <Index.TableRow>
                                                        <Index.TableCell>Price(USDT)</Index.TableCell>
                                                        <Index.TableCell align="right">Amount(BTC)</Index.TableCell>
                                                        <Index.TableCell align="right">Total</Index.TableCell>
                                                    </Index.TableRow>
                                                </Index.TableHead>
                                                <Index.TableBody>
                                                    {rows.map((row) => (
                                                        <Index.TableRow
                                                            key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <Index.TableCell scope="row">
                                                                {row.price}
                                                            </Index.TableCell>
                                                            <Index.TableCell align="right">{row.amount}</Index.TableCell>
                                                            <Index.TableCell align="right">{row.total}</Index.TableCell>
                                                        </Index.TableRow>
                                                    ))}
                                                </Index.TableBody>
                                            </Index.Table>
                                        </Index.TableContainer>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="price-table-sec">
                                    <Index.Box className="price-table-header">
                                        <Index.Box className="grid-table">
                                            <Index.Typography
                                                className="table-head-text green-text"
                                                component="p"
                                                variant="p"
                                            >
                                                25,854.02
                                            </Index.Typography>
                                            <Index.Box className='up-down-arrown'>
                                                <img
                                                    src={Index.Svg.arrowGreen}
                                                    alt="arrow icon"
                                                    className="arrow-green-icons"
                                                />
                                                <img
                                                    src={Index.Svg.arrowRed}
                                                    alt="arrow icon"
                                                    className="arrow-red-icons"
                                                />
                                            </Index.Box>
                                            <Index.Typography
                                                className="head-sub-text"
                                                component="p"
                                                variant="p"
                                            >
                                                $25,855.58
                                            </Index.Typography>
                                        </Index.Box>
                                        <Index.Box className="price-table-right">
                                            {/* <Index.Typography
                                            className="head-sub-text"
                                            component="p"
                                            variant="p"
                                        >
                                            
                                        </Index.Typography> */}
                                            <Index.Link to="#" className="more-link">More</Index.Link>
                                        </Index.Box>

                                    </Index.Box>
                                    <Index.Box className='price-table-main price-bottom-table'>
                                        <Index.TableContainer className='table-container' component={Paper}>
                                            <Index.Table className='price-table-wrap' aria-label="simple table">
                                                {/* <Index.TableHead>
                                                    <Index.TableRow>
                                                        <Index.TableCell>Price(USDT)</Index.TableCell>
                                                        <Index.TableCell align="right">Amount(BTC)</Index.TableCell>
                                                        <Index.TableCell align="right">Total</Index.TableCell>
                                                    </Index.TableRow>
                                                </Index.TableHead> */}
                                                <Index.TableBody>
                                                    {rows.map((row) => (
                                                        <Index.TableRow
                                                            key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <Index.TableCell scope="row">
                                                                {row.price}
                                                            </Index.TableCell>
                                                            <Index.TableCell align="right">{row.amount}</Index.TableCell>
                                                            <Index.TableCell align="right">{row.total}</Index.TableCell>
                                                        </Index.TableRow>
                                                    ))}
                                                </Index.TableBody>
                                            </Index.Table>
                                        </Index.TableContainer>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="trade-inner-right">
                                <Index.Box className="trade-chart-main">
                                    <img
                                        src={Index.Png.chartTrad}
                                        className="chart-img"
                                    />
                                </Index.Box>
                                <Index.Box className="trade-spot-tab-main">
                                    <Index.Box sx={{ width: '100%' }}>
                                        <Index.Box>
                                            <Index.Tabs value={spotvalue} onChange={spothandleChange} aria-label="basic tabs example" className='cus-spot-tabs'>
                                                <Index.Tab label="Spot" {...a11yProps(0)} className='cus-spot-tab' />
                                                <Index.Tab label="Cross 5x" {...a11yProps(1)} className='cus-spot-tab' />
                                                <Index.Tab label="Isolated" {...a11yProps(2)} className='cus-spot-tab' />
                                                {/* <Index.Tab label="Grid" {...a11yProps(3)} className='cus-spot-tab' /> */}
                                            </Index.Tabs>
                                        </Index.Box>
                                        <Index.CustomTabPanel value={spotvalue} index={0} className='spot-tab-panel'>
                                            <Index.Box className="spot-inner-tab">
                                                {/* <Index.Box sx={{ width: '100%' }}> */}
                                                {/* <Index.Box> */}

                                                <Index.Tabs value={limitvalue} onChange={limithandleChange} aria-label="basic tabs example2" className='cus-spot-inner-tabs'>
                                                    <Index.Tab label="Limit" {...a11yProps(0)} className='cus-spot-inner-tab' />
                                                    <Index.Tab label="Market" {...a11yProps(1)} className='cus-spot-inner-tab' />
                                                    <Index.Tab label="Spot-Limit" {...a11yProps(2)} className='cus-spot-inner-tab' />
                                                </Index.Tabs>
                                                {/* </Index.Box> */}
                                                <Index.CustomTabPanel value={limitvalue} index={0} className='spot-inner-tab-panel'>
                                                    <Index.Box className='limit-wrapper'>
                                                        <Index.Box className='avbl-box avbl-usdt-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-USDT</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Amount
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={70}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>

                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                        <Index.Box className='avbl-box avbl-btc-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-BTC</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>

                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Amount
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={0}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.CustomTabPanel>
                                                <Index.CustomTabPanel value={limitvalue} index={1} className='spot-inner-tab-panel'>
                                                    <Index.Box className='limit-wrapper'>
                                                        <Index.Box className='avbl-box avbl-usdt-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-USDT</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='Market'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Total
                                                                    </Index.Typography>
                                                                    <Index.Box className='cus-arrow-drop'>
                                                                        <Index.Box className='cus-arrow-ic'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="t.third" data-testid="BUYDownArrow" class="css-p3k24z"><path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="#7A7A8A"></path></svg>
                                                                        </Index.Box>
                                                                        <Index.Box className='cus-drop-list'>
                                                                            <Index.List className='drop-list-ul'>
                                                                                <Index.ListItem className='drop-list-li'>Amount</Index.ListItem>
                                                                                <Index.ListItem className='drop-list-li active'>Total
                                                                                    <Index.Box className='drop-tooltip'>
                                                                                        <Index.Tooltip className='cus-tooltip' title="Place a market order based on the amount of assets you want to receive. The final executed quantity and price will depend on the actual transaction result." placement="top-start" arrow>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#000"></path></svg>
                                                                                        </Index.Tooltip>
                                                                                    </Index.Box>
                                                                                </Index.ListItem>
                                                                            </Index.List>
                                                                        </Index.Box>
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={50}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>

                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                        <Index.Box className='avbl-box avbl-btc-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-BTC</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>


                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='Market'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>

                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Amount
                                                                    </Index.Typography>
                                                                    <Index.Box className='cus-arrow-drop'>
                                                                        <Index.Box className='cus-arrow-ic'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="t.third" data-testid="BUYDownArrow" class="css-p3k24z"><path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="#7A7A8A"></path></svg>
                                                                        </Index.Box>
                                                                        <Index.Box className='cus-drop-list'>
                                                                            <Index.List className='drop-list-ul'>
                                                                                <Index.ListItem className='drop-list-li active'>Amount</Index.ListItem>
                                                                                <Index.ListItem className='drop-list-li'>Total
                                                                                    <Index.Box className='drop-tooltip'>
                                                                                        <Index.Tooltip className='cus-tooltip' title="Place a market order based on the amount of assets you want to receive. The final executed quantity and price will depend on the actual transaction result." placement="top-start" arrow>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#000"></path></svg>
                                                                                        </Index.Tooltip>
                                                                                    </Index.Box>
                                                                                </Index.ListItem>
                                                                            </Index.List>
                                                                        </Index.Box>
                                                                    </Index.Box>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={0}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.CustomTabPanel>
                                                <Index.CustomTabPanel value={limitvalue} index={2} className='spot-inner-tab-panel'>
                                                    <Index.Box className='limit-wrapper'>
                                                        <Index.Box className='avbl-box avbl-usdt-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-USDT</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='Market'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Total
                                                                    </Index.Typography>
                                                                    <Index.Box className='cus-arrow-drop'>
                                                                        <Index.Box className='cus-arrow-ic'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="t.third" data-testid="BUYDownArrow" class="css-p3k24z"><path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="#7A7A8A"></path></svg>
                                                                        </Index.Box>
                                                                        <Index.Box className='cus-drop-list'>
                                                                            <Index.List className='drop-list-ul'>
                                                                                <Index.ListItem className='drop-list-li'>Amount</Index.ListItem>
                                                                                <Index.ListItem className='drop-list-li active'>Total
                                                                                    <Index.Box className='drop-tooltip'>
                                                                                        <Index.Tooltip className='cus-tooltip' title="Place a market order based on the amount of assets you want to receive. The final executed quantity and price will depend on the actual transaction result." placement="top-start" arrow>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#000"></path></svg>
                                                                                        </Index.Tooltip>
                                                                                    </Index.Box>
                                                                                </Index.ListItem>
                                                                            </Index.List>
                                                                        </Index.Box>
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={50}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>

                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                        <Index.Box className='avbl-box avbl-btc-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-BTC</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>


                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='Market'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>

                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Amount
                                                                    </Index.Typography>
                                                                    <Index.Box className='cus-arrow-drop'>
                                                                        <Index.Box className='cus-arrow-ic'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="t.third" data-testid="BUYDownArrow" class="css-p3k24z"><path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="#7A7A8A"></path></svg>
                                                                        </Index.Box>
                                                                        <Index.Box className='cus-drop-list'>
                                                                            <Index.List className='drop-list-ul'>
                                                                                <Index.ListItem className='drop-list-li active'>Amount</Index.ListItem>
                                                                                <Index.ListItem className='drop-list-li'>Total
                                                                                    <Index.Box className='drop-tooltip'>
                                                                                        <Index.Tooltip className='cus-tooltip' title="Place a market order based on the amount of assets you want to receive. The final executed quantity and price will depend on the actual transaction result." placement="top-start" arrow>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#000"></path></svg>
                                                                                        </Index.Tooltip>
                                                                                    </Index.Box>
                                                                                </Index.ListItem>
                                                                            </Index.List>
                                                                        </Index.Box>
                                                                    </Index.Box>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={0}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.CustomTabPanel>
                                                {/* </Index.Box> */}
                                            </Index.Box>
                                        </Index.CustomTabPanel>
                                        <Index.CustomTabPanel value={spotvalue} index={1} className='spot-tab-panel'>
                                            <Index.Box className="spot-inner-tab">
                                                {/* <Index.Box sx={{ width: '100%' }}> */}
                                                {/* <Index.Box> */}

                                                <Index.Tabs value={limitvalue} onChange={limithandleChange} aria-label="basic tabs example2" className='cus-spot-inner-tabs'>
                                                    <Index.Tab label="Limit" {...a11yProps(0)} className='cus-spot-inner-tab' />
                                                    <Index.Tab label="Market" {...a11yProps(1)} className='cus-spot-inner-tab' />
                                                    <Index.Tab label="Spot-Limit" {...a11yProps(2)} className='cus-spot-inner-tab' />
                                                </Index.Tabs>
                                                {/* </Index.Box> */}
                                                <Index.CustomTabPanel value={limitvalue} index={0} className='spot-inner-tab-panel'>
                                                    <Index.Box className='limit-wrapper'>
                                                        <Index.Box className='avbl-box avbl-usdt-box'>
                                                            <Index.Box className='borrow-tooltip-main'>
                                                                <Index.Box className='borrow-sec'>
                                                                    <Index.List>
                                                                        <Index.ListItem>Normal</Index.ListItem>
                                                                        <Index.ListItem>Borrow</Index.ListItem>
                                                                        <Index.ListItem className='active'>Reply</Index.ListItem>
                                                                    </Index.List>
                                                                </Index.Box>
                                                                <Index.Box className='drop-tooltip'>
                                                                    <Index.Tooltip className='cus-tooltip' title="Repay Mode:Assets received after a trade will automatically be used to repay the debt of the same coin in your margin account. If there is an insufficient amount when repaying, it will repay 90% of the debt only." placement="top-start" arrow>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#707A8A"></path></svg>
                                                                    </Index.Tooltip>
                                                                </Index.Box>
                                                            </Index.Box>

                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-USDT</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Amount
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={70}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>

                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                        <Index.Box className='avbl-box avbl-btc-box'>
                                                            <Index.Box className='borrow-tooltip-main'>
                                                                <Index.Box className='borrow-sec'>
                                                                    <Index.List>
                                                                        <Index.ListItem>Normal</Index.ListItem>
                                                                        <Index.ListItem>Borrow</Index.ListItem>
                                                                        <Index.ListItem className='active'>Reply</Index.ListItem>
                                                                    </Index.List>
                                                                </Index.Box>
                                                                <Index.Box className='drop-tooltip'>
                                                                    <Index.Tooltip className='cus-tooltip' title="Repay Mode:Assets received after a trade will automatically be used to repay the debt of the same coin in your margin account. If there is an insufficient amount when repaying, it will repay 90% of the debt only." placement="top-start" arrow>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#707A8A"></path></svg>
                                                                    </Index.Tooltip>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-BTC</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>

                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Amount
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={0}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.CustomTabPanel>
                                                <Index.CustomTabPanel value={limitvalue} index={1} className='spot-inner-tab-panel'>
                                                    <Index.Box className='limit-wrapper'>
                                                        <Index.Box className='avbl-box avbl-usdt-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-USDT</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='Market'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Total
                                                                    </Index.Typography>
                                                                    <Index.Box className='cus-arrow-drop'>
                                                                        <Index.Box className='cus-arrow-ic'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="t.third" data-testid="BUYDownArrow" class="css-p3k24z"><path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="#7A7A8A"></path></svg>
                                                                        </Index.Box>
                                                                        <Index.Box className='cus-drop-list'>
                                                                            <Index.List className='drop-list-ul'>
                                                                                <Index.ListItem className='drop-list-li'>Amount</Index.ListItem>
                                                                                <Index.ListItem className='drop-list-li active'>Total
                                                                                    <Index.Box className='drop-tooltip'>
                                                                                        <Index.Tooltip className='cus-tooltip' title="Place a market order based on the amount of assets you want to receive. The final executed quantity and price will depend on the actual transaction result." placement="top-start" arrow>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#000"></path></svg>
                                                                                        </Index.Tooltip>
                                                                                    </Index.Box>
                                                                                </Index.ListItem>
                                                                            </Index.List>
                                                                        </Index.Box>
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={50}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>

                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                        <Index.Box className='avbl-box avbl-btc-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-BTC</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>


                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='Market'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>

                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Amount
                                                                    </Index.Typography>
                                                                    <Index.Box className='cus-arrow-drop'>
                                                                        <Index.Box className='cus-arrow-ic'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="t.third" data-testid="BUYDownArrow" class="css-p3k24z"><path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="#7A7A8A"></path></svg>
                                                                        </Index.Box>
                                                                        <Index.Box className='cus-drop-list'>
                                                                            <Index.List className='drop-list-ul'>
                                                                                <Index.ListItem className='drop-list-li active'>Amount</Index.ListItem>
                                                                                <Index.ListItem className='drop-list-li'>Total
                                                                                    <Index.Box className='drop-tooltip'>
                                                                                        <Index.Tooltip className='cus-tooltip' title="Place a market order based on the amount of assets you want to receive. The final executed quantity and price will depend on the actual transaction result." placement="top-start" arrow>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#000"></path></svg>
                                                                                        </Index.Tooltip>
                                                                                    </Index.Box>
                                                                                </Index.ListItem>
                                                                            </Index.List>
                                                                        </Index.Box>
                                                                    </Index.Box>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={0}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.CustomTabPanel>
                                                <Index.CustomTabPanel value={limitvalue} index={2} className='spot-inner-tab-panel'>
                                                    <Index.Box className='limit-wrapper'>
                                                        <Index.Box className='avbl-box avbl-usdt-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-USDT</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='Market'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Total
                                                                    </Index.Typography>
                                                                    <Index.Box className='cus-arrow-drop'>
                                                                        <Index.Box className='cus-arrow-ic'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="t.third" data-testid="BUYDownArrow" class="css-p3k24z"><path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="#7A7A8A"></path></svg>
                                                                        </Index.Box>
                                                                        <Index.Box className='cus-drop-list'>
                                                                            <Index.List className='drop-list-ul'>
                                                                                <Index.ListItem className='drop-list-li'>Amount</Index.ListItem>
                                                                                <Index.ListItem className='drop-list-li active'>Total
                                                                                    <Index.Box className='drop-tooltip'>
                                                                                        <Index.Tooltip className='cus-tooltip' title="Place a market order based on the amount of assets you want to receive. The final executed quantity and price will depend on the actual transaction result." placement="top-start" arrow>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#000"></path></svg>
                                                                                        </Index.Tooltip>
                                                                                    </Index.Box>
                                                                                </Index.ListItem>
                                                                            </Index.List>
                                                                        </Index.Box>
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={50}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>

                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                        <Index.Box className='avbl-box avbl-btc-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-BTC</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>


                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='Market'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>

                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Amount
                                                                    </Index.Typography>
                                                                    <Index.Box className='cus-arrow-drop'>
                                                                        <Index.Box className='cus-arrow-ic'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="t.third" data-testid="BUYDownArrow" class="css-p3k24z"><path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="#7A7A8A"></path></svg>
                                                                        </Index.Box>
                                                                        <Index.Box className='cus-drop-list'>
                                                                            <Index.List className='drop-list-ul'>
                                                                                <Index.ListItem className='drop-list-li active'>Amount</Index.ListItem>
                                                                                <Index.ListItem className='drop-list-li'>Total
                                                                                    <Index.Box className='drop-tooltip'>
                                                                                        <Index.Tooltip className='cus-tooltip' title="Place a market order based on the amount of assets you want to receive. The final executed quantity and price will depend on the actual transaction result." placement="top-start" arrow>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#000"></path></svg>
                                                                                        </Index.Tooltip>
                                                                                    </Index.Box>
                                                                                </Index.ListItem>
                                                                            </Index.List>
                                                                        </Index.Box>
                                                                    </Index.Box>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={0}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.CustomTabPanel>
                                                {/* </Index.Box> */}
                                            </Index.Box>
                                        </Index.CustomTabPanel>
                                        <Index.CustomTabPanel value={spotvalue} index={2} className='spot-tab-panel'>
                                            <Index.Box className="spot-inner-tab">
                                                {/* <Index.Box sx={{ width: '100%' }}> */}
                                                {/* <Index.Box> */}

                                                <Index.Tabs value={limitvalue} onChange={limithandleChange} aria-label="basic tabs example2" className='cus-spot-inner-tabs'>
                                                    <Index.Tab label="Limit" {...a11yProps(0)} className='cus-spot-inner-tab' />
                                                    <Index.Tab label="Market" {...a11yProps(1)} className='cus-spot-inner-tab' />
                                                    <Index.Tab label="Spot-Limit" {...a11yProps(2)} className='cus-spot-inner-tab' />
                                                </Index.Tabs>
                                                {/* </Index.Box> */}
                                                <Index.CustomTabPanel value={limitvalue} index={0} className='spot-inner-tab-panel'>
                                                    <Index.Box className='limit-wrapper'>
                                                        <Index.Box className='avbl-box avbl-usdt-box'>
                                                            <Index.Box className='borrow-tooltip-main'>
                                                                <Index.Box className='borrow-sec'>
                                                                    <Index.List>
                                                                        <Index.ListItem>Normal</Index.ListItem>
                                                                        <Index.ListItem>Borrow</Index.ListItem>
                                                                        <Index.ListItem className='active'>Reply</Index.ListItem>
                                                                    </Index.List>
                                                                </Index.Box>
                                                                <Index.Box className='drop-tooltip'>
                                                                    <Index.Tooltip className='cus-tooltip' title="Repay Mode:Assets received after a trade will automatically be used to repay the debt of the same coin in your margin account. If there is an insufficient amount when repaying, it will repay 90% of the debt only." placement="top-start" arrow>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#707A8A"></path></svg>
                                                                    </Index.Tooltip>
                                                                </Index.Box>
                                                            </Index.Box>

                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-USDT</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Amount
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={70}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>

                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                        <Index.Box className='avbl-box avbl-btc-box'>
                                                            <Index.Box className='borrow-tooltip-main'>
                                                                <Index.Box className='borrow-sec'>
                                                                    <Index.List>
                                                                        <Index.ListItem>Normal</Index.ListItem>
                                                                        <Index.ListItem>Borrow</Index.ListItem>
                                                                        <Index.ListItem className='active'>Reply</Index.ListItem>
                                                                    </Index.List>
                                                                </Index.Box>
                                                                <Index.Box className='drop-tooltip'>
                                                                    <Index.Tooltip className='cus-tooltip' title="Repay Mode:Assets received after a trade will automatically be used to repay the debt of the same coin in your margin account. If there is an insufficient amount when repaying, it will repay 90% of the debt only." placement="top-start" arrow>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#707A8A"></path></svg>
                                                                    </Index.Tooltip>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-BTC</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>

                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Amount
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={0}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.CustomTabPanel>
                                                <Index.CustomTabPanel value={limitvalue} index={1} className='spot-inner-tab-panel'>
                                                    <Index.Box className='limit-wrapper'>
                                                        <Index.Box className='avbl-box avbl-usdt-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-USDT</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='Market'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Total
                                                                    </Index.Typography>
                                                                    <Index.Box className='cus-arrow-drop'>
                                                                        <Index.Box className='cus-arrow-ic'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="t.third" data-testid="BUYDownArrow" class="css-p3k24z"><path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="#7A7A8A"></path></svg>
                                                                        </Index.Box>
                                                                        <Index.Box className='cus-drop-list'>
                                                                            <Index.List className='drop-list-ul'>
                                                                                <Index.ListItem className='drop-list-li'>Amount</Index.ListItem>
                                                                                <Index.ListItem className='drop-list-li active'>Total
                                                                                    <Index.Box className='drop-tooltip'>
                                                                                        <Index.Tooltip className='cus-tooltip' title="Place a market order based on the amount of assets you want to receive. The final executed quantity and price will depend on the actual transaction result." placement="top-start" arrow>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#000"></path></svg>
                                                                                        </Index.Tooltip>
                                                                                    </Index.Box>
                                                                                </Index.ListItem>
                                                                            </Index.List>
                                                                        </Index.Box>
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={50}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>

                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                        <Index.Box className='avbl-box avbl-btc-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-BTC</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>


                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='Market'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>

                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Amount
                                                                    </Index.Typography>
                                                                    <Index.Box className='cus-arrow-drop'>
                                                                        <Index.Box className='cus-arrow-ic'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="t.third" data-testid="BUYDownArrow" class="css-p3k24z"><path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="#7A7A8A"></path></svg>
                                                                        </Index.Box>
                                                                        <Index.Box className='cus-drop-list'>
                                                                            <Index.List className='drop-list-ul'>
                                                                                <Index.ListItem className='drop-list-li active'>Amount</Index.ListItem>
                                                                                <Index.ListItem className='drop-list-li'>Total
                                                                                    <Index.Box className='drop-tooltip'>
                                                                                        <Index.Tooltip className='cus-tooltip' title="Place a market order based on the amount of assets you want to receive. The final executed quantity and price will depend on the actual transaction result." placement="top-start" arrow>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#000"></path></svg>
                                                                                        </Index.Tooltip>
                                                                                    </Index.Box>
                                                                                </Index.ListItem>
                                                                            </Index.List>
                                                                        </Index.Box>
                                                                    </Index.Box>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={0}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.CustomTabPanel>
                                                <Index.CustomTabPanel value={limitvalue} index={2} className='spot-inner-tab-panel'>
                                                    <Index.Box className='limit-wrapper'>
                                                        <Index.Box className='avbl-box avbl-usdt-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-USDT</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='Market'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Total
                                                                    </Index.Typography>
                                                                    <Index.Box className='cus-arrow-drop'>
                                                                        <Index.Box className='cus-arrow-ic'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="t.third" data-testid="BUYDownArrow" class="css-p3k24z"><path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="#7A7A8A"></path></svg>
                                                                        </Index.Box>
                                                                        <Index.Box className='cus-drop-list'>
                                                                            <Index.List className='drop-list-ul'>
                                                                                <Index.ListItem className='drop-list-li'>Amount</Index.ListItem>
                                                                                <Index.ListItem className='drop-list-li active'>Total
                                                                                    <Index.Box className='drop-tooltip'>
                                                                                        <Index.Tooltip className='cus-tooltip' title="Place a market order based on the amount of assets you want to receive. The final executed quantity and price will depend on the actual transaction result." placement="top-start" arrow>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#000"></path></svg>
                                                                                        </Index.Tooltip>
                                                                                    </Index.Box>
                                                                                </Index.ListItem>
                                                                            </Index.List>
                                                                        </Index.Box>
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={50}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>

                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                        <Index.Box className='avbl-box avbl-btc-box'>
                                                            <Index.Typography
                                                                className="avbl-text"
                                                                variant="p"
                                                                component="p"
                                                            >
                                                                Avbl <span>-BTC</span>
                                                            </Index.Typography>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Price
                                                                    </Index.Typography>


                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                            placeholder='Market'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        USDT
                                                                    </Index.Typography>
                                                                </Index.Box>

                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp'>
                                                                <Index.Box className='price-start-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        Amount
                                                                    </Index.Typography>
                                                                    <Index.Box className='cus-arrow-drop'>
                                                                        <Index.Box className='cus-arrow-ic'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="t.third" data-testid="BUYDownArrow" class="css-p3k24z"><path d="M16 9v1.2L12 15l-4-4.8V9h8z" fill="#7A7A8A"></path></svg>
                                                                        </Index.Box>
                                                                        <Index.Box className='cus-drop-list'>
                                                                            <Index.List className='drop-list-ul'>
                                                                                <Index.ListItem className='drop-list-li active'>Amount</Index.ListItem>
                                                                                <Index.ListItem className='drop-list-li'>Total
                                                                                    <Index.Box className='drop-tooltip'>
                                                                                        <Index.Tooltip className='cus-tooltip' title="Place a market order based on the amount of assets you want to receive. The final executed quantity and price will depend on the actual transaction result." placement="top-start" arrow>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-13nk0v9"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="#000"></path></svg>
                                                                                        </Index.Tooltip>
                                                                                    </Index.Box>
                                                                                </Index.ListItem>
                                                                            </Index.List>
                                                                        </Index.Box>
                                                                    </Index.Box>

                                                                </Index.Box>
                                                                <Index.Box className="price-box">
                                                                    <Index.Box className="form-group">
                                                                        <Index.TextField
                                                                            className="form-control"
                                                                            autocomplete="off"
                                                                        // placeholder='25798.90'
                                                                        />
                                                                    </Index.Box>
                                                                </Index.Box>
                                                                <Index.Box className='price-end-label'>
                                                                    <Index.Typography
                                                                        className="price-text"
                                                                        variant="p"
                                                                        component="p"
                                                                    >
                                                                        BTC
                                                                    </Index.Typography>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='prog-main-wrapper'>
                                                                <Index.Box className='prog-main'>
                                                                    <ProgressBar
                                                                        percent={0}
                                                                        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                                                    >
                                                                        <Step transition="scale">

                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic active'
                                                                                />

                                                                            )}
                                                                            {/* <Index.Box className='bn-slider-stepper'></Index.Box> */}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                        <Step transition="scale">
                                                                            {({ accomplished }) => (
                                                                                <img
                                                                                    // style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                                                                                    width="30"
                                                                                    src={Index.Svg.square}
                                                                                    className='squreic'
                                                                                />
                                                                            )}
                                                                        </Step>
                                                                    </ProgressBar>
                                                                </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box className='price-input-grp login-reg-btn'>
                                                                <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link>

                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.CustomTabPanel>
                                                {/* </Index.Box> */}
                                            </Index.Box>
                                        </Index.CustomTabPanel>
                                       
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>


                    <Index.Box className="trade-right">
                        <Index.Box className='trade-search-sec'>
                            <img
                                src={Index.Svg.searchgrey}
                                alt="search icon"
                                className="search-icons"
                            />
                            <Index.Box className="input-box">
                                <Index.Box className="form-group">
                                    <Index.TextField
                                        className="form-control"
                                        autocomplete="off"
                                        placeholder='Search'
                                    />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className='trad-top-tab'>
                            <Index.Box>
                                <Index.Box sx={{ width: '100%' }}>
                                    <Index.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Index.Tabs value={tabvalue} onChange={tabHandleChange} aria-label="basic tabs example" variant="scrollable" className='trad-top-tabs'>
                                            <Index.Tab icon={
                                                <img
                                                    src={Index.Svg.star}
                                                    className="star-icons"
                                                />
                                            }
                                                iconPosition="start" {...a11yProps(0)} />
                                            <Index.Tab label="USDT" {...a11yProps(1)} />
                                            <Index.Tab label="FDUSD" {...a11yProps(2)} />
                                            <Index.Tab label="TUSD" {...a11yProps(3)} />
                                            <Index.Tab label="BUSD" {...a11yProps(4)} />
                                            <Index.Tab label="BNB" {...a11yProps(5)} />
                                            <Index.Tab label="BTC" {...a11yProps(6)} />
                                            <Index.Tab label="ALTS" {...a11yProps(7)} />
                                            <Index.Tab label="FIAT" {...a11yProps(8)} />
                                            <Index.Tab label="Zones" {...a11yProps(9)} />
                                            <Index.Tab label="Margin" {...a11yProps(10)} />
                                            <Index.Tab label="Grid" {...a11yProps(11)} />
                                        </Index.Tabs>
                                    </Index.Box>
                                    <Index.CustomTabPanel value={tabvalue} index={0} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                    <Index.CustomTabPanel value={tabvalue} index={1} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                    <Index.CustomTabPanel value={tabvalue} index={2} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                    <Index.CustomTabPanel value={tabvalue} index={3} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                    <Index.CustomTabPanel value={tabvalue} index={4} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                    <Index.CustomTabPanel value={tabvalue} index={5} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                    <Index.CustomTabPanel value={tabvalue} index={6} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                    <Index.CustomTabPanel value={tabvalue} index={7} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                    <Index.CustomTabPanel value={tabvalue} index={8} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                    <Index.CustomTabPanel value={tabvalue} index={9} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                    <Index.CustomTabPanel value={tabvalue} index={10} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                    <Index.CustomTabPanel value={tabvalue} index={11} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                </Index.Box>

                            </Index.Box>
                        </Index.Box>
                        <Index.Box className='trad-top-tab market-trade-tab'>
                            <Index.Box>
                                <Index.Box >
                                    <Index.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Index.Tabs value={tabvalue} onChange={tabHandleChange} aria-label="basic tabs example" variant="scrollable" className='trad-top-tabs'>

                                            <Index.Tab label="Market Trades" {...a11yProps(0)} />
                                            <Index.Tab label="My Trades" {...a11yProps(1)} />

                                        </Index.Tabs>
                                    </Index.Box>
                                    <Index.CustomTabPanel value={tabvalue} index={0} className='cus-tab-panel'>
                                        <Index.Box className='cus-table-wrapper'>
                                            <DataGrid
                                                rows={tabrows}
                                                columns={tabcolumns}
                                                className='cus-datagrid'
                                            />
                                        </Index.Box>
                                    </Index.CustomTabPanel>
                                    <Index.CustomTabPanel value={tabvalue} index={1} className='cus-tab-panel'>

                                        <Index.Box className='login-reg-tab'>
                                            <Index.Link to='#' className='login-reg-link'>Log in</Index.Link> <span>&nbsp;or&nbsp;</span> <Index.Link className='login-reg-link' to='#'>Register Now</Index.Link><span>to trade</span>
                                        </Index.Box>
                                    </Index.CustomTabPanel>

                                </Index.Box>

                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>

        </>
    )
}

export default SpotTrade
