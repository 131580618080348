import React from "react";
import { Grid, Link, Container, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FooterStyle from "../FooterStyle";
import Svg from "../../../../assets/Svg";
import Png from "../../../../assets/Png";
import Accordion from "./Accordian";
import Divider from "@mui/material/Divider";

const FooterMobile = () => {
  const theme = useTheme();
  let themeMode = theme?.palette?.mode === "light" ? true : false;
  return (
    <div>
      <Container
        sx={{
          color: "Neutrals1",
          backgroundColor: `${!themeMode && "#000000"}`,
        }}
      >
        <Box
          sx={{
            paddingTop: "107.5px",
          }}
        >
          <Grid container>
            <img
              src={themeMode ? Png.ncxIcon : Png.ncxIconDark}
              alt="icon"
              className="mobile-footer-icon"
            />
            <Accordion themeMode={themeMode} />
            {/* <Box className='divider-end-accordian'>
            </Box> */}
            <Box className="mobile-footer-secondbox">
              <Typography sx={FooterStyle.newsletter}>newsletter</Typography>
              <Box className="newsletter-card" sx={{ color: "Neutrals1" }}>
                <Box
                  component="input"
                  className="newsletter-card-input"
                  sx={{ color: "Neutrals1" }}
                  placeholder="Enter your email"
                />
                <button className="newsletter-card-button">
                  <img src={Svg.rightArrow} />
                </button>
              </Box>

              <Box
                sx={[FooterStyle.SocialLinks, FooterStyle.SocialLinksMobile]}
              >
                <Link to="/dashbord" sx={{ marginLeft: "0px!important" }}>
                  <img
                    src={themeMode ? Svg.twitter : Svg.twitterDark}
                    alt="icon"
                  />
                </Link>
                <Link to="/">
                  <img
                    src={themeMode ? Svg.facebook : Svg.facebookDark}
                    alt="icon"
                  />
                </Link>
                <Link to="#">
                  <img
                    src={themeMode ? Svg.instagram : Svg.instagramDark}
                    alt="icon"
                  />
                </Link>
                <Link to="#">
                  <img
                    src={themeMode ? Svg.telegram : Svg.telegramDark}
                    alt="icon"
                  />
                </Link>
              </Box>
              <Box
                className="divider-end-accordian"
                sx={{
                  backgroundColor: "Neutrals6C",
                  margin: "0px 0px 37px 0px",
                }}
              ></Box>
              {/*               
              <Typography variant="p" component="p" sx={FooterStyle.Typo}>
                support@ncx.cx
              </Typography> */}
              {/* <Typography variant="p" component="p" sx={FooterStyle.Typo}>
                Powered by CryptoLink O.U.
              </Typography> */}
              <Typography variant="p" component="p" sx={FooterStyle.Typo}>
                © 2023 all right reserved
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default FooterMobile;
