import React from 'react'
import Index from '../../Index'

function MobileHeader() {
    return (
        <>
            <Index.Box className='mob-header'>
                <Index.Box className='mob-header-left'>
                    <img src={Index.Svg.bergurmenu} className="bergurmenu-icon" alt='dashboard logo'></img>
                    <Index.Box className="mob-header-logo">
                        <img src={Index.Png.fbcNetworkLogo} className="admin-sidebar-logo" alt='logo' />
                    </Index.Box>
                </Index.Box>
                <Index.Box className='mob-header-right'>
                    <Index.Box className="profile-main">
                        <img src={Index.Png.user1} className="" alt='user1' />
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    )
}

export default MobileHeader
