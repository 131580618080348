import "./App.css";
import "swiper/css";
import "./assets/css/style.css";
import "./assets/css/keyur.css";
import "./assets/css/nikita.css";
import "./assets/css/sujal.css";
import "./assets/css/bansari.css";
import "./assets/css/rinku.css";
import "./assets/css/rinku2.css";
import React, { useMemo } from "react";
import "./assets/css/responsive.css";
import { BrowserRouter } from "react-router-dom";

import Router from "./routes/Routes";
import "./assets/css/yagnik.css";
import "./assets/css/meet.css";
import "./assets/css/select2.css";
import "./assets/css/select2.min.css";
import "./assets/css/style.css";
// import "./assets/css/admin.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./component/DefaultLayout/portal/Portal.css";
import "./component/DefaultLayout/portal/Portal.css";
import "./container/pages/exchangedashboord/exchangedashboard.css";
import "./component/DefaultLayout/defaultLayout.css";
import "./container/pages/exchangekyc/exchangekyc.css";

import "../src/container/pages/market/Market.css";
import "../src/container/pages/exchange/Exchange.css";
import "./container/pages/exchangedashboord/exchangedashboard.responsive.css";
import "./container/pages/listing/listing.css";
import "./container/pages/spottrade/spottrade.css";
function App() {
  {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  return (
    <BrowserRouter basename="/">
      {/* all routes */}
      <ToastContainer />
      <Router />
    </BrowserRouter>
  );
}

export default App;
