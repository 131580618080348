import React from "react";
import Index from "../../../component/Index";
import Png from "../../../assets/Png";

const Navigating_business = () => {
  return (
    <>
      <Index.Box className="spacing-40"></Index.Box>
      <Index.Box className="navigating-main">
        <Index.Box className="image-container-crypto">
          <img src={Png.buyCrypto} />
        </Index.Box>
        <Index.Box className="navigation-content">
          <Index.Box className="navigation-details">
            <Index.Typography variant="h1">
              Buy crypto in minutes
            </Index.Typography>
          </Index.Box>
          <Index.Box className="navigation-details">
            <Index.Box className="buy-descr">
              <Index.Box className="buy-desc-txt-box">
                <Index.Box className="round-num">1</Index.Box>
                <Index.Box className="round-num-txt">
                  Create your free FBC account
                </Index.Box>
              </Index.Box>
              <Index.Box className="buy-desc-txt-box">
                <Index.Box className="round-num">2</Index.Box>
                <Index.Box className="round-num-txt">
                  Connect your funding method
                </Index.Box>
              </Index.Box>
              <Index.Box className="buy-desc-txt-box">
                <Index.Box className="round-num">3</Index.Box>
                <Index.Box className="round-num-txt">
                  Buy and sell 200+ cryptocurrencies
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="hero-btn">
              <Index.Link to="/market">Buy crypto</Index.Link>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Navigating_business;
