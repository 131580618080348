import React from "react";
import QRCode from "react-qr-code";
import Index from "../Index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const QrCodeGenerate = ({ referralLink, qrCodeData, typeReferral }) => {
  return (
    <Index.Box>
      <Index.Box
        style={{
          height: "auto",
          margin: "0 auto",
          maxWidth: 200,
          width: "100%",
        }}
      >
        <Index.Typography variant="h6" component="h6" className="qrcode-title">
          {typeReferral}
        </Index.Typography>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={referralLink ? referralLink : qrCodeData}
          viewBox={`0 0 256 256`}
        />
      </Index.Box>
    </Index.Box>
  );
};

export default QrCodeGenerate;
