import React from "react";
import Index from "../../../component/Index";
import Png from "../../../assets/Png";
import "../../../assets/css/zoya.css";

const Services = () => {
  return (
    <>
      <Index.Box className="services">
        <Index.Box className="container">
          <Index.Grid container>
            <Index.Grid item sm={2}>
              <Index.Box className="image_box">
                <img src={Png.advantageImg} />
              </Index.Box>
            </Index.Grid>
            <Index.Grid item sm={2}>
              <Index.Box className="image_box">
                <img src={Png.medicareImg} />
              </Index.Box>
            </Index.Grid>

            <Index.Grid item sm={2}>
              <Index.Box className="image_box">
                <img src={Png.PrimaveraImg} />
              </Index.Box>
            </Index.Grid>
            <Index.Grid item sm={2}>
              <Index.Box className="image_box">
                <img src={Png.celesteImg} />
              </Index.Box>
            </Index.Grid>
            <Index.Grid item sm={2}>
              <Index.Box className="image_box">
                <img src={Png.applauzImg} />
              </Index.Box>
            </Index.Grid>
            <Index.Grid item sm={2}>
              <Index.Box className="image_box">
                <img src={Png.konkImg} />
              </Index.Box>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Services;
