import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Index from "../../../component/Index";
import { MuiOtpInput } from "mui-one-time-password-input";
import { API } from "../../../config/Api";
import { DataService } from "../../../config/DataService";
import Chatboat from "../../../component/common/chatboat";

const OtpVerify = () => {
  const location = Index.useLocation();
  const navigate = Index.useNavigate();

  const [email, setEmail] = useState(location?.state?.email);

  let initialValues = {
    Otp: "",
  };

  const validationSchema = Yup.object().shape({
    Otp: Yup.string()
      .min(4, "Please Enter valid OTP")
      .required("Please Enter OTP"),
  });

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = async () => {
    setMinutes(1);
    setSeconds(59);

    if (email) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("email", email?.trim());
      await DataService()
        .post(API.User.USER_FORGOT_PASSWORD, urlencoded)
        .then((res) => {
          if (res?.data?.status == 200) {
            Index.toast.success(res?.data?.message);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.status == 401) {
            Index.toast.error(error?.response?.data?.message);
          } else {
            Index.toast.error("User not exist");
          }
        });
    }
  };

  const handleFormSubmit = async (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", email?.trim());
    urlencoded.append("otp", values.Otp?.trim());
    DataService()
      .post(API.User.USER_OTP, urlencoded)
      .then((res) => {
        if (res.data.status == 200) {
          Index.toast.success(res.data.message);
          navigate("/new/reset-password", {
            state: {
              otp: values.Otp,
            },
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status == 401) {
          Index.toast.error(error?.response?.data?.message);
        }
      });
  };
  return (
    <div>
      <Index.Box className="center-div auth-wrapper">
        <Index.Box className="admin-login-inner auth-form">
          <Index.Box className="login-details-title">
            <Index.Typography
              className="login-title"
              component="h5"
              variant="h5"
            >
              Verify OTP
            </Index.Typography>
            <Index.Typography className="login-desc" component="p" variant="p">
              We sent you an OTP to your registered Email
            </Index.Typography>
          </Index.Box>

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <Index.Box className="otp-flex-main otp-flex-main2 form-group">
                  <MuiOtpInput
                    length={4}
                    className="form-control"
                    name="Otp"
                    onBlur={handleBlur}
                    value={values.Otp}
                    onChange={(value) => setFieldValue("Otp", value)}
                    helperText={touched.Otp && errors.Otp}
                    error={Boolean(errors.Otp && touched.Otp)}
                  />
                  {touched.Otp && errors.Otp && (
                    <Index.FormHelperText error>
                      {errors.Otp}
                    </Index.FormHelperText>
                  )}
                </Index.Box>
                <Index.Box className="countdown-text resend-main">
                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p>Didn't recieve code?</p>
                  )}
                  {!(seconds > 0 || minutes > 0) && (
                    <Index.Box className="resend-button ">
                      <Index.Link
                        disabled={seconds > 0 || minutes > 0}
                        onClick={resendOTP}
                      >
                        Resend OTP
                      </Index.Link>
                    </Index.Box>
                  )}
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column login-column"
                  mt={2}
                >
                  <Index.Box className="">
                    <Index.Link className="signin-btn-main primary-btn-main-link-user">
                      <Index.Button
                        type="submit"
                        variant="contained"
                        className="comman-primary-btn "
                        onClick={handleSubmit}
                      >
                        OTP Verify
                      </Index.Button>
                    </Index.Link>
                  </Index.Box>
                </Index.Box>
              </>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
      <Chatboat />
    </div>
  );
};

export default OtpVerify;
