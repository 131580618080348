import React from 'react'
import Index from "../../../component/Index";

function ExchangeKyc() {
    const [age, setAge] = React.useState('');

    const handleChangedropdown = (event) => {
        setAge(event.target.value);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  return (
    <>
        <Index.Box className="exchange-dash-main">
            <Index.Box className='mob-header'>
                <Index.Box className='mob-header-left'>
                    <img src={Index.Svg.bergurmenu} className="bergurmenu-icon" alt='dashboard logo'></img>
                    <Index.Box className="mob-header-logo">
                        <img src={Index.Png.fbcNetworkLogo} className="admin-sidebar-logo" alt='logo' />
                    </Index.Box>
                </Index.Box>
                <Index.Box className='mob-header-right'>
                    <Index.Box className="profile-main">
                        <img src={Index.Png.user1} className="" alt='user1' />
                    </Index.Box>
                </Index.Box>
            </Index.Box>
            
            <Index.Box className="overlay-section"></Index.Box>
            <Index.Box className="main-title-sec">
                <Index.Typography className='admin-page-title' component='h2' variant='h2'>KYC Verification</Index.Typography>
            </Index.Box>

            <Index.Box className="kyc-wrape">
                <Index.Box className="excha-card">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box display="grid" className="display-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 0 }}>
                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 6" }} className="grid-column">
                                <Index.Box className="swap-box-wrapper">
                                    <Index.Box className="swap-box-content full-width">
                                        <Index.Box className="input-box swap-input-box">
                                            <Index.Typography className='form-lable' variant='p' component='p'>Fullname as per Upload ID</Index.Typography>
                                            <Index.Box className="form-group">
                                                <Index.TextField
                                                    fullWidth
                                                    id="fullWidth"
                                                    className="form-control"
                                                    placeholder="Enter your fullname"
                                                    autocomplete="off"
                                                />
                                            </Index.Box>
                                        </Index.Box>

                                        <Index.Box className="input-box swap-input-box full-width">
                                            <Index.Box className="upload-wrape half-wrape">
                                                <Index.Box className="upload-sec">
                                                    <Index.Typography className='form-lable' variant='p' component='p'>Photo Id Uploaded</Index.Typography>
                                                    <Index.Box className="upload-pad attached">
                                                        <input type="file" />
                                                        <span className="browse">
                                                            <img src={Index.Svg.upload} alt="" className='upload' />
                                                            <span>Choose File</span>
                                                        </span>
                                                        <img src={Index.Png.fbcNetworkLogo} alt='' />
                                                    </Index.Box>
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>

                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 6" }} className="grid-column">
                                <Index.Box className="swap-box-wrapper">
                                    <Index.Box className="swap-box-content full-width">
                                        <Index.Box className="input-box swap-input-box">
                                            <Index.Typography className='form-lable' variant='p' component='p'>Address Proof</Index.Typography>
                                            <Index.Box className='dropdown-box'>
                                                <Index.FormControl className='form-control drop-form-control'>
                                                    <Index.Select className='dropdown-select drop-select'
                                                        value={age}
                                                        onChange={handleChangedropdown}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <Index.MenuItem value="" className='drop-menuitem'>
                                                            Adhar Card
                                                        </Index.MenuItem>
                                                        <Index.MenuItem value={10} className='drop-menuitem'>Driving License</Index.MenuItem>
                                                        <Index.MenuItem value={11} className='drop-menuitem'>Voter ID</Index.MenuItem>
                                                    </Index.Select>
                                                    {/* <span><img src={Index.Svg.instagram} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                                                </Index.FormControl>
                                            </Index.Box>
                                        </Index.Box>

                                        <Index.Box className="input-box swap-input-box full-width">
                                            <Index.Box className="upload-wrape half-wrape">
                                                <Index.Box className="upload-sec">
                                                    <Index.Typography className='form-lable' variant='p' component='p'>
                                                        <Index.Checkbox></Index.Checkbox> Front Upload
                                                    </Index.Typography>
                                                    <Index.Box className="upload-pad">
                                                        <input type="file" />
                                                        <span className="browse">
                                                            <img src={Index.Svg.upload} alt="" className='upload' />
                                                            <span>Choose File</span>
                                                        </span>
                                                        {/* <img src={Index.Png.fbcNetworkLogo} alt='' /> */}
                                                    </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="upload-sec">
                                                    <Index.Typography className='form-lable' variant='p' component='p'>
                                                        <Index.Checkbox></Index.Checkbox> Back Upload
                                                    </Index.Typography>
                                                    <Index.Box className="upload-pad attached">
                                                        <input type="file" />
                                                        <span className="browse">
                                                            <img src={Index.Svg.upload} alt="" className='upload' />
                                                            <span>Choose File</span>
                                                        </span>
                                                        <img src={Index.Png.fbcNetworkLogo} alt='' />
                                                    </Index.Box>
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>

                    <Index.Box className="btn-main-primary withdraw-main-btn right-align">
                        <Index.Button className="btn-primary withdraw-btn">
                            Withdraw
                        </Index.Button>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </Index.Box>
    </>
  )
}

export default ExchangeKyc