import React, { useState, useRef } from "react";
import Index from "../../../component/Index";
import { DataService } from "../../../config/DataService";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../config/Api";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { getWalletDataList } from "../../../redux/features/slices/User/UserService";
import { CircularProgress } from "@mui/material";

// modal
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Transfer = () => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [verifyData, setVerifyData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const walletList = useSelector((state) => state.user.walletList);
  const [open, setOpen] = useState(false);
  const [storeValue, setStoreValue] = useState({});
  const [resetValue, setResetValue] = useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const initialValues = {
    user_name: "",
    selectedToken: walletList?.length > 0 ? walletList?.[0]?.token_name : "",
    amount: "",
  };

  const transferFbc = async (value) => {
    try {
      const transferData = new URLSearchParams();
      transferData.append("user_name", value);

      const res = await DataService(token).post(
        API.User.WITHDRAW_VERIFY_USER,
        transferData
      );

      if (res?.data?.status === 200 && res?.data?.data?.user_name) {
        toast.success(res?.data?.message);
        setVerifyData(res?.data?.data?.user_name);
      } else {
        setVerifyData("");
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const WithdrawCryptoSchema = Yup.object().shape({
    user_name: Yup.string().required("Please enter user name"),
    amount: Yup.number()
      .typeError("Only numbers are allowed")
      .required("Please enter Amount")
      .test("balance", "Amount exceeds available balance", function (value) {
        const selectedTokenBalance = walletList?.find(
          (data) => data?.token_name === this.parent.selectedToken
        )?.token_balance;
        return selectedTokenBalance >= value;
      }),
  });

  const confirmationModalOpen = async () => {
    const data = new URLSearchParams();
    data.append("token_withdraw", storeValue?.amount);
    data.append("transfer_user", storeValue?.user_name);
    data.append("token_name", storeValue?.selectedToken);
    await DataService(token)
      .post(API.Exchange.TRANSFER_WITHDRAW, data)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
          // resetForm();
          dispatch(getWalletDataList(token));
          setOpen(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.response?.data?.message);
        setOpen(false);
      });
    formikRef.current.resetForm();
  };

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    handleOpen();
    setStoreValue(values);
  };

  return (
    <>
      {/* modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="confirmation-box">
          <Box sx={style} className="confirmation-btn">
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to withdraw?
            </Typography>
            <Index.Box className="con-btn">
              <Button
                onClick={confirmationModalOpen}
                style={{
                  color: "white",
                  backgroundColor: "#00235f",
                  fontFamily: "system-ui",
                  fontSize: 13,
                  padding: 5,
                }}
              >
                Yes
              </Button>
              <Button
                onClick={handleClose}
                style={{
                  color: "white",
                  backgroundColor: "red",
                  fontSize: 12,
                  fontFamily: "system-ui",
                  marginLeft: 5,
                }}
              >
                No
              </Button>
            </Index.Box>
          </Box>
        </Box>
      </Modal>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={WithdrawCryptoSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Index.Box className="crypto-token-form withdraw-token-form">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Select Token
                      </Index.FormHelperText>
                      <Index.Box className="dropdown-box">
                        <Index.FormControl className="form-control drop-form-control">
                          <Index.Select
                            className="dropdown-select drop-select"
                            name="selectedToken"
                            value={values.selectedToken}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            displayEmpty
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                          >
                            {walletList &&
                              walletList?.map((net) => (
                                <Index.MenuItem
                                  key={net?.token_name}
                                  value={net?.token_name}
                                >
                                  {net?.token_name}
                                </Index.MenuItem>
                              ))}
                          </Index.Select>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box ">
                      <Index.Box className="typography-flex">
                        <Index.FormHelperText className="form-lable">
                          User Name
                        </Index.FormHelperText>
                        <Index.FormHelperText className="form-lable">
                          {verifyData === "" ? (
                            <>
                              <NavLink
                                className="transfer-verify"
                                onClick={() => {
                                  transferFbc(values?.user_name);
                                }}
                              >
                                Click to verify
                              </NavLink>
                            </>
                          ) : (
                            <>
                              <NavLink className="transfer-verify-verify">
                                verify
                              </NavLink>
                            </>
                          )}
                        </Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          name="user_name"
                          className="form-control"
                          placeholder=""
                          autocomplete="off"
                          onBlur={handleBlur}
                          value={values.user_name}
                          onChange={(e) => {
                            setFieldValue("user_name", e.target.value);
                            if (e.target.value === "") {
                              setVerifyData("");
                            }
                            if (e.target.value !== verifyData?.user_name) {
                              setVerifyData("");
                            }
                          }}
                          helperText={touched.user_name && errors.user_name}
                          error={Boolean(errors.user_name && touched.user_name)}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="form-lable">
                        Amount
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          name="amount"
                          value={values.amount}
                          id="fullWidth"
                          className="form-control"
                          placeholder=""
                          autocomplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.amount && errors.amount}
                          error={Boolean(errors.amount && touched.amount)}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box input-box-panel">
                      <Index.Box className="available-token-sec">
                        <Index.Typography
                          className="available-titles"
                          variant="p"
                          component="p"
                        >
                          Available Balance
                        </Index.Typography>
                        <Index.Typography
                          className="available-data"
                          variant="p"
                          component="p"
                        >
                          {values.selectedToken &&
                            walletList
                              .find(
                                (data) =>
                                  data?.token_name === values.selectedToken
                              )
                              ?.token_balance?.toFixed(2)}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="btn-main-primary withdraw-main-btn-btn">
                      {verifyData == "" ? (
                        <>
                          {" "}
                          <Index.Button
                            className="btn-primary withdraw-btn"
                            disabled
                          >
                            Withdraw
                          </Index.Button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <Index.Button
                            className="btn-primary withdraw-btn"
                            // type="submit"
                            onClick={handleSubmit}
                            disableRipple
                          >
                            Withdraw
                            {/* {!isLoading ? (
                              "Withdraw"
                            ) : (
                              <>
                                <CircularProgress
                                  sx={{ color: "white" }}
                                  size={24}
                                />
                              </>
                            )} */}
                          </Index.Button>
                        </>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Transfer;
