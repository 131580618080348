import { border } from "@mui/system";


const footerStyle = {
    // for main container
    // FooterContainer: {
    //     maxWidth: { xs: '100%', sm: '600px', md: '870px', lg: '1432px', xl:'1432px'}, 
    //     paddingLeft: { xs: '16px', lg: '16px' },
    //     paddingRight: { xs: '16px', lg: '16px' },
    // },
    PaymentContainer: {
        // height: '152px'
        marginTop: '45px',

    },
    FooterMain: {
        paddingTop: { sm: "50px", lg: "80px" },
        paddingBottom: { sm: "50px", lg: "50px" },
        // bgcolor: 'Neutrals8',
        // backgroundColor:'Neutrals8'
    },
    FooterRow: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row', }
    },
    FooterCol1: {
        width: { sm: '50%', md: '36%', lg: '36.124%' },
        flexBasic: { sm: '50%', md: '36%', lg: '36.124%' },
        maxWidth: { sm: '50%', md: '36%', lg: '36.124%' },
        marginRight: { sm: '0', lg: '21px' },
    },
    FooterCol2: {
        width: { sm: '50%', md: '36%', lg: '36.124%' },
        flexBasic: { sm: '50%', md: '36%', lg: '36.124%' },
        maxWidth: { sm: '50%', md: '36%', lg: '36.124%' },
        marginRight: { sm: '0', lg: '42px' },
    },
    FooterCol3: {
        marginTop: { sm: "30px", md: "0", lg: "0" },
        flex: "1",
        textAlign: { xs: 'left', sm: 'center', md: 'right', lg: 'right', xl: 'right' },

    },
    NcxFooterIcon: {
        marginBottom: "20px",
        // display: "flex",
        height: { sm: "30px", lg: "auto" },

    },
    footerFont: {
        position: 'static',
        height: '16px',
        left: '0px',
        top: '0px',
        fontWeight: "400",
        fontSize: { sm: "12px", lg: "14px" },
        lineHeight: '16px',
        color: 'Neutrals1',
        flex: 'none',
        order: '0',
        flexGrow: '0',
        textDecoration: 'none',
    },
    MenuColumn1: {
        paddingRight: { sm: "0", lg: "22.5px" },
    },
    MenuColumn2: {
        paddingLeft: { sm: "0", lg: "22.5px" },
    },
    Typo: {
        fontSize: { sm: "12px", lg: "14px" },

        lineHeight: "20px!important",
        fontFamily: "Poppins-Regular !important",
        marginBottom: { xs: '8px!important', xl: '11px' },
        textAlign: { xs: 'left', sm: 'center', md: 'right', lg: 'right', xl: 'right' },

    },
    SocialLinks: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "20px",
        // justifyContent : "flex-end", 
        justifyContent: { xs: 'flex-start', sm: 'center', md: 'flex-end', lg: 'flex-end', xl: 'flex-end' },

        '& a': {
            marginLeft: "28px",
            marginTop: "10px",
            marginBottom: "12px",
            color: "#000",
        }
    },
    SocialLinksMobile: {
        marginTop: "20px",
        '& a:first-child': {
            marginLeft: "0"
        }

    },
    PaymentImg: {
        '& img': {
            height: '30px',
            width: '100%',
            objectFit: 'contain',
            //  background:"green",
        },

    },
    PymentColumn1: {
        marginTop: "72.5px"
    },
    PymentColumn2: {
        marginTop: "18.5px"
    },

    // MOBILE style
    accordianTitleFont: {
        fontFamily: 'poppins-medium',
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "12px",
        textTransform: "uppercase",
    },

    accordianBorder: {
        boxShadow: 'none',
        borderBottom: '1px solid',
        borderBottomColor: 'Neutrals6C',
        borderRadius: '0 !important',
        backgroundColor: 'Neutrals8',
    },

    accordionDetails: {
        padding: "0", backgroundColor: 'Neutrals8'
    },
    accordionSummary: {
        minHeight: '88px', padding: "0", backgroundColor: 'Neutrals8',
    },
    accordianList: {
        fontFamily: 'Poppins-Regular',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "20px",
        padding: '0px',
        marginBottom: '32px',


        '& a': {
            color: 'Neutrals1',

        }
    },
    newsletter: {
        marginBottom: "20px",
    },

}

export default footerStyle;

