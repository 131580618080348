import React, { useEffect, useState } from "react";
// import Index from '../../Index';
import Index from "../Index";
import { userLogout } from "../../redux/features/slices/User/UserSlice";
// import Index from '../../Index';

export default function Sidebar(props) {
  const dispatch = Index.useDispatch();

  const [open, setOpen] = React.useState(true);
  const [screen, setScreen] = useState("");
  const handleClickAccount = () => {
    setOpen(!open);
  };

  useEffect(() => {
    console.log(window.screen.width, 226);
    if (window.screen.width < 768) {
      setScreen("Mobile");
    } else {
      setScreen("Desktop");
    }
  }, [window.screen.availHeight]);

  const handleLogout = () => {
    dispatch(userLogout());
  };

  return (
    <>
      <Index.Box className="admin-sidebar-main">
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <img
              src={Index.Png.fbcNetworkLogo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            {/* <Index.Button onClick={() => {
                                          props.setOpen(!props.open)
                                          document.body.classList[props.open ? "add" : "remove"](
                                                "body-overflow"
                                          );
                                    }} className="sidebar-close-btn">
                                          <img src={Index.Svg.close} className="close-icon" alt='logo' />
                                    </Index.Button> */}
          </Index.Box>

          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.NavLink
                  to="/v1/dashboard"
                  className="admin-sidebar-link"
                >
                  <img
                    src={Index.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Dashboard
                </Index.NavLink>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.NavLink to="/v1/listing" className="admin-sidebar-link">
                  <img
                    src={Index.Svg.userlist}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Transaction List
                </Index.NavLink>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.NavLink to="/market" className="admin-sidebar-link">
                  <img
                    src={Index.Svg.usercard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Market
                </Index.NavLink>
              </Index.ListItem>
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.NavLink to="/exchange" className="admin-sidebar-link">
                  <img
                    src={Index.Svg.usercard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Exchange
                </Index.NavLink>
              </Index.ListItem>
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.NavLink
                  to="/v1/trade-list"
                  className="admin-sidebar-link"
                >
                  <img
                    src={Index.Svg.usercard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Trade List
                </Index.NavLink>
              </Index.ListItem>

              <Index.ListItem
                className="admin-sidebar-listitem"
                onClick={() => handleLogout()}
              >
                <Index.NavLink to="/login" className="admin-sidebar-link">
                  <img
                    src={Index.Svg.usercard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Logout
                </Index.NavLink>
              </Index.ListItem>
              {/* <Index.ListItem className="admin-sidebar-listitem">
                                                <Index.Link to='/admin/user-list' className="admin-sidebar-link">
                                                      <img src={Index.Svg.userlist} alt='sidebar icon' className='admin-sidebar-icons' />User List
                                                </Index.Link>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <Index.Link to='/admin/user-card' className="admin-sidebar-link">
                                                      <img src={Index.Svg.usercard} alt='sidebar icon' className='admin-sidebar-icons' />User Card
                                                </Index.Link>
                                          </Index.ListItem>
                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <Index.Link to='/admin/user-list' className="admin-sidebar-link">
                                                      <img src={Index.Svg.userlist} alt='sidebar icon' className='admin-sidebar-icons' />User List
                                                </Index.Link>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <Index.Link to='/admin/user-card' className="admin-sidebar-link">
                                                      <img src={Index.Svg.usercard} alt='sidebar icon' className='admin-sidebar-icons' />User Card
                                                </Index.Link>
                                          </Index.ListItem> */}

              {/* <Index.ListItem className='admin-sidebar-listitem admin-submenu-listitem-main' onClick={handleClickAccount}>
                                                <Index.Link className='admin-sidebar-link'>
                                                      <img src={Index.Svg.changepassword} alt='sidebar icon' className='admin-sidebar-icons' />   CMS
                                                      {open ? <Index.ExpandMore className='expandmore-icon' /> : <Index.ExpandLess className='expandless-icon' />}
                                                      <Index.Box className='submenu-main'>
                                                            <Index.Collapse in={open} timeout="auto" unmountOnExit className='submenu-collapse'>
                                                                  <Index.List component="div" disablePadding className='admin-sidebar-submenulist'>
                                                                        <Index.ListItem className='admin-sidebar-listitem'>
                                                                              <Index.Link className='admin-sidebar-link  active'>
                                                                                    About
                                                                              </Index.Link>
                                                                        </Index.ListItem>
                                                                        <Index.ListItem className='admin-sidebar-listitem'>
                                                                              <Index.Link className='admin-sidebar-link '>
                                                                                    Terms & Conditions
                                                                              </Index.Link>
                                                                        </Index.ListItem>
                                                                        <Index.ListItem className='admin-sidebar-listitem'>
                                                                              <Index.Link className='admin-sidebar-link '>
                                                                                    Privacy Policy
                                                                              </Index.Link>
                                                                        </Index.ListItem>
                                                                  </Index.List>
                                                            </Index.Collapse>
                                                      </Index.Box>
                                                </Index.Link>
                                          </Index.ListItem> */}
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
