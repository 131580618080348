import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import FooterStyle from "./FooterStyle";
import Png from "../../../assets/Png";
import FooterMobile from "./Mobille/Footer-mobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Index from "../../Index";
// import pRouets from '../../../routes/DefaultLayoutRoutesArray';
import { useLocation, Link as RouterLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = () => {
  const theme = useTheme();
  let themeMode = theme?.palette?.mode === "light" ? true : false;
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [paymentFooter, setPaymentFooter] = useState();
  let location = useLocation();
  // useEffect(()=>{
  //   let currentRoute = pRouets.filter( route=>(location.pathname).toLowerCase() ===route.path.toLowerCase());
  // // console.log(currentRoute[0]?.isPaymentFooter,'currentRoutecurrentRoute');
  // setPaymentFooter(currentRoute[0]?.isPaymentFooter)
  // },[location])

  const buyCryptoLinks = [
    {
      title: "Crypto Exchange",
      link: "#",
    },
  ];

  const aboutUsLinks = [
    {
      title: "About us",
      link: "#",
    },
    {
      title: "Blog & News",
      link: "#",
    },
    // {
    //   title: "Media Center",
    //   link: "#",
    // },
    {
      title: "Whitepaper",
      link: "#",
    },
  ];
  const helpCenterLinks = [
    {
      title: "FAQ",
      link: "#",
    },
    {
      title: "Contact us",
      link: "#",
    },
  ];
  const termsConditions = [
    {
      title: "Terms & Conditions",
      link: "#",
    },
    {
      title: "Privacy Policy",
      link: "#",
    },
    {
      title: "Cookies Policy",
      link: "#",
    },
  ];
  return (
    <>
      <Box sx={[FooterStyle.FooterMain]} className="main-footer-wrap-dash">
        <Container sx={FooterStyle.FooterContainer}>
          <Box className="footer-img-link">
            <Box className="footer-logo-dash">
              <img src={Index.Png.fbclogo} />
            </Box>
            <Box sx={FooterStyle.FooterRow} className="footer-links-dash">
              <Box className="footer-grid-main">
                <Grid container rowSpacing={0} className="footer-grid-wrapper">
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    sx={FooterStyle.MenuColumn1}
                    className="footer-sep-grid"
                  >
                    <Grid
                      container
                      rowSpacing={{ sm: 1, lg: 3 }}
                      direction="column"
                    >
                      {buyCryptoLinks.map((item, i) => (
                        <Grid item className="footer-grid-item">
                          <Link
                            to={item?.link}
                            target="_blank"
                            component={RouterLink}
                            key={i}
                            sx={[FooterStyle.footerFont]}
                          >
                            {item?.title}
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    sx={FooterStyle.MenuColumn2}
                    className="footer-sep-grid"
                  >
                    <Grid
                      rowSpacing={{ sm: 1, lg: 3 }}
                      container
                      direction="column"
                    >
                      {aboutUsLinks.map((item, i) => (
                        <Grid item className="footer-grid-item">
                          <Link
                            key={i}
                            component={RouterLink}
                            to={"#"}
                            sx={FooterStyle.footerFont}
                            // target="_blank"
                          >
                            {item?.title}
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    sx={FooterStyle.MenuColumn1}
                    className="footer-sep-grid"
                  >
                    <Grid
                      rowSpacing={{ sm: 1, lg: 3 }}
                      container
                      direction="column"
                    >
                      {helpCenterLinks.map((item, i) => (
                        <Grid item className="footer-grid-item">
                          <Link
                            key={i}
                            component={RouterLink}
                            // to={item?.link}
                            sx={FooterStyle.footerFont}
                            // target="_blank"
                          >
                            {item?.title}
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    sx={FooterStyle.MenuColumn2}
                    className="footer-sep-grid"
                  >
                    <Grid
                      rowSpacing={{ sm: 1, lg: 3 }}
                      container
                      direction="column"
                    >
                      {termsConditions.map((item, i) => (
                        <Grid item className="footer-grid-item">
                          <Link
                            key={i}
                            component={RouterLink}
                            // to={item?.link}
                            // target="_blank"
                            sx={FooterStyle.footerFont}
                          >
                            {item?.title}
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box sx={FooterStyle.FooterCol3} className="footer-copyright">
            {!paymentFooter && (
              <>
                <Typography variant="p" component="p" sx={FooterStyle.Typo}>
                  © 2023 all right reserved
                </Typography>
              </>
            )}
          </Box>
          {paymentFooter && (
            <Grid container sx={[FooterStyle.PaymentContainer]}>
              <Grid
                container
                columnSpacing={3.5}
                sx={FooterStyle.PymentColumn1}
                item
                md={7}
              >
                <Grid item md={2} sx={[FooterStyle.PaymentImg]}>
                  <img
                    src={themeMode ? Png.blackVisa : Png.blackVisaDark}
                    alt="icon"
                    // style={FooterStyle.NcxFooterIcon}
                  />
                </Grid>
                <Grid item md={2} sx={[FooterStyle.PaymentImg]}>
                  <img
                    src={
                      themeMode
                        ? Png.blackBankTransfer
                        : Png.blackBankTransferDark
                    }
                    alt="icon"
                    // style={FooterStyle.NcxFooterIcon}
                  />
                </Grid>
                <Grid item md={2} sx={[FooterStyle.PaymentImg]}>
                  <img
                    src={themeMode ? Png.blackSEPA : Png.blackSEPADark}
                    alt="icon"
                    // style={FooterStyle.NcxFooterIcon}
                  />
                </Grid>
                <Grid item md={2} sx={[FooterStyle.PaymentImg]}>
                  <img
                    src={themeMode ? Png.avdCash : Png.avdCashDark}
                    alt="icon"
                    // style={FooterStyle.NcxFooterIcon}
                  />
                </Grid>
                <Grid item md={2} sx={[FooterStyle.PaymentImg]}>
                  <img
                    src={
                      themeMode
                        ? Png.blackPerfectMoney
                        : Png.blackPerfectMoneyDark
                    }
                    alt="icon"
                    // style={FooterStyle.NcxFooterIcon}
                  />
                </Grid>
                <Grid item md={2} sx={[FooterStyle.PaymentImg]}>
                  <img
                    src={themeMode ? Png.blackXOOCash : Png.blackXOOCashDark}
                    alt="icon"
                    // style={FooterStyle.NcxFooterIcon}
                  />
                </Grid>
              </Grid>
              <Grid
                sx={FooterStyle.PymentColumn2}
                container
                direction="row-reverse"
                item
                md={5}
              >
                <Grid item sx={{ textAlign: "right" }}>
                  {/* <Typography variant="p" component="p" sx={FooterStyle.Typo}>
                support@ncx.cx
              </Typography> */}
                  {/* <Typography variant="p" component="p" sx={FooterStyle.Typo}>
                Powered by CryptoLink O.U.
              </Typography> */}
                  <Typography variant="p" component="p" sx={FooterStyle.Typo}>
                    © 2023 all right reserved
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Footer;
