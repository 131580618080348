import closeIcon from "../assets/images/close-icon.svg";
import closeCircle from "../assets/images/closeCircle.svg";

// LIGHT MODE IMAGES IMPORT
import facebook from "../assets/images/light/svg-icon/facebook.svg";
import instagram from "../assets/images/light/svg-icon/instagram.svg";
import telegram from "../assets/images/light/svg-icon/telegram.svg";
import twitter from "../assets/images/light/svg-icon/twitter.svg";
import rightArrow from "../assets/images/light/svg-icon/right-arrow.svg";
import dollar from "../assets/images/light/svg-icon/dollar.svg";
import clock from "../assets/images/clock.svg";
import arrowUp from "../assets/images/arrowUp.svg";
import arrowDown from "../assets/images/arrowDown.svg";
import chartBar from "../assets/images/chartBar.svg";
import menuIcon1 from "../assets/images/light/svg-icon/menuIcon1.svg";
import menuIcon2 from "../assets/images/light/svg-icon/menuIcon2.svg";
import menuIcon3 from "../assets/images/light/svg-icon/menuIcon3.svg";
import dropDown from "../assets/images/light/svg-icon/dropDown.svg";
import tableArrewUp from "../assets/images/light/svg-icon/tableArrewUp.svg";
import menuIcon from "../assets/images/light/svg-icon/menu-icon.svg";
import user from "../assets/images/light/svg-icon/user.svg";
import ball from "../assets/images/light/svg-icon/ball.svg";
import themeMode from "../assets/images/light/svg-icon/themeMode.svg";
import light from "../assets/images/light/svg-icon/light.svg";
import fullScreen from "../assets/images/fullScreen.svg";
import wallet from "../assets/images/light/svg-icon/wallet.svg";
import bitcoinIcon from "../assets/images/light/svg-icon/bitcoinIcon.svg";
import calenderWhite from "../assets/images/light/svg-icon/calender-white.svg";

import visa from "../assets/images/light/svg-icon/visa.svg";
import visaorange from "../assets/images/light/svg-icon/visa-orange.svg";
import bitcoin from "../assets/images/light/svg-icon/bitcoin.svg";
import prevarrow from "../assets/images/light/svg-icon/prev-arrow.svg";
import checkwhite from "../assets/images/light/svg-icon/check-white.svg";
import checkblue from "../assets/images/light/svg-icon/modal-blue-check.svg";
import searchgrey from "../assets/images/light/svg-icon/search-grey.svg";
import stockGraph from "../assets/images/light/svg-icon/stockGraph.svg";
import emailBlack from "../assets/images/light/svg-icon/email-black-icon.svg";
import emailWhite from "../assets/images/light/svg-icon/email-white-icon.svg";
import eye from "../assets/images/light/svg-icon/eye.svg";
import plusSmallIcon from "../assets/images/light/svg-icon/plus-small-icon.svg";
import searchblack from "../assets/images/light/svg-icon/searchblack.svg";
import search from "../assets/images/svg/search.svg";
import arrowLeftWhite from "../assets/images/light/svg-icon/arrowLeftWhite.svg";

// DARK MODE IMAGES IMPORT
import twitterDark from "../assets/images/dark/svg-icon/twitter.svg";
import facebookDark from "../assets/images/dark/svg-icon/facebook.svg";
import instagramDark from "../assets/images/dark/svg-icon/instagram.svg";
import telegramDark from "../assets/images/dark/svg-icon/telegram.svg";
import menuIconDark from "../assets/images/dark/svg-icon/menu-icon.svg";
import userDark from "../assets/images/dark/svg-icon/user.svg";
import ballDark from "../assets/images/dark/svg-icon/ball.svg";
import themeModeDark from "../assets/images/dark/svg-icon/themeMode.svg";
import lightDark from "../assets/images/dark/svg-icon/light.svg";
import emailBlackDark from "../assets/images/dark/svg-icon/email-white-icon.svg";
import WhiteEyeDark from "../assets/images/dark/svg-icon/white-eye.svg";

import plusSmallIconDark from "../assets/images/dark/svg-icon/plus-small-icon.svg";
import calenderDark from "../assets/images/dark/svg-icon/calender-dark.svg";

// New Imports
import middleround from "../assets/images/svg/middleround.svg";
import smallround from "../assets/images/svg/smallround.svg";

import card1 from "../assets/images/svg/card-1.svg";
import card2 from "../assets/images/svg/card-2.svg";
import card3 from "../assets/images/svg/card-3.svg";
import dash_hero from "../assets/images/svg/dash-hero-crypto.svg";

// FBC Exchange
import close from "../assets/images/svg/close.svg";
import dashboard from "../assets/images/svg/dashboard.svg";
import userlist from "../assets/images/svg/user-list.svg";
import usercard from "../assets/images/svg/user-card.svg";
import editpage from "../assets/images/svg/edit-page.svg";
import swap from "../assets/images/svg/swap.svg";
import notification from "../assets/images/svg/notification.svg";
import copyNew from "../assets/images/svg/copyNew.svg";
import company from "../assets/images/svg/company.svg";
import location from "../assets/images/svg/location.svg";
import bergurmenu from "../assets/images/svg/bergur-menu.svg";
import upload from "../assets/images/svg/upload.svg"
import downArrow from "../assets/images/svg/down-arrow.svg"
import arrowGreen from "../assets/images/svg/arrow-up-green.svg"
import arrowRed from "../assets/images/svg/down-arrow-red.svg"
import star from "../assets/images/svg/star.svg"
import square from "../assets/images/svg/square-shape.svg"
import auto from "../assets/images/svg/auto.svg"
import popular from "../assets/images/svg/popular.svg"

const Svg = {
  upload,
  search,
  dash_hero,
  card1,
  card2,
  card3,
  calenderWhite,
  closeIcon,
  //LIGHT
  searchblack,
  plusSmallIcon,
  facebook,
  instagram,
  telegram,
  twitter,
  rightArrow,
  dollar,
  clock,
  arrowUp,
  arrowDown,
  chartBar,
  menuIcon1,
  menuIcon2,
  menuIcon3,
  dropDown,
  tableArrewUp,
  menuIcon,
  user,
  ball,
  themeMode,
  light,
  fullScreen,
  visaorange,
  visa,
  bitcoin,
  prevarrow,
  checkwhite,
  checkblue,
  searchgrey,
  emailBlack,
  eye,
  emailWhite,
  wallet,
  stockGraph,
  bitcoinIcon,
  closeCircle,
  arrowLeftWhite,

  //DARK
  calenderDark,
  WhiteEyeDark,
  facebookDark,
  instagramDark,
  telegramDark,
  twitterDark,
  menuIconDark,
  userDark,
  ballDark,
  themeModeDark,
  lightDark,
  emailBlackDark,
  plusSmallIconDark,

  middleround,
  smallround,
  close,
  dashboard,
  userlist,
  usercard,
  editpage,
  swap,
  notification,
  notification,
  copyNew,
  company,
  location,
  bergurmenu,
  downArrow,
  arrowGreen,
  arrowRed,
  star,
  square,
  auto,
  popular,
};

export default Svg;
