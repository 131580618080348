import React from "react";
import Index from "../../../component/Index";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BnbPrice, { BNBPriceChart } from "./BnbPrice";
import MarketChart from "./BnbPrice";

const ViewCurrencyDetails = () => {
  const userData = useSelector((store) => store.user.userData);
  console.log(852, userData);
  const { symbol } = useParams();
  console.log(symbol, 16);
  const [getData, setGetData] = useState([]);
  const [gainerList, setGainerList] = useState([]);
  const [loserList, setLoserList] = useState([]);

  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log(11, state.data);
  const [coinDetails, setCoinDetails] = useState({});
  console.log(111, coinDetails);
  const getTrendingCoinsData = async () => {
    await DataService(token)
      .get(API.Exchange.TRENDING_COINS)
      .then((res) => {
        console.log(res, 10);
        setGetData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTopGainers = async () => {
    await DataService(token)
      .get(API.Exchange.GET_GAINER_LOSER_LIST)
      .then((res) => {
        console.log(22, res.data.data.gainerList);
        console.log(33, res.data.data.looserList);
        setGainerList(res.data.data.gainerList);
        setLoserList(res.data.data.looserList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getTrendingCoinsData();
    getTopGainers();
  }, []);
  const getMarketDetails = async () => {
    const symbolData = new URLSearchParams();
    symbolData.append("symbol", symbol);
    await DataService(token)
      .post(API.Exchange.TOKEN_DETAILS, symbolData)
      .then((res) => {
        setCoinDetails(res?.data?.data);
      })
      .catch((error) => {
        console.log(error, ":Error");
      });
  };
  useEffect(() => {
    getMarketDetails();
  }, [symbol]);
  const handleClick = () => {
    if (userData) {
      navigate("/new-design/exchange-dashboard");
    } else {
      navigate("/new/login");
    }
  };

  return (
    <Index.Box className="view-main-container">
      <Index.Box className="view-container">
        <Index.Box className="view-heading-select">
          <Index.Box className="view-heading">
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}/${coinDetails?.image}`}
              alt="logo"
              className="view-icon"
            />
            <Index.Typography className="view-heading-name">
              {coinDetails?.name}
              <span>({coinDetails?.symbol})</span>
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="view-chart-tab">
          <Index.Box className="view-container-left">
            <Index.Box className="price-inc-day">
              <Index.Box className="view-price-chart">
                ${coinDetails?.USD?.price?.toFixed(2)}
              </Index.Box>

              <Index.Box
                className={
                  Number(coinDetails?.USD?.percent_change_1h) > 0
                    ? "bnb-inc-chart"
                    : "bnb-inc-chart price-red"
                }
              >
                {Number(coinDetails?.USD?.percent_change_1h) > 0
                  ? `+${Number(coinDetails?.USD?.percent_change_1h)?.toFixed(
                      2
                    )} %`
                  : Number(coinDetails?.USD?.percent_change_1h)?.toFixed(2)}
              </Index.Box>
            </Index.Box>
            {/* {data.symbol == "BNB" ? ( */}

            <Index.Box className="chart-design">
              {coinDetails?.trading_view_symbol &&
                coinDetails?.trading_view_symbol != "" && (
                  <MarketChart symbol={coinDetails?.trading_view_symbol} />
                )}
              {/* <MarketChart symbol={data?.trading_view_symbol} /> */}
            </Index.Box>
            {/* ) : (
              ""
            )} */}
            {/* 
            <Index.Box className="buy-sell-section">
              <Index.Box className="buy-sell-form">
                <Index.Box className="market-section-title">
                  <Index.Typography className="token-buy-sell-title">BUY BNB</Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="buy-sell-form"></Index.Box>
            </Index.Box> */}
            <Index.Box className="token-para">
              <Index.Typography className="token-big-txt">
                About Us
              </Index.Typography>
              <Index.Typography className="token-small-txt">
                {coinDetails?.description}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="token-para">
              {/* <Index.Box className="view-heading">
                <Index.Typography variant="h6" component="h6">
                  {data.name} Resources
                </Index.Typography>
              </Index.Box> */}
              <Index.Box className="token-para">
                <Index.Box className="bnb-heading">
                  <Index.Typography
                    variant="h6"
                    component="h6"
                    className="sub-heading"
                  >
                    Resources
                  </Index.Typography>
                </Index.Box>

                <Index.Box>
                  <Index.Box className="flex-btn">
                    <img />
                    <Index.Typography>
                      <Index.Link>
                        Official {coinDetails.name} Website
                      </Index.Link>
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="flex-btn">
                    <Index.Typography>
                      <Index.Link>White Paper</Index.Link>
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-container-right">
            <Index.Box>
              <Index.Box className="sub-tab">
                <Index.Box class="sub-tab-heading">
                  <Index.Typography variant="h1" class="token-name">
                    Make a Trade
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="sub-tab-buttons">
                  <Index.Button className="buttons" onClick={handleClick}>
                    BUY
                  </Index.Button>
                  <Index.Button className="buttons" onClick={handleClick}>
                    SELL
                  </Index.Button>
                  <Index.Button className="buttons" onClick={handleClick}>
                    TRANSFER
                  </Index.Button>
                </Index.Box>
                <Index.Box className="main-tabbar">
                  <Index.Box className="sub-tabs">
                    <Index.Box className="main-tabbar-trending">
                      <Index.Box className="trending-section-heading">
                        <Index.Typography className="section-heading">
                          Trending Coins
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="trending-section-data">
                        <Index.Box>
                          {getData.map((trending) => (
                            <>
                              <Index.Box className="trending-data">
                                <Index.Box className="sub-data-tab">
                                  <Index.Box>
                                    <Index.Box
                                      className="name-data"
                                      onClick={() => {
                                        navigate(
                                          `/exchange/${trending?.symbol}`,
                                          {
                                            state: {
                                              categoryData: trending,
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}/${trending.image}`}
                                      />

                                      <Index.Box className="name-text-data">
                                        <Index.Typography className="name-text">
                                          {`${trending.name} `}
                                        </Index.Typography>
                                        <Index.Typography className="name-text-2">
                                          {`${trending.symbol} `}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box className="trending-percents">
                                    <Index.Box className="trending-price">
                                      <Index.Typography className="price">
                                        {trending.USD.price?.toFixed(2)}
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="trending-price">
                                      <Index.Box className="percent-box">
                                        <Index.Typography
                                          className={
                                            trending?.USD?.percent_change_1h
                                              ? "percent-positive"
                                              : "percent-negative"
                                          }
                                        >
                                          {/* {trending.USD.percent_change_1h.toFixed(
                                            2
                                          )}
                                          % */}
                                          {trending?.USD?.percent_change_1h > 0
                                            ? "+" +
                                              " " +
                                              trending?.USD?.percent_change_1h?.toFixed(
                                                2
                                              ) +
                                              " " +
                                              "%"
                                            : trending.USD.percent_change_1h?.toFixed(
                                                2
                                              ) +
                                              " " +
                                              "%"}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box></Index.Box>
                            </>
                          ))}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="sub-tabs">
                    <Index.Box className="main-tabbar-trending">
                      <Index.Box className="trending-section-heading">
                        <Index.Typography className="section-heading">
                          Top 5 Gainers
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="trending-section-data">
                        <Index.Box>
                          {gainerList.map((trending) => (
                            <>
                              <Index.Box className="trending-data">
                                <Index.Box className="sub-data-tab">
                                  <Index.Box>
                                    <Index.Box
                                      className="name-data"
                                      onClick={() => {
                                        navigate(
                                          `/exchange/${trending?.symbol}`,
                                          {
                                            state: {
                                              categoryData: trending,
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}/${trending.image}`}
                                      />

                                      <Index.Box className="name-text-data">
                                        <Index.Typography className="name-text">
                                          {`${trending.name} `}
                                        </Index.Typography>
                                        <Index.Typography className="name-text-2">
                                          {`(${trending.symbol}) `}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box className="trending-percents">
                                    <Index.Box className="trending-price">
                                      <Index.Typography className="price">
                                        {trending.USD.price?.toFixed(2)}
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="trending-price">
                                      <Index.Box className="percent-box">
                                        <Index.Typography
                                          className={
                                            trending.USD.percent_change_1h
                                              ? "percent-positive"
                                              : "percent-negative"
                                          }
                                        >
                                          {/* {trending.USD.percent_change_1h.toFixed(
                                            2
                                          )}
                                          % */}
                                          {trending.USD.percent_change_1h > 0
                                            ? "+" +
                                              " " +
                                              trending.USD.percent_change_1h?.toFixed(
                                                2
                                              ) +
                                              " " +
                                              "%"
                                            : trending.USD.percent_change_1h?.toFixed(
                                                2
                                              ) +
                                              " " +
                                              "%"}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box></Index.Box>
                            </>
                          ))}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="sub-tabs">
                    <Index.Box className="main-tabbar-trending">
                      <Index.Box className="trending-section-heading">
                        <Index.Typography className="section-heading">
                          Top 5 Losers
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="trending-section-data">
                        <Index.Box>
                          {loserList.map((trending) => (
                            <>
                              <Index.Box className="trending-data">
                                <Index.Box className="sub-data-tab">
                                  <Index.Box>
                                    <Index.Box
                                      className="name-data"
                                      onClick={() => {
                                        navigate(
                                          `/exchange/${trending?.symbol}`,
                                          {
                                            state: {
                                              categoryData: trending,
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}/${trending.image}`}
                                      />

                                      <Index.Box className="name-text-data">
                                        <Index.Typography className="name-text">
                                          {`${trending.name} `}
                                        </Index.Typography>
                                        <Index.Typography className="name-text-2">
                                          {`(${trending.symbol}) `}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box className="trending-percents">
                                    <Index.Box className="trending-price">
                                      <Index.Typography className="price">
                                        {trending.USD.price?.toFixed(2)}
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="trending-price">
                                      <Index.Box className="percent-box">
                                        <Index.Typography
                                          className={
                                            trending?.USD?.percent_change_1h > 0
                                              ? "percent-positive"
                                              : "percent-negative"
                                          }
                                        >
                                          {/* {trending?.USD?.percent_change_1h.toFixed(
                                            2
                                          )}
                                          % */}
                                          {trending.USD.percent_change_1h > 0
                                            ? "+" +
                                              " " +
                                              trending.USD.percent_change_1h?.toFixed(
                                                2
                                              ) +
                                              " " +
                                              "%"
                                            : trending.USD.percent_change_1h?.toFixed(
                                                2
                                              ) +
                                              " " +
                                              "%"}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box></Index.Box>
                            </>
                          ))}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default ViewCurrencyDetails;
