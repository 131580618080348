import React, { useEffect, useState, useRef } from "react";
import Index from "../../../component/Index";
import { DataService } from "../../../config/DataService";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../config/Api";
import { Formik, Form, resetForm } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { getWalletDataList } from "../../../redux/features/slices/User/UserService";
import { CircularProgress } from "@mui/material";
// modal
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Swap = () => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [settingData, setSettingData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const token = useSelector((state) => state.user.userToken);
  const walletList = useSelector((state) => state.user.walletList);
  const [storeValue, setStoreValue] = useState({});
  const [resetValue, setResetValue] = useState({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const generateAmount = (amountSend) => {
    const username = `${amountSend * 2}`;
    // const username = fullName.toLowerCase().replace(/\s+/g, "_");
    return username;
  };

  const generateReceiveAmount = (
    amountSend,
    setFieldValue,
    selectSendToken
  ) => {
    const coinPrice = walletList?.find(
      (data) => data?.token_name === selectSendToken
    )?.price;
    if (selectSendToken === "USDT") {
      const recieveAmount = amountSend / coinPrice;
      setFieldValue("recieveAmount", recieveAmount);
    } else {
      const recieveAmount = amountSend * settingData.coin_price;
      setFieldValue("recieveAmount", recieveAmount);
    }
  };
  // get tokens

  const getSettingData = async () => {
    await DataService(token)
      .get(API.Admin.GET_SETTING)
      .then((res) => {
        setSettingData(res?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // validation logic
  const SwapSchema = Yup.object().shape({
    sendAmount: Yup.number()
      .typeError("Only numbers are allowed")
      .test("balance", "Amount exceeds available balance", function (value) {
        const selectedTokenBalance = walletList?.find(
          (data) => data?.token_name === this.parent.selectSendToken
        )?.token_balance;

        return selectedTokenBalance >= value;
      })
      .required("Please enter Amount"),
  });
  const handleKeyDown = (event) => {
    const isAllowedKey =
      /^[0-9.]$/.test(event.key) || event.key === "Backspace";
    if (!isAllowedKey) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    getSettingData();
    dispatch(getWalletDataList(token));
  }, []);
  const confirmationModalOpen = async () => {
    const data = new URLSearchParams();
    data.append("firstToken", storeValue?.selectSendToken);
    data.append("secondToken", storeValue?.secondToken);
    data.append("firstAmount", storeValue?.sendAmount);
    data.append("secondAmount", storeValue?.recieveAmount);
    await DataService(token)
      .post(API.Exchange.SWAP_TOKEN, data)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status === 201) {
          toast.success(res?.data?.message);
          dispatch(getWalletDataList(token));
          setOpen(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
    formikRef.current.resetForm();
  };

  const handleFormSubmit = async (value) => {
    handleOpen();
    setStoreValue(value);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="confirmation-box">
          <Box sx={style} className="confirmation-btn">
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to swap?
            </Typography>
            <Index.Box className="con-btn">
              <Button
                onClick={confirmationModalOpen}
                style={{
                  color: "white",
                  backgroundColor: "#00235f",
                  fontFamily: "system-ui",
                  fontSize: 13,
                  padding: 5,
                }}
              >
                Yes
              </Button>
              <Button
                onClick={handleClose}
                style={{
                  color: "white",
                  backgroundColor: "red",
                  fontSize: 12,
                  fontFamily: "system-ui",
                  marginLeft: 5,
                }}
              >
                No
              </Button>
            </Index.Box>
          </Box>
        </Box>
      </Modal>
      <Formik
        innerRef={formikRef}
        initialValues={{
          selectSendToken:
            walletList?.length > 0 ? walletList[0]?.token_name : "",
          secondToken:
            walletList?.length > 0 && walletList?.[0]?.token_name == "FBC"
              ? "USDT"
              : "",
          // secondToken:"",
          sendAmount: "",
          recieveAmount: "",
          amount: "",
        }}
        validationSchema={SwapSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Index.Box className="admin-dashboard-box">
              <Index.Box className="excha-card">
                <Index.Box className="admin-sub-title-main">
                  <Index.Typography
                    className="admin-sub-title"
                    variant="h6"
                    component="h6"
                  >
                    Swap
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="swap-box-main">
                  <Index.Box className="swap-box-wrapper">
                    <Index.Box className="available-swipe-box">
                      <img
                        src={Index.Svg.swap}
                        className="available-swiper-icon"
                        alt="swap"
                      />
                    </Index.Box>

                    <Index.Box className="swap-box-content">
                      <Index.Box className="input-box swap-input-box">
                        <Index.Typography
                          className="form-lable"
                          variant="p"
                          component="p"
                        >
                          Send
                        </Index.Typography>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="sendAmount"
                            value={values?.sendAmount}
                            id="fullWidth"
                            className="form-control"
                            autocomplete="off"
                            placeholder="Enter Amount"
                            onKeyDown={handleKeyDown}
                            // onChange={handleChange}
                            onChange={(e) => {
                              const amountSend = e.target.value;
                              setFieldValue("sendAmount", amountSend);
                              generateReceiveAmount(
                                amountSend,
                                setFieldValue,
                                values?.selectSendToken &&
                                  values?.selectSendToken
                              );
                            }}
                            onBlur={handleBlur}
                            error={Boolean(
                              errors.sendAmount && touched.sendAmount
                            )}
                            helperText={touched.sendAmount && errors.sendAmount}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box swap-input-box">
                        <Index.Typography
                          className="form-lable"
                          variant="p"
                          component="p"
                        >
                          Select Token
                        </Index.Typography>
                        <Index.Box className="dropdown-box">
                          <Index.FormControl className="form-control drop-form-control">
                            <Index.Select
                              className="dropdown-select drop-select"
                              name="selectSendToken"
                              value={values?.selectSendToken}
                              onChange={(e) => {
                                const tokenSend = e.target.value;
                                setFieldValue("selectSendToken", tokenSend);
                                generateReceiveAmount(
                                  values?.sendAmount && values?.sendAmount,
                                  setFieldValue,
                                  tokenSend
                                );
                              }}
                              onBlur={handleBlur}
                              displayEmpty
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                            >
                              {walletList &&
                                walletList
                                  ?.filter(
                                    (item) => item?.token_name !== "USDT"
                                  )
                                  .map((net) => (
                                    <Index.MenuItem
                                      key={net?.token_name}
                                      value={net?.token_name}
                                    >
                                      {net?.token_name}
                                    </Index.MenuItem>
                                  ))}
                            </Index.Select>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="swap-box-content">
                      <Index.Box className="input-box swap-input-box">
                        <Index.Typography
                          className="form-lable"
                          variant="p"
                          component="p"
                        >
                          Receive
                        </Index.Typography>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="recieveAmount"
                            value={values?.recieveAmount}
                            id="fullWidth"
                            className="form-control"
                            disabled
                            autocomplete="off"
                            placeholder="Enter Amount"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box swap-input-box">
                        <Index.Typography
                          className="form-lable"
                          variant="p"
                          component="p"
                        >
                          Select Token
                        </Index.Typography>
                        <Index.Box className="dropdown-box">
                          <Index.FormControl className="form-control drop-form-control">
                            <Index.Select
                              className="dropdown-select drop-select"
                              name="secondToken"
                              value={
                                values?.selectSendToken === "FBC"
                                  ? "USDT"
                                  : "FBC"
                              }
                              onBlur={handleBlur}
                              displayEmpty
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                              onChange={(e) => {
                                setFieldValue("secondToken", e.target.value);
                              }}
                            >
                              {values?.selectSendToken === "FBC" && (
                                <Index.MenuItem key={"USDT"} value={"USDT"}>
                                  USDT
                                </Index.MenuItem>
                              )}
                              {/* {values?.selectSendToken === "FBC" ? (
                                <Index.MenuItem key={"USDT"} value={"USDT"}>
                                  USDT
                                </Index.MenuItem>
                              ) : (
                                ""
                              )} */}
                            </Index.Select>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="available-token-sec">
                    <Index.Typography
                      className="available-titles"
                      variant="p"
                      component="p"
                    >
                      Available Tokens
                    </Index.Typography>
                    <Index.Typography
                      className="available-data"
                      variant="p"
                      component="p"
                    >
                      {values?.selectSendToken &&
                        walletList
                          ?.find(
                            (data) =>
                              data?.token_name === values?.selectSendToken
                          )
                          ?.token_balance?.toFixed(2)}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="btn-main-primary login-btn-main">
                    <Index.Button
                      className="btn-primary login-btn"
                      type="submit"
                      disableRipple
                      // onClick={handleCloseDelete}
                    >
                      {/* Swap{" "}
                      <CircularProgress sx={{ color: "white" }} size={24} /> */}
                      {!isLoading ? (
                        "Swap"
                      ) : (
                        <>
                          <CircularProgress sx={{ color: "white" }} size={24} />
                        </>
                      )}
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Swap;
