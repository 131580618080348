import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../container/auth/login/Login";
import ForgotPassword from "../container/auth/forgotPassword/ForgotPassword";
import OtpVerify from "../container/auth/otpVerify/OtpVerify";
import ResetPassword from "../container/auth/resetPassword/ResetPassword";
import Dashboard from "../container/pages/dashboard/Dashboard";
import SignUpUser from "../container/auth/register/SignUpUser";
import Homepage from "../container/pages/home/Homepage";
import NewHomePage from "../container/pages/newHomePage/NewHomePage";

import PortalLayout from "../component/DefaultLayout/portal/PortalLayout";
import ExchangeDashboord from "../container/pages/exchangedashboord/ExchangeDashboord";
import LayOut from "../container/pages/layout/LayOut";
import Exchange from "../container/pages/exchange/Exchange";
import Market from "../container/pages/market/Market";

import NewDefaultLayout from "../container/pages/newHomePage/NewDefaultLayout";
import ViewCurrencyDetails from "../container/pages/view_currency_details/ViewCurrencyDetails";

import MarketView from "../container/pages/market/MarketView";
import Listing from "../container/pages/listing/Listing";

import PrivateRoutes from "./ProtectedRoutes";
import ExchangeKyc from "../container/pages/exchangekyc/ExchangeKyc";
import SpotTrade from "../container/pages/spottrade/SpotTrade";
import TradeList from "../container/pages/tradeList/TradeList";

export default function Router() {
  return (
    <>
      <Routes>
        <Route path="/" element={<NewHomePage />} />
        {/* <Route path="/fbc-portal" element={<PortalLayout />} /> */}
        <Route path="/" element={<NewDefaultLayout />}>
          {/* <Route path="" element={<NewHomePage />} /> */}
          <Route exact path="login" element={<Login />} />
          <Route exact path="singup" element={<SignUpUser />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="otp" element={<OtpVerify />} />
          <Route path="reset-Password" element={<ResetPassword />} />
        </Route>

        <Route path="/" element={<Dashboard />}>
          {/* <Route path="/" element={<Homepage />} /> */}
          <Route path="/market" element={<Market />} />

          <Route
            path="/view-currency-details"
            element={<ViewCurrencyDetails />}
          />
          <Route path="exchange/:symbol" element={<ViewCurrencyDetails />} />
          <Route path="/market/:symbol" element={<MarketView />} />
          <Route path="/exchange" element={<Exchange />} />
          <Route path="spot-trade" element={<SpotTrade />} />

          {/* <Route exact path="/singup" element={<SignUpUser />} /> */}
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path="/v1" element={<LayOut />}>
            <Route path="dashboard" element={<ExchangeDashboord />} />
            <Route path="listing" element={<Listing />} />
            <Route path="exchange-kyc" element={<ExchangeKyc />} />
            <Route path="trade-list" element={<TradeList />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}
