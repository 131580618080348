import React from "react";
import Index from "../../Index";
import Png from "../../../assets/Png";
import { Avatar, Stack } from "@mui/material";
import HeaderStyle from "./HeaderStyle";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import { userLogout } from "../../../redux/features/slices/User/UserSlice";

const MenuHeader = () => {
  const userToken = useSelector((store) => store.user.userToken);
  const userData = useSelector((store) => store.user.userData);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(userLogout());
  };
  return (
    <>
      <Index.Box class="bt-logo-area menu-holder btClear">
        <Index.Box class="port exchange-header">
          <Index.Box className="header_left_content">
            <Index.Box class="logo fbc-logo">
              <Index.Link to="/">
                <img
                  class="btMainLogo"
                  data-hw="1.2678571428571"
                  src={Png.fbclogo}
                  alt="Orbis - Ajani"
                />
              </Index.Link>{" "}
            </Index.Box>
            <Index.Box class="menuPort">
              <nav>
                <ul id="menu-main-menu" class="menu">
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-140">
                    <Index.Link
                      to={"/exchange"}
                      component={Index.Link}
                      sx={HeaderStyle.Menu}
                    >
                      Exchange
                    </Index.Link>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-91">
                    <Index.Link
                      to={"/market"}
                      component={Index.Link}
                      sx={HeaderStyle.Menu}
                    >
                      Market
                    </Index.Link>
                  </li>
                </ul>{" "}
              </nav>
            </Index.Box>
          </Index.Box>

          {userToken ? (
            <Index.Box className="exchange-wallet-btn">
              <Index.Box class="topBarInMenu profile-name header_Book_btn">
                <Index.IconButton
                  onClick={handleMenuClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <span style={{ color: "#fff" }}>
                    {userData?.user_name} ({userData?.uid})
                  </span>
                  <span>
                    {userData?.is_verified && (
                      <VerifiedIcon color="primary" fontSize="14px" />
                    )}
                  </span>
                </Index.IconButton>
                <Index.Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Index.MenuItem onClick={handleClose}>
                    {" "}
                    <Index.Link
                      to="/v1/dashboard"
                      component={Index.Link}
                      className="text-decoration-none"
                    >
                      {" "}
                      <Index.Button sx={HeaderStyle.walletButton}>
                        Dashboard
                      </Index.Button>
                    </Index.Link>
                  </Index.MenuItem>
                  <Index.MenuItem onClick={handleClose}>
                    {" "}
                    <Index.Link
                      component={Index.Link}
                      className="text-decoration-none"
                    >
                      {" "}
                      <Index.Button sx={HeaderStyle.walletButton}>
                        Profile
                      </Index.Button>
                    </Index.Link>
                  </Index.MenuItem>
                  <Index.MenuItem
                    onClick={() => {
                      handleClose();
                      handleLogout();
                    }}
                  >
                    <Index.Link
                      component={Index.Link}
                      className="text-decoration-none"
                    >
                      {" "}
                      <Index.Button sx={HeaderStyle.walletButton}>
                        Logout
                      </Index.Button>
                    </Index.Link>
                  </Index.MenuItem>
                </Index.Menu>
              </Index.Box>
            </Index.Box>
          ) : (
            <>
              <Index.Box class="topBarInMenu header_Book_btn">
                <Index.IconButton
                  onClick={handleMenuClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Index.Button
                    variant="contained"
                    className="exchange-header-btn"
                  >
                    Console
                  </Index.Button>
                </Index.IconButton>
                <Index.Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Index.MenuItem onClick={handleClose}>
                    {" "}
                    <Index.Link
                      to={"/login"}
                      component={Index.Link}
                      className="text-decoration-none"
                    >
                      {" "}
                      <Index.Button sx={HeaderStyle.walletButton}>
                        Login
                      </Index.Button>
                    </Index.Link>
                  </Index.MenuItem>
                  <Index.MenuItem onClick={handleClose}>
                    <Index.Link
                      to={"/singup"}
                      component={Index.Link}
                      className="text-decoration-none"
                    >
                      {" "}
                      <Index.Button sx={HeaderStyle.walletButton}>
                        Register
                      </Index.Button>
                    </Index.Link>
                  </Index.MenuItem>
                </Index.Menu>
              </Index.Box>
            </>
          )}
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default MenuHeader;
